import {
  GET_SESSION,
  CLEAR_SESSION,
  SESSION_ERROR,
  GET_SESSION_COURSES,
  LOGOUT,
  GET_ACTIVE_SESSION,
  GET_SESSION_DETAIL,
  SESSION_CREATED,
  LOADING,
  GET_GROUPED_SESSION,
  GET_GROUPED_SESSION_DETAIL,
} from '../actions/types';

const initialState = {
  sessions: [],
  details: [],
  ActiveSessions: [],
  groupedSessions: [],
  groupSessionDetails: [],
  courses: [],
  loading: true,
  creationLoader: false,
  error: {},
};

export default function (state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_SESSION:
      return {
        ...state,
        sessions: payload,
        loading: false,
        creationLoader: false,
      };
    case SESSION_CREATED:
      return {
        ...state,
        loading: false,
        creationLoader: false,
      };
    case GET_GROUPED_SESSION:
      return {
        ...state,
        groupedSessions: payload,
        loading: false,
        creationLoader: false,
      };
    case GET_GROUPED_SESSION_DETAIL:
      return {
        ...state,
        groupSessionDetails: payload,
        loading: false,
        creationLoader: false,
      };
    case GET_SESSION_DETAIL:
      return {
        ...state,
        details: payload,
        loading: false,
        creationLoader: false,
      };

    case LOADING:
      return {
        ...state,
        creationLoader: true,
      };
    case GET_ACTIVE_SESSION:
      return {
        ...state,
        ActiveSessions: payload,
        loading: false,
        creationLoader: false,
      };
    case GET_SESSION_COURSES:
      return {
        ...state,
        courses: payload,
        loading: false,
        creationLoader: false,
      };
    case SESSION_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        creationLoader: false,
      };
    case LOGOUT:
    case CLEAR_SESSION:
      return {
        ...state,
        sessions: null,
        loading: false,
        creationLoader: false,
      };
    default:
      return state;
  }
}
