import React, {useState} from 'react';
import logo from '../../assets/img/logo.png';
import sec1 from '../../assets/img/sec-1.png';
import key2 from '../../assets/img/key-2.png';
import arrow from '../../assets/img/arrow-white.png';
import message from '../../assets/img/message.png';
import lock from '../../assets/img/lock.png';
import user from '../../assets/img/user.png';
import {Link, Redirect} from 'react-router-dom';
import google from '../../assets/img/google.png';
import '../../styles/login.scss';
import {resetPasswordService} from '../../actions/auth';
import {connect} from 'react-redux';
import {setAlert} from '../../actions/alert';
import PropTypes from 'prop-types';

const ResetPassword = ({resetPasswordService, match, history, loading}) => {
  const id = match.params.id;
  const [formData, setFormData] = useState({
    password: '',
    new_password: '',
  });
  const {password, new_password} = formData;

  const onFormChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    if (password != new_password) {
      console.log(formData);
      setAlert('Passwords do not match!', 'error');
    } else {
      const body = {
        token: id,
        new_password: new_password,
      };
      console.log(body);
      async function awaitdata() {
        let data = await resetPasswordService(body);
        if (data) {
          console.log('change oooooooooo');
          history.push({pathname: `/login`});
        }
      }
      awaitdata();
    }
  };
  return (
    <div>
      <section className="signIn-wrapper">
        <div className="curve1">
          <img src={sec1} alt="" />
        </div>

        <div className="curve2">
          <img src={key2} alt="" />
        </div>
        <div className="container">
          <div className="sign-container">
            <div>
              <div className="my-5 logo-wrapper">
                <img src={logo} alt="" className="login-logo" />
              </div>
              <div className="row my-5">
                <div className="col-lg-5 col-sm-12 sm-d-none my-auto">
                  <div className="text-white">
                    <h2 className="font88 font-bold">Reset Password</h2>
                    <p className="font18">
                      <span>Fill the form to reset your password</span>
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-12 sm-d-none my-auto">
                  <img src={arrow} alt="" className="w-100" />
                </div>
                <div className="col-lg-5 col-sm-12">
                  <div>
                    <form className="p-5 bg-white">
                      <div className="d-flex align-items-center mb-4">
                        <h2 className="login-nav login active mr-3">
                          Reset Password
                        </h2>
                      </div>
                      <div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={(e) => onFormChange(e)}
                            required
                          />
                          <span className="input-icon">
                            <img src={lock} alt="" />
                          </span>
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Confirm Password"
                            name="new_password"
                            value={new_password}
                            onChange={(e) => onFormChange(e)}
                            required
                          />
                          <span className="input-icon">
                            <img src={lock} alt="" />
                          </span>
                        </div>
                        <div className="my-4">
                          <button
                            className="btn btn-green"
                            disabled={loading}
                            onClick={onSubmitForm}
                          >
                            Reset Password
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <footer className="mt-auto">
              <div className="d-flex justify-content-between">
                <p>(c) 2020. Discipleship Training Institute</p>
                <p>
                  <Link to="/" className="mr-2">
                    Home
                  </Link>
                  <span className="mr-2">|</span>
                  <span> God has great plans for you ! (Jer. 29:11)</span>
                </p>
              </div>
            </footer>
          </div>
        </div>
      </section>
    </div>
  );
};

ResetPassword.prototypes = {
  resetPasswordService: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {resetPasswordService})(ResetPassword);
