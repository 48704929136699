import React, {useState} from 'react';
import profile from '../../assets/img/profile.svg';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createStaff} from '../../actions/staff';

const AddAdmin = ({createStaff, reloadPage, location}) => {
    const [formData, setFormData] = useState({
      email_address: '',
      phone_number: '',
      password: '',
      first_name: '',
      last_name: '',
      middle_name: '',
      country: 'country',
      city: '',
      role: 'admin',
    });
    const {
      email_address,
      phone_number,
      password,
      first_name,
      last_name,
      middle_name,
      city,
      country,
      role
    } = formData;
  
    const onFormChange = (e) => {
      setFormData({...formData, [e.target.name]: e.target.value});
    };
   
    const onSubmitForm =(e)=> {
      e.preventDefault();
      console.log(formData)
      createStaff(formData)
    }
    if(reloadPage == true){
      window.location.reload()
    }
    
  return (
    <div>
      <div
        className="modal fade"
        id="addModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addModalLabel">
                New Staff
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ">
              <div className="row pt-5">
                <div className="col-lg-3 col-sm-12 sm-text-center">
                  <div className="profile m-auto">
                    <img src={profile} alt="" />
                    {/* <div className="file-picker bg-blue">
                      <label htmlFor="file" className=" text-white mb-0">
                        <i className="fa fa-plus"></i>
                      </label>
                      <input
                        type="file"
                        name="file"
                        id="file"
                        className="inputfile"
                      />
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-9 col-sm-12">
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <input
                        type="text"
                        className="common-input my-3 bg-white w-100"
                        placeholder="First Name"
                        name="first_name"
                        value={first_name}
                        onChange={(e) => onFormChange(e)}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <input
                        type="text"
                        className="common-input my-3 bg-white w-100"
                        placeholder="Last Name"
                        name="last_name"
                        value={last_name}
                        onChange={(e) => onFormChange(e)}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <input
                        type="text"
                        className="common-input my-3 bg-white w-100"
                        placeholder="Middle Name"
                        name="middle_name"
                        value={middle_name}
                        onChange={(e) => onFormChange(e)}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <input
                        type="tel"
                        className="common-input my-3 bg-white w-100"
                        placeholder="Phone Number"
                        name="phone_number"
                        value={phone_number}
                        onChange={(e) => onFormChange(e)}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <input
                        type="text"
                        className="common-input my-3 bg-white w-100"
                        placeholder="City"
                        name="city"
                        value={city}
                        onChange={(e) => onFormChange(e)}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <input
                        type="text"
                        className="common-input my-3 bg-white w-100"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={(e) => onFormChange(e)}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="common-input my-3 bg-white w-100"
                        placeholder="Email"
                        name="email_address"
                        value={email_address}
                        onChange={(e) => onFormChange(e)}
                      />
                    </div>
                  </div>

                  <div className="my-5 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-secondary mr-3"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-green"
                      onClick={onSubmitForm}
                    >
                      Add Admin
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
  );
};

AddAdmin.propTypes = {
  createStaff: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  reloadPage:state.staffs.reloadPage
})
export default connect(mapStateToProps, {createStaff})(AddAdmin);
