export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const SESSION_ERROR = 'SESSION_ERROR';
export const GET_GROUP_SESSION_COURSES = 'GET_GROUP_SESSION_COURSES';
export const GET_SESSION_COURSES = 'GET_SESSION_COURSES';
export const GET_GROUPED_SESSION = 'GET_GROUPED_SESSION';
export const SESSION_CREATED = 'SESSION_CREATED';
export const GET_SESSION = 'GET_SESSION';
export const GET_ACTIVE_SESSION = 'GET_ACTIVE_SESSION';
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const STAFF_CREATED = 'STAFF_CREATED';
export const GET_STAFF = 'GET_STAFF';
export const STAFF_ERROR = 'STAFF_ERROR';
export const CLEAR_STAFF = 'CLEAR_STAFF';
export const COURSE_CREATED = 'COURSE_CREATED';
export const GET_COURSE = 'GET_COURSE';
export const COURSE_ERROR = 'COURSE_ERROR';
export const CLEAR_COURSE = 'CLEAR_COURSE';
export const RELOAD_PAGE = 'RELOAD_PAGE';
export const STUDENT_CREATED = 'STUDENT_CREATED';
export const GET_STUDENT = 'GET_STUDENT';
export const STUDENT_ERROR = 'STUDENT_ERROR';
export const CLEAR_STUDENT = 'CLEAR_STUDENT';
export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_COURSE_DETAIL = 'GET_COURSE_DETAIL';
export const GET_SESSION_DETAIL = 'GET_SESSION_DETAIL';
export const GET_GROUPED_SESSION_DETAIL = 'GET_GROUPED_SESSION_DETAIL';
export const GET_STUDENT_DETAIL = 'GET_STUDENT_DETAIL';
export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const UPDATE_USER = 'UPDATE_USER';
export const LOADING = 'LOADING';
export const NON_MEMBER = 'NON_MEMBER';
export const REMOVE_NON_MEMBER = 'REMOVE_NON_MEMBER';
