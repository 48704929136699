import React, {useEffect} from 'react';
import {Link, Redirect} from 'react-router-dom';
import profile from '../../assets/img/profile.svg';
import StudentNavbar from '../component/Student-Navbar';
import {StudentFooter} from '../component/Student-Footer';
import certlogo from '../../assets/img/certificate.jpg';
import visa from '../../assets/img/visa.png';
import {logoutService} from '../../actions/auth';
import EditStudent from '../component/Edit-Student';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {userProfile} from '../../actions/student';
import Moment from 'react-moment';

const StudentProfile = ({student, logoutService, isAuthenticated}) => {
  console.log(student);
  return (
    <div className="admin">
      <StudentNavbar />
      <div className="d-body">
        <div className="container mt-5">
          <div className="row setting_wrapper">
            <div className="col-lg-3 col-sm-12">
              <div className="bg-blue border-radius">
                <div className="pb-3 pt-4 text-center ">
                  <div className="mx-auto profile-img">
                    <img
                      src={
                        student?.user?.avater ? student.user.avater : profile
                      }
                      alt=""
                    />
                  </div>
                  <p className="mt-2 font24 text-white ">
                    <b>
                      {student?.user?.first_name} <br />
                      {student?.user?.last_name}
                    </b>
                  </p>
                  <p className="text-white">{student?.user?.email_address}</p>
                </div>
                <div className="px-5 py-3 font12 text-white click d-flex justify-content-between edit-click">
                  <b data-toggle="modal" data-target="#editModal">
                    EDIT
                  </b>
                  <b onClick={logoutService}>Logout</b>
                </div>
              </div>
            </div>
            <div className="col-lg-1 col-sm-12"></div>
            <div className="col-lg-8 col-sm-12 sm-mt">
              <div className="nav-card bg-white px-4 py-3">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="pills-Overview-tab"
                      data-toggle="pill"
                      href="#pills-Overview"
                      role="tab"
                      aria-controls="pills-Overview"
                      aria-selected="true"
                    >
                      Overview
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-ResultsCertificates-tab"
                      data-toggle="pill"
                      href="#pills-ResultsCertificates"
                      role="tab"
                      aria-controls="pills-ResultsCertificates"
                      aria-selected="false"
                    >
                      Certificates
                    </a>
                  </li>
                </ul>
              </div>
              <hr className="my-0" />

              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-Overview"
                  role="tabpanel"
                  aria-labelledby="pills-Overview-tab"
                >
                  <div className="bg-white py-5 px-4">
                    <div className="row">
                      <div className="col-lg-8 col-sm-12">
                        <div className="row">
                          <div className="col-lg-6 col-sm-12 sm-mt">
                            <div className="my-3">
                              <p className="mb-0 text-grey font12">Full Name</p>
                              <p className="mb-0">
                                {student?.user?.first_name}{' '}
                                {student?.user?.middle_name}{' '}
                                {student?.user?.last_name}
                              </p>
                            </div>
                            <div className="my-3">
                              <p className="mb-0 text-grey font12">EMAIL</p>
                              <p className="mb-0">
                                {student?.user?.email_address}
                              </p>
                            </div>
                            <div className="my-3">
                              <p className="mb-0 text-grey font12">Address</p>
                              <p className="mb-0">
                                {student?.user?.city}, {student?.user?.country}
                              </p>
                            </div>
                            <div className="my-3">
                              <p className="mb-0 text-grey font12">
                                PHONE NUMBER
                              </p>
                              <p className="mb-0">
                                {student?.user?.phone_number}
                              </p>
                            </div>
                            <div className="my-3">
                              <p className="mb-0 text-grey font12">
                                Date of Birth
                              </p>
                              <p className="mb-0">
                                <Moment format="LL">
                                  {student?.user?.dob}
                                </Moment>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-ResultsCertificates"
                  role="tabpanel"
                  aria-labelledby="pills-ResultsCertificates-tab"
                >
                  <div className="p-4 bg-white">
                    <div>
                      {student?.certificate?.map((each) => (
                        <div
                          className="row my-5 align-items-center"
                          key={each._id}
                        >
                          <div className="col-md-2 col-6 mx-auto my-auto">
                            <img
                              src={certlogo}
                              alt=""
                              className="w-100 cert-logo"
                            />
                          </div>
                          <div className="col-md-9 col-sm-12 sm-text-center">
                            <div className="row align-items-center">
                              <div className="col-lg-6 col-sm-12">
                                <p className="mb-1 font-bold">
                                  {each.modules_details[0].name} Course
                                </p>
                                <p className="mb-1 font12 text-grey">
                                  {each.status}
                                </p>
                                <p className="mb-1 font12">
                                  Issued{' '}
                                  <Moment format="LL">
                                    {each.createdDate}
                                  </Moment>
                                  <span className="ml-3 badge completed">
                                    Certificate
                                  </span>
                                </p>
                              </div>
                              <div className="col-lg-6 col-sm-12">
                                <div className="d-flex justify-content-between record py-2 px-3  font12">
                                  <div
                                    data-toggle="modal"
                                    data-target="#viewModal"
                                    className="click"
                                  >
                                    <i className="fa fa-eye"></i>
                                    <a
                                      target="_blanc"
                                      href={each.certificate_link}
                                      className="ml-3 text-black"
                                    >
                                      View
                                    </a>
                                  </div>
                                  <div className="click">
                                    <i className="fa fa-download"></i>
                                    <a
                                      href={each.certificate_link}
                                      className="ml-3 text-black"
                                      download
                                    >
                                      Download
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12">
                                <hr className="mb-0" />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1"></div>
                        </div>
                      ))}
                      {student?.certificate?.length < 1 && (
                        <div className="m-auto  text-grey">
                          You have no certificate yet
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-auto">
          <StudentFooter />
        </div>
      </div>

      {/* <!--edit Modal --> */}
      <EditStudent />
    </div>
  );
};

StudentProfile.propTypes = {
  logoutService: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  student: state.students.students,
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, {logoutService})(StudentProfile);
