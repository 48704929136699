import axios from 'axios';
import {setAlert} from './alert';
import {
  SESSION_CREATED,
  GET_SESSION,
  GET_GROUPED_SESSION,
  SESSION_ERROR,
  LOGOUT,
  CLEAR_SESSION,
  GET_SESSION_COURSES,
  GET_SESSION_DETAIL,
  GET_ACTIVE_SESSION,
  LOADING,
  GET_GROUP_SESSION_COURSES,
  GET_GROUPED_SESSION_DETAIL,
  RELOAD_PAGE,
} from './types';
import {base_url} from './auth';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const token = cookies.get('token');

// create session
export const createSession = (formData) => async (dispatch) => {
  dispatch({type: LOADING});
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const body = formData;
  try {
    const res = await axios.post(
      `${base_url}api/admin/create-session`,
      body,
      config
    );
    dispatch(setAlert(res.data.message, 'success'));
    dispatch({
      type: SESSION_CREATED,
    });
  } catch (error) {
    const errors = error.response;
    console.log(errors);

    if (errors) {
      dispatch(setAlert(errors.data.message, 'error'));
    }
    if (errors.status === 401) {
      console.log(error);
      dispatch({
        type: LOGOUT,
      });
    }
    dispatch({
      type: SESSION_ERROR,
      payload: {
        msg: error.response?.statusText,
        status: error.response?.status,
      },
    });
  }
};

// create session
export const createGroupSession = (formData) => async (dispatch) => {
  dispatch({type: LOADING});
  const config = {
    headers: {
      // 'Content-Type': 'multipart/form-data',
    },
  };

  const body = formData;
  try {
    const res = await axios.post(
      `${base_url}api/admin/create-group`,
      body,
      config
    );
    dispatch(setAlert('Session created', 'success'));
    dispatch({
      type: RELOAD_PAGE,
    });
    dispatch({
      type: SESSION_CREATED,
    });
  } catch (error) {
    console.log(error);
    const errors = error.response;
    console.log(errors);

    if (errors) {
      dispatch(setAlert(errors.data.message, 'error'));
    }
    if (errors?.status === 401) {
      console.log(error);
      dispatch({
        type: LOGOUT,
      });
    }
    dispatch({
      type: SESSION_ERROR,
      payload: {
        msg: error.response?.statusText,
        status: error.response?.status,
      },
    });
  }
};

// update session
export const updateSession = (formData) => async (dispatch) => {
  dispatch({type: LOADING});
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const body = formData;
  try {
    const res = await axios.post(
      `${base_url}api/admin/update-session`,
      body,
      config
    );
    dispatch(setAlert(res.data.message, 'success'));
    dispatch({
      type: GET_SESSION,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response;
    console.log(errors);

    if (errors) {
      dispatch(setAlert(errors.data.message, 'error'));
    }
    if (errors.status === 401) {
      console.log(error);
      dispatch({
        type: LOGOUT,
      });
    }
    dispatch({
      type: SESSION_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// update session
export const updateGroupSession = (payload) => async (dispatch) => {
  dispatch({type: LOADING});
  const config = {
    headers: {
      // 'Content-Type': 'multipart/form-data',
    },
  };

  console.log(payload);
  try {
    const res = await axios.post(
      `${base_url}api/admin/update-group`,
      payload,
      config
    );
    dispatch(setAlert(res.data.message, 'success'));
    dispatch({
      type: SESSION_CREATED,
    });
  } catch (error) {
    const errors = error.response;
    console.log(errors);

    if (errors) {
      dispatch(setAlert(errors.data.message, 'error'));
    }
    if (errors.status === 401) {
      console.log(error);
      dispatch({
        type: LOGOUT,
      });
    }
    dispatch({
      type: SESSION_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// Get sessions
export const fetchSession = () => async (dispatch) => {
  const config = {
    headers: {},
  };
  try {
    const res = await axios.get(`${base_url}api/admin/sessions`, config);
    dispatch({
      type: GET_SESSION,
      payload: res.data.data.sessions,
    });
  } catch (error) {
    dispatch({
      type: SESSION_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// Get group sessions
export const fetchGroupSession = () => async (dispatch) => {
  const config = {
    headers: {},
  };
  try {
    const res = await axios.post(
      `${base_url}api/admin/fetch-group`,
      {},
      config
    );
    dispatch({
      type: GET_GROUPED_SESSION,
      payload: res.data.data.groups,
    });
    console.log(res);
  } catch (error) {
    dispatch({
      type: SESSION_ERROR,
      payload: {
        msg: error.response?.statusText,
        status: error.response?.status,
      },
    });
  }
};

// Get sessions details
export const getSessionDetail = (id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.post(
      `${base_url}api/admin/session-details`,
      {session_id: id},
      config
    );

    dispatch({
      type: GET_SESSION_DETAIL,
      payload: res.data.data.sessions,
    });

    return res.data.data.sessions;
  } catch (error) {
    dispatch({
      type: SESSION_ERROR,
      payload: {
        msg: error,
        status: error,
      },
    });

    return {loading: false};
  }
};

// Get group sessions details
export const getGroupSessionDetail = (id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.post(
      `${base_url}api/admin/group-session-details`,
      {session_id: id},
      config
    );

    dispatch({
      type: GET_GROUPED_SESSION_DETAIL,
      payload: res.data.data.sessions,
    });

    return res.data.data.sessions;
  } catch (error) {
    dispatch({
      type: SESSION_ERROR,
      payload: {
        msg: error,
        status: error,
      },
    });

    return {loading: false};
  }
};
// Get session courses
export const getSessionCourses = (id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.post(
      `${base_url}api/admin/fetch-modules-by-session`,
      {session_id: id},
      config
    );
    dispatch({
      type: GET_SESSION_COURSES,
      payload: res.data.data.modules,
    });

    // return res.data.data.sessions;
  } catch (error) {
    console.log(error);
    dispatch({
      type: SESSION_ERROR,
      payload: {
        msg: error,
        status: error,
      },
    });

    return {loading: false};
  }
};

// Delete session
export const deleteSession = (id) => async (dispatch) => {
  dispatch({type: LOADING});
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.post(
      `${base_url}api/admin/delete-session`,
      {session_id: id},
      config
    );

    dispatch({type: CLEAR_SESSION});
    dispatch({type: SESSION_ERROR});

    dispatch(setAlert('The course has been permanently deleted', 'success'));
    return res;
  } catch (err) {
    console.log(err.response);
    dispatch({
      type: SESSION_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Delete group session
export const deleteGroupSession = (id) => async (dispatch) => {
  dispatch({type: LOADING});
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.post(
      `${base_url}api/admin/delete-group`,
      {id: id},
      config
    );

    dispatch({type: CLEAR_SESSION});
    dispatch({type: SESSION_ERROR});

    dispatch(setAlert('The session has been permanently deleted', 'success'));
    return res;
  } catch (err) {
    console.log(err.response);
    dispatch({
      type: SESSION_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Get user sessions
export const UserSession = () => async (dispatch) => {
  const config = {
    headers: {},
  };
  try {
    const res = await axios.get(`${base_url}api/user/fetch-sessions`, config);
    console.log(res);
    dispatch({
      type: GET_SESSION,
      payload: res.data.data.session,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: SESSION_ERROR,
      payload: {
        msg: error.response?.statusText,
        status: error.response?.status,
      },
    });

    if (error.response.status === 401) {
      dispatch({type: CLEAR_SESSION});
    }
  }
};

// Get user group sessions
export const UserGroupedSession = () => async (dispatch) => {
  const config = {
    headers: {},
  };
  try {
    const res = await axios.post(
      `${base_url}api/user/fetch-group-sessions-courses`,
      {},
      config
    );
    console.log(res);
    dispatch({
      type: GET_GROUPED_SESSION,
      payload: res.data.data.groups,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: SESSION_ERROR,
      payload: {
        msg: error.response?.statusText,
        status: error.response?.status,
      },
    });

    if (error.response.status === 401) {
      dispatch({type: CLEAR_SESSION});
    }
  }
};

// Get user sessions
export const UserActiveSession = () => async (dispatch) => {
  const config = {
    headers: {},
  };
  try {
    const res = await axios.get(
      `${base_url}api/user/fetch-user-sessions`,
      config
    );
    dispatch({
      type: GET_ACTIVE_SESSION,
      payload: res.data.data.session,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: SESSION_ERROR,
      payload: {
        msg: error.response?.statusText,
        status: error.response?.status,
      },
    });
  }
};

//create user session
export const createUserSession = (body) => async (dispatch) => {
  dispatch({type: LOADING});
  const config = {
    headers: {},
  };
  try {
    const res = await axios.post(
      `${base_url}api/user/confirm-group-payment`,
      body,
      config
    );
    // dispatch({
    //   type: GET_ACTIVE_SESSION,
    //   payload: res.data.message,
    // });
    console.log(res);
    return res.data;
  } catch (error) {
    console.log(error);
    console.log(error.response);
    dispatch({
      type: SESSION_ERROR,
      payload: {
        msg: error.response?.statusText,
        status: error.response?.status,
      },
    });
  }
};
