import React from 'react';
import message from '../assets/img/message-white.png';
import phone from '../assets/img/call-white.png';
import {Navbar} from '../components/navbar';
import {Footer} from '../components/footer';

export const Faq = () => {
  return (
    <div>
      <header>
        <div class="container">
          <div class="row">
            <div class="col-lg-10 col-sm-12">
              <div>
                <div class="text-right font14 text-light-green pt-4 mb-4">
                  <span class=" mr-3">
                    <i className="fa fa-phone mr-3"></i>
                    <span class="click">
                      <a href="tel:+16025290439" class="text-light-green">
                        +1 (602) 529-0439
                      </a>
                    </span>
                  </span>
                  |
                  <span>
                    <i className="fa fa-envelope-o mx-3"></i>
                    <span>
                      <a
                        href="mailto:care@iamchurchonline.org"
                        class="text-light-green"
                      >
                        care@iamchurchonline.org
                      </a>
                    </span>
                  </span>
                  <a
                    href="https://www.facebook.com/iamchurchonline/"
                    target="_blanc"
                    className="social-icon text-light-green"
                  >
                    <span
                      class="iconify"
                      data-icon="jam:facebook-circle"
                      data-inline="false"
                    ></span>
                  </a>
                  <a
                    href="https://www.instagram.com/iamchurchonline/"
                    target="_blanc"
                    className="social-icon text-light-green"
                  >
                    <span
                      class="iconify"
                      data-icon="la:instagram"
                      data-inline="false"
                    ></span>
                  </a>
                </div>
                <Navbar />
              </div>
            </div>
            <div class="col-lg-2 col-sm-12"></div>
          </div>
        </div>
        <div class="pl-8 hidden">
          <div class="row my-5 ">
            <div class="col-lg-7 col-sm-12">
              <div class="mt-5">
                <h3 class="font64 text-white">
                  Frequently <span class="text-green">asked</span> Questions
                </h3>
                <p class="font20 my-4 text-light-green">
                  We want you to know that we’re praying for you. God has GREAT
                  plans for you (Jer. 29:11)! <br /> Look through our FAQs or
                  reach us via mail,call, or instant messaging..
                </p>
              </div>
            </div>
            <div class="col-lg-5 col-sm-12 my-auto sm-mt"></div>
          </div>
        </div>
      </header>
      <section className="py-5 px-8 hidden faq-wrapper">
        <h3 className="font48 font-bold">FAQs</h3>
        <div className="row">
          <div className="col-lg-7 col-sm-12">
            <div className="accordion" id="accordionExample">
              <div id="headingOne">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    What is Disciple Training Institute?
                  </p>
                </div>

                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">
                    Disciple Training Institute is an Extension of International
                    Association of Ministries Ministry Training Institute. Which
                    exists to equip passionate followers of Christ in God's Word
                    and create a pathway for Discipleship, Leadership,
                    Financial, Business, Ministry Training and a lifelong
                    learning.
                  </div>
                </div>
              </div>
              <div id="headingTwo">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    How is it Taught?
                  </p>
                </div>

                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">English and Spanish</div>
                </div>
              </div>
              <div id="headingThree">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                  >
                    Is this school accredited?
                  </p>
                </div>

                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">
                    No. While we believe there is a place for accreditation, it
                    is our desire to keep it simple, accessible and affordable.
                    While accreditation is not outside of the scope of
                    possibility, we also want to be careful not to be unduly
                    burdened by requirements of an outside accreditation body.
                    This school is designed for the purposes of learning God's
                    Word.
                  </div>
                </div>
              </div>
              <div id="headingFour">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseFour"
                    aria-expanded="true"
                    aria-controls="collapseFour"
                  >
                    What is the cost for the discipleship Program
                  </p>
                </div>

                <div
                  id="collapseFour"
                  className="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">
                    <div>1. $10.00 Registration per year</div>
                    <div>
                      2. Free tuition
                    </div>
                    <div>3. Option to purchase books</div>
                  </div>
                </div>
              </div>
              <div id="headingFive">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseFive"
                    aria-expanded="true"
                    aria-controls="collapseFive"
                  >
                    Who attends discipleship training
                  </p>
                </div>

                <div
                  id="collapseFive"
                  className="collapse"
                  aria-labelledby="headingFive"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">
                    Many of our students come immediately after graduating from
                    high school or college. Others have already started a career
                    and/or family, and some are even retired. Our students are
                    from all walks of life and in all seasons of life.
                  </div>
                </div>
              </div>
              <div id="headingSix">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseSix"
                    aria-expanded="true"
                    aria-controls="collapseSix"
                  >
                    How long is the Program?
                  </p>
                </div>

                <div
                  id="collapseSix"
                  className="collapse"
                  aria-labelledby="headingSix"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">2 Years (24Months)</div>
                </div>
              </div>
              <div id="headingSeven">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseSeven"
                    aria-expanded="true"
                    aria-controls="collapseSeven"
                  >
                    What is IAM Academy purpose?
                  </p>
                </div>

                <div
                  id="collapseSeven"
                  className="collapse"
                  aria-labelledby="headingSeven"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">
                    The mandate, “Go teach My people faith,”and is a school
                    designed to equip men and women as Disciples, Apostles,
                    Prophets, Evangelists, Pastors, Teachers, and Ministry of
                    Helps. This Assignment was given by God to Apostle Alfred
                    Craig Sr, and Ambassador Beverly Craig the founders of
                    Ministry Training Institute. Today his son, Alfred Craig Jr,
                    the school’s president, and daughter-in-law, Noel Craig, its
                    director, assist with carrying out the mandate.
                  </div>
                </div>
              </div>
              <div id="headingEight">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseEight"
                    aria-expanded="true"
                    aria-controls="collapseEight"
                  >
                    Is IAM Academy for me?
                  </p>
                </div>

                <div
                  id="collapseEight"
                  className="collapse"
                  aria-labelledby="headingEight"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">
                    A. Yes! Whether you feel called to the full-time ministry or
                    the ministry of helps, or would simply like to study the
                    Bible more in-depth, IAM Academy Training is the school for
                    you! While ministry training is an important part of IAM’s
                    vision, our overall mission is to equip and Disciple
                    believers to live successfully and reach their world for
                    Jesus Christ. Our two-year ministry fundamentals program is
                    designed to see students grow stronger in their relationship
                    with God. Simply put, you’ll come out a stronger believer,
                    ready to accomplish whatever God has called you to do! (For
                    those who do feel called to the ministry, we offer programs
                    that provide specialized, hands-on training.)
                  </div>
                </div>
              </div>
              <div id="headingNine">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseNine"
                    aria-expanded="true"
                    aria-controls="collapseNine"
                  >
                    When does school begin ?
                  </p>
                </div>

                <div
                  id="collapseNine"
                  className="collapse"
                  aria-labelledby="headingNine"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">
                    Our Program is On-line it is Open Enrollment.
                  </div>
                </div>
              </div>
              <div id="headingTen">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseTen"
                    aria-expanded="true"
                    aria-controls="collapseTen"
                  >
                    Does IAM offer ministerial licensing and/or Ordinance?
                  </p>
                </div>

                <div
                  id="collapseTen"
                  className="collapse"
                  aria-labelledby="headingTen"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">
                    Yes. International Association of Ministries offers
                    licensing and ordination to qualified students (
                    Ministry Training Institute graduates) in full-time
                    ministry.
                  </div>
                </div>
              </div>
              <div id="headingEleven">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseEleven"
                    aria-expanded="true"
                    aria-controls="collapseEleven"
                  >
                    What denomination is IAM Academy?
                  </p>
                </div>

                <div
                  id="collapseEleven"
                  className="collapse"
                  aria-labelledby="headingEleven"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">
                    We are technically “nondenominational.” However, our beliefs
                    are Charismatic, Pentecostal, and Word of Faith based.
                  </div>
                </div>
              </div>
              <div id="headingTwelve">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseTwelve"
                    aria-expanded="true"
                    aria-controls="collapseTwelve"
                  >
                    How can I apply to IAM Academy?
                  </p>
                </div>

                <div
                  id="collapseTwelve"
                  className="collapse"
                  aria-labelledby="headingTwelve"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">
                    A. You can apply online <br />
                    B. IAM Church App
                  </div>
                </div>
              </div>
              <div id="headingThirteen">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseThirteen"
                    aria-expanded="true"
                    aria-controls="collapseThirteen"
                  >
                    Do I need a high school diploma, GED, SAT, or ACT to apply?
                  </p>
                </div>

                <div
                  id="collapseThirteen"
                  className="collapse"
                  aria-labelledby="headingThirteen"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">
                    No. While we do encourage each person to get as much
                    education as possible, IAM Academy of Ministry requires only
                    that an applicant be old enough to have graduated from high
                    school.
                  </div>
                </div>
              </div>

              <div id="headingFourteen">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseFourteen"
                    aria-expanded="true"
                    aria-controls="collapseFourteen"
                  >
                    Do I have to be a Christian to take the Course?
                  </p>
                </div>

                <div
                  id="collapseFourteen"
                  className="collapse"
                  aria-labelledby="headingFourteen"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">
                    No. Everyone is welcomed. However, a part of the course work
                    is to serve in Church.
                  </div>
                </div>
              </div>

              <div id="headingFifteen">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseFifteen"
                    aria-expanded="true"
                    aria-controls="collapseFifteen"
                  >
                    Is church attendance required?
                  </p>
                </div>

                <div
                  id="collapseFifteen"
                  className="collapse"
                  aria-labelledby="headingFifteen"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">
                    Yes. Service in the local church is a part of the curriculum
                    throughout the IAM Academy School of Ministry training.
                  </div>
                </div>
              </div>

              <div id="headingSixteen">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseSixteen"
                    aria-expanded="true"
                    aria-controls="collapseSixteen"
                  >
                    Will there be homework? Will there be tests? And will there
                    be grades?
                  </p>
                </div>

                <div
                  id="collapseSixteen"
                  className="collapse"
                  aria-labelledby="headingSixteen"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">
                    Yes, there will be homework that each student will be
                    expected to have prepared prior to each class. <br />
                    Each Student will be Required to read the Book <br />
                    Each Student will be Required to do a Summary of Each Class
                    Taught <br />
                    Did you Serve in any Part of Ministry? and what did you
                    learn?
                  </div>
                </div>
              </div>
              <div id="headingSeventeen">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseSeventeen"
                    aria-expanded="true"
                    aria-controls="collapseSeventeen"
                  >
                    What is different about IAM Academy School of Ministry?
                  </p>
                </div>

                <div
                  id="collapseSeventeen"
                  className="collapse"
                  aria-labelledby="headingSeventeen"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">
                    <ul>
                      <li>
                        IAM Academy is Easily Accessible so the student does not
                        have to leave their job, their church, and it makes it
                        extremely affordable.
                      </li>
                      <li>
                        Because "practical" is a core value, every student will
                        have the opportunity to have hands on training in
                        Ministry, be a part of the Ministry you serve in, and
                        have the initiative to be a part of their community.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div id="headingEighteen">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseEighteen"
                    aria-expanded="true"
                    aria-controls="collapseEighteen"
                  >
                    What will be required for a student to enter Ministry
                    Training?
                  </p>
                </div>

                <div
                  id="collapseEighteen"
                  className="collapse"
                  aria-labelledby="headingEighteen"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">
                    Those who complete discipleship, will go through an evaluation
                    period and are assessed and approved by IAM Academy
                    leadership and are recommended by their lead pastor would be
                    eligible to apply for the Ministry Training Institute
                    Preparation Track: <br />
                    In this Next level of ministry training is where you would
                    (Find your Calling) by God Grace’s <br />
                    You will be administered a Spiritual Assessment to Test to
                    see where you most likely would fit in the 5- Fold Ministry
                    Gifts
                    <ul>
                      <li> You will see what your Gifts are</li>
                      <li> You will be taught what those Gifts are</li>
                      <li> You will find out what they mean</li>
                    </ul>
                    Discipleship is open to those who have been assessed and approved
                    by the IAM Academy Leadership and are recommended by their
                    Local Pastor and have either: <br />
                     Qualified, based on other biblical training and/or
                    experience (as determined by IAM Academy Leadership) Still
                    may have to take some Classes but not all. <br />
                    Completion of each courses results in a “Certificate of
                    Completion” <br />
                    <b>
                      All students completing two years of study graduate and
                      are eligible to continue to a third year Programs of
                      Study.
                    </b>
                  </div>
                </div>
              </div>

              <div id="headingNineteen">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseNineteen"
                    aria-expanded="true"
                    aria-controls="collapseNineteen"
                  >
                    If a student finishes discipleship can they apply for credentials?
                  </p>
                </div>

                <div
                  id="collapseNineteen"
                  className="collapse"
                  aria-labelledby="headingNineteen"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">
                    At the end of discipleship training we will assess, with the student, what
                    further training or qualifications, if any may be needed to
                    be licensed as a minister. If further experience or training
                    is needed, we will help create the pathway to that end.
                  </div>
                </div>
              </div>

              <div id="headingTwenty">
                <div className="my-4">
                  <p
                    className="font20 font-bold click"
                    data-toggle="collapse"
                    data-target="#collapseTwenty"
                    aria-expanded="true"
                    aria-controls="collapseTwenty"
                  >
                    You may apply for License and be ordained under
                    International Association of Ministries. It a Yearly
                    Subscription. Ask for an Application once Program is
                    finished.
                  </p>
                </div>

                <div
                  id="collapseTwenty"
                  className="collapse"
                  aria-labelledby="headingTwenty"
                  data-parent="#accordionExample"
                >
                  <div className="font18 text-grey">
                    Lifelong Learning & Path to Credentials apply for
                    credentials with International Association of Ministries. We
                    would assess with the candidate any further requirements
                    that would need to be met to be eligible for ministerial
                    credentials. We would then work with them to find or create
                    appropriate ministry opportunities.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-sm-12 sm-mt" id="contact">
            <div className="text-white">
              <div className="bg-green border-radius p-4 d-flex mb-4">
                <div className="mr-3">
                  <img src={phone} alt="" />
                </div>
                <div>
                  <h3 className="font24">Call Us</h3>
                  <a href="tel:+16025290439" className="text-blue">
                    +1 (602) 529-0439
                  </a>
                  <p className="mb-0">
                    Place a call through to our contact centre for quick answers
                    to all your questions.
                  </p>
                </div>
              </div>
              <div className="bg-green border-radius p-4 d-flex mb-">
                <div className="mr-3">
                  <img src={message} alt="" />
                </div>
                <div>
                  <h3 className="font24">Send Us an Email</h3>
                  <a
                    href="mailto:care@iamchurchonline.org"
                    className="text-blue"
                  >
                    care@iamchurchonline.org
                  </a>
                  <p className="mb-0">
                    If you have further enquiries, send us an email, we are here
                    to help you in any way we can. You can as well fill out the
                    message form
                    <a
                      href="https://iamchurchonline.org/contact"
                      target="_blanc"
                      className="text-blue"
                    >
                      {' '}
                      here
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
