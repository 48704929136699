import React from 'react';
import logo from '../../assets/img/logo.png';
import profile from '../../assets/img/profile.svg';
import '../styles/admin-styles.scss';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {setAlert} from '../../actions/alert';
import {logoutService} from '../../actions/auth';
import PropTypes from 'prop-types';
import {Navbar} from '../../components/navbar';

const AdminNavbar = ({userDetails, logoutService}) => {
  return (
    <div>
      <section className="admin-nav">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img src={logo} alt="" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav ml-auto mr-auto mt-2 mt-lg-0">
                <li className="nav-item">
                  <NavLink
                    to="/admin"
                    exact={true}
                    activeClassName="active"
                    className="nav-link"
                  >
                    DASHBOARD
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/admin/students"
                    exact={false}
                    activeClassName="active"
                    className="nav-link"
                  >
                    STUDENTS
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/admin/staffs"
                    exact={false}
                    activeClassName="active"
                    className="nav-link"
                  >
                    Staffs
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/admin/grouped-sessions"
                    exact={false}
                    activeClassName="active"
                    className="nav-link"
                  >
                    SESSIONS
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/admin/sessions"
                    exact={false}
                    activeClassName="active"
                    className="nav-link"
                  >
                    COURSES
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/admin/courses"
                    exact={false}
                    activeClassName="active"
                    className="nav-link"
                  >
                    CLASSES
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/admin/profile"
                    exact={false}
                    activeClassName="active"
                    className="nav-link"
                  >
                    SETTINGS
                  </NavLink>
                </li>
              </ul>
              <ul className="navbar-nav drop-down align-items-center mt-lg-0">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Hi, {userDetails?.first_name}
                    <span className="ml-2">
                      <i className="fa fa-angle-down"></i>
                    </span>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <NavLink to="/admin/profile" className="dropdown-item">
                      Profile
                    </NavLink>
                    <a
                      href="/login"
                      className="dropdown-item"
                      onClick={logoutService}
                    >
                      Logout
                    </a>
                  </div>
                </li>
                <li className="nav-item with-img">
                  <img
                    src={userDetails.avater ? userDetails.avater : profile}
                    alt=""
                  />
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>
    </div>
  );
};

AdminNavbar.prototype = {
  logoutService: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  userDetails: state.auth.user,
});
export default connect(mapStateToProps, {logoutService})(AdminNavbar);
