import axios from "axios";
import { setAlert } from "./alert";
import {
  GET_COURSE,
  COURSE_ERROR,
  CLEAR_COURSE,
  LOGOUT,
  GET_COURSE_DETAIL,
  GET_SESSION_COURSES,
  GET_GROUP_SESSION_COURSES,
  LOADING,
  COURSE_CREATED,
} from "./types";
import { base_url } from "./auth";
const token = localStorage.getItem("token");

// create course
export const createCourse = (formData) => async (dispatch) => {
  dispatch({ type: LOADING });
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const body = formData;
  try {
    const res = await axios.post(
      `${base_url}api/admin/create-module`,
      body,
      config
    );
    dispatch(setAlert("Class Created", "success"));
    dispatch({
      type: COURSE_CREATED,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    const errors = error.response;
    console.log(errors);

    if (errors) {
      dispatch(setAlert(errors.data, "error"));
    }
    if (errors.status === 401) {
      console.log(error);
      dispatch({
        type: LOGOUT,
      });
    }
    dispatch({
      type: COURSE_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// update course
export const updateCourse = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const body = formData;
  try {
    const res = await axios.post(
      `${base_url}api/admin/update-course`,
      body,
      config
    );
    dispatch(setAlert("Class updated successfully", "success"));
    dispatch({
      type: GET_COURSE,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response;
    console.log(errors);

    if (errors) {
      dispatch(setAlert(errors.data.message, "error"));
    }
    if (errors.status === 401) {
      console.log(error);
      dispatch({
        type: LOGOUT,
      });
    }
    dispatch({
      type: COURSE_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// Get courses
export const fetchCourse = (body) => async (dispatch) => {
  const config = {
    headers: {},
  };

  try {
    const res = await axios.post(
      `${base_url}api/admin/fetch-modules`,
      body,
      config
    );
    console.log(res);
    dispatch({
      type: GET_COURSE,
      payload: res.data.data.modules,
    });
  } catch (error) {
    if (error) {
      console.log(error.response);
      console.log();
      dispatch({
        type: COURSE_ERROR,
        payload: {
          msg: error.response?.statusText,
          status: error.response?.status,
        },
      });
    }
    if (error.response?.status === 401) {
      dispatch({
        type: LOGOUT,
        payload: {
          msg: "session Expired",
        },
      });
    }
  }
};

// Get course details
export const getCourseDetail = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${base_url}api/admin/fetch-module-details`,
      { module: payload },
      config
    );

    dispatch({
      type: GET_COURSE_DETAIL,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: COURSE_ERROR,
      payload: {
        msg: error.response,
        status: error,
      },
    });

    return { loading: false };
  }
};

// Delete course and profile
export const deleteCourse = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${base_url}api/admin/delete-module`,
      { module_id: payload },
      config
    );

    dispatch(setAlert("Class permanently deleted", "success"));
    dispatch({ type: CLEAR_COURSE });
    return res;
  } catch (err) {
    console.log(err);
    dispatch({
      type: COURSE_ERROR,
      payload: {
        msg: err.response?.statusText,
        status: err.response?.status,
      },
    });
  }
};

// Get user courses by session
export const UserSessionCourses = (body) => async (dispatch) => {
  const config = {
    headers: {},
  };

  try {
    const res = await axios.post(
      `${base_url}api/user/fetch-modules-sessions`,
      { session_id: body },
      config
    );
    console.log(res);
    dispatch({
      type: GET_SESSION_COURSES,
      payload: res.data.data.all_modules,
    });
  } catch (error) {
    console.log(error.response);
    dispatch({
      type: COURSE_ERROR,
      payload: {
        msg: error?.response?.statusText,
        status: error?.response?.status,
      },
    });
    if (error.response?.status === 401) {
      dispatch({
        type: LOGOUT,
        payload: {
          msg: "session Expired",
        },
      });
    }
  }
};

// Get user courses by session
export const UserGroupSessionCourses = (body) => async (dispatch) => {
  const config = {
    headers: {},
  };

  try {
    const res = await axios.post(
      `${base_url}api/user/fetch-session-by-group-id`,
      { group_id: body },
      config
    );
    console.log(res);
    dispatch({
      type: GET_GROUP_SESSION_COURSES,
      payload: res.data.data.session,
    });
  } catch (error) {
    console.log(error.response);
    dispatch({
      type: COURSE_ERROR,
      payload: {
        msg: error?.response?.statusText,
        status: error?.response?.status,
      },
    });
    if (error.response?.status === 401) {
      dispatch({
        type: LOGOUT,
        payload: {
          msg: "session Expired",
        },
      });
    }
  }
};

// Get all user courses
export const userCourses = () => async (dispatch) => {
  const config = {
    headers: {},
  };

  try {
    const res = await axios.post(
      `${base_url}api/user/fetch-user-modules`,
      {},
      config
    );
    console.log(res.data);
    dispatch({
      type: GET_COURSE,
      payload: res.data.data.all_modules,
    });
  } catch (error) {
    console.log(error.response);
    dispatch({
      type: COURSE_ERROR,
      payload: {
        msg: error?.response?.statusText,
        status: error?.response?.status,
      },
    });
    if (error.response?.status === 401) {
      dispatch({
        type: LOGOUT,
        payload: {
          msg: "session Expired",
        },
      });
    }
  }
};

// Get course details
export const getUserCourseDetail = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${base_url}api/user/fetch-modules-by-session-id`,
      { session_id: payload },
      config
    );
    dispatch({
      type: GET_COURSE_DETAIL,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: COURSE_ERROR,
      payload: {
        msg: error.response,
        status: error,
      },
    });

    return { loading: false };
  }
};
