import React, {useState, useEffect} from 'react';
import AdminNavbar from './components/Admin-Navbar';
import {AdminFooter} from './components/Admin-Footer';
import {createSession, fetchGroupSession} from '../actions/session';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const AdminCreateSession = ({
  createSession,
  loading,
  fetchGroupSession,
  groupedsession,
  history,
}) => {
  useEffect(() => {
    fetchGroupSession();
  }, [fetchGroupSession]);

  const [preview_image, setpreview_image] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [group_id, setGroup] = useState('');
  const [duration, setDuration] = useState('');
  const [imageName, setImageName] = useState('');

  const onNameChange = (e) => {
    setName(e.target.value);
  };
  const onDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const onGroupChange = (e) => {
    console.log('about to change');
    console.log(e.target.value);
    setGroup(e.target.value);
  };
  const onDurationChange = (e) => {
    setDuration(e.target.value);
  };
  const onImageChange = (e) => {
    let file = e.target.files[0];
    setpreview_image(file);
    setImageName(e.target.files[0].name);
  };
  const onSubmitForm = (e) => {
    e.preventDefault();
    const formResponse = new FormData();
    formResponse.append('preview_image', preview_image);
    formResponse.append('name', name);
    formResponse.append('description', description);
    formResponse.append('duration', duration);
    console.log(formResponse.get('preview_image'));
    if (group_id == '') {
      formResponse.append('group_id', groupedsession[0].id);
    } else {
      formResponse.append('group_id', group_id);
    }
    async function awaitCreate() {
      let dlt = await createSession(formResponse);
      history.push({pathname: '/admin/sessions'});
    }
    awaitCreate();
  };
  return (
    <div className="admin">
      <AdminNavbar />
      <div className="d-body admin-create">
        <div className="container mt-5">
          <form>
            <div className="form-group mb-4">
              <p className="font18 text-grey pt-5">Course Title</p>
              <input
                type="text"
                placeholder="Title"
                className="form-control"
                name="name"
                value={name}
                onChange={(e) => onNameChange(e)}
                required
              />
            </div>
            <div className="form-group mb-4">
              <p className="font18 text-grey pt-5">Course Description</p>
              <textarea
                rows="8"
                className="form-control"
                placeholder="Descriptiion"
                name="description"
                value={description}
                onChange={(e) => onDescriptionChange(e)}
                required
              ></textarea>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-12">
                <div className="form-group mb-4">
                  <p className="font18 text-grey pt-5">Choose Course Session</p>
                  <select
                    className="form-control"
                    name="group_id"
                    onChange={(e) => onGroupChange(e)}
                  >
                    {groupedsession.map((each) => (
                      <option key={each.id} value={each.id}>
                        session {each.name}, year {each.year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="form-group mb-4">
                  <p className="font18 text-grey pt-5">Number of Classes </p>
                  <input
                    type="number"
                    placeholder="number"
                    className="form-control"
                    name="duration"
                    value={duration}
                    onChange={(e) => onDurationChange(e)}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="form-group mb-4">
                  <p className="font18 text-grey pt-5">
                    Preview Image for the course
                  </p>
                  <div>
                    <label className="file-upload">
                      <div>{imageName ? imageName : 'Upload Image'}</div>
                      <div className="upload-btn">Browse</div>
                      <input
                        type="file"
                        id="file-picker"
                        name="preview_image"
                        onChange={(e) => onImageChange(e)}
                        required
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-right mt-4">
              <button
                className="btn btn-green"
                onClick={onSubmitForm}
                disabled={loading}
              >
                Add Course
              </button>
            </div>
          </form>
        </div>
        <div className="mt-auto">
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

AdminCreateSession.prototypes = {
  createSession: PropTypes.func.isRequired,
  fetchGroupSession: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.session.creationLoader,
  groupedsession: state.session.groupedSessions,
});

export default connect(mapStateToProps, {createSession, fetchGroupSession})(
  AdminCreateSession
);
