import React, {useState} from 'react';
import logo from '../../assets/img/logo.png';
import sec1 from '../../assets/img/sec-1.png';
import key2 from '../../assets/img/key-2.png';
import arrow from '../../assets/img/arrow-white.png';
import message from '../../assets/img/message.png';
import google from '../../assets/img/google.png';
import '../../styles/login.scss';
import {Link} from 'react-router-dom';
import {forgotPasswordService} from '../../actions/auth';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const ForgotPassword = ({forgotPasswordService, loading}) => {
  const [formData, setFormData] = useState({
    email_address: '',
  });
  const {email_address} = formData;

  const onFormChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    console.log(formData);
    forgotPasswordService(formData);
  };
  return (
    <div>
      <section className="signIn-wrapper">
        <div className="curve1">
          <img src={sec1} alt="" />
        </div>

        <div className="curve2">
          <img src={key2} alt="" />
        </div>
        <div className="container">
          <div className="sign-container">
            <div>
              <div className="my-5 logo-wrapper">
                <Link to="/">
                  <img src={logo} alt="" className="login-logo" />
                </Link>
              </div>
              <div className="row my-5">
                <div className="col-lg-5 col-sm-12 sm-d-none my-auto">
                  <div className="text-white">
                    <h2 className="font88 font-bold">Welcome Back</h2>
                    <p className="font18">
                      <span>
                        Dont worry we’ll get your account back for you. Please
                        enter the email address associated with your account.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-12 sm-d-none my-auto">
                  <img src={arrow} alt="" className="w-100" />
                </div>
                <div className="col-lg-5 col-sm-12">
                  <div>
                    <form className="p-5 bg-white">
                      <div className="d-flex align-items-center mb-4">
                        <h2 className="login-nav login active mr-3">
                          Forgot Password
                        </h2>
                      </div>
                      <div>
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email address"
                            name="email_address"
                            value={email_address}
                            onChange={(e) => onFormChange(e)}
                            required
                          />
                          <span className="input-icon">
                            <img src={message} alt="" />
                          </span>
                        </div>
                        <div className="my-4">
                          <button
                            className="btn btn-green"
                            onClick={onSubmitForm}
                            disabled={loading}
                          >
                            Reset Password
                          </button>
                        </div>
                        <div className="text-right font14">
                          Dont have an account yet?
                          <Link to="/register" className="text-grey ml-2">
                            Sign Up
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <footer className="mt-auto">
              <div className="d-flex justify-content-between">
                <p>(c) 2020. Discipleship Training Institute</p>
                <p>
                  <Link to="/" className="mr-2">
                    Home
                  </Link>
                  <span className="mr-2">|</span>
                  <span> God has great plans for you ! (Jer. 29:11)</span>
                </p>
              </div>
            </footer>
          </div>
        </div>
      </section>
    </div>
  );
};

ForgotPassword.prototypes = {
  forgotPasswordService: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {forgotPasswordService})(
  ForgotPassword
);
