import React, {useEffect} from 'react';
import {Footer} from '../components/footer';
import {NavbarProgram} from '../components/navbar-program';
import coursebg from '../assets/img/session.jpg';
import course1 from '../assets/img/course1.jpg';
import course2 from '../assets/img/course2.jpg';
import course3 from '../assets/img/course3.jpg';
import course4 from '../assets/img/course4.jpg';
import course5 from '../assets/img/course5.jpg';
import course6 from '../assets/img/course6.jpg';
import course7 from '../assets/img/course7.jpg';
import course8 from '../assets/img/course8.jpg';
import {Link} from 'react-router-dom';

export const Courses = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <div>
        <header className="program-header">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 col-sm-12">
                <div>
                  <div className="text-right font14  pt-4 mb-4">
                    <span className=" mr-3">
                      <i className="fa fa-phone mr-3"></i>
                      <span className="click">
                        <a href="tel:+16025290439">+1 (602) 529-0439</a>
                      </span>
                    </span>
                    |
                    <span>
                      <i className="fa fa-envelope-o mx-3"></i>
                      <span>
                        <a href="mailto:care@iamchurchonline.org" className="">
                          care@iamchurchonline.org
                        </a>
                      </span>
                    </span>
                    <a
                      href="https://www.facebook.com/iamchurchonline/"
                      target="_blanc"
                      className="social-icon"
                    >
                      <span
                        class="iconify"
                        data-icon="jam:facebook-circle"
                        data-inline="false"
                      ></span>
                    </a>
                    <a
                      href="https://www.instagram.com/iamchurchonline/"
                      target="_blanc"
                      className="social-icon"
                    >
                      <span
                        class="iconify"
                        data-icon="la:instagram"
                        data-inline="false"
                      ></span>
                    </a>
                  </div>
                  <NavbarProgram />
                </div>
              </div>
              <div className="col-lg-2 col-sm-12"></div>
            </div>
          </div>
          <div className="pl-8 hidden">
            <div className="row my-5 ">
              <div className="col-lg-6 col-sm-12">
                <div>
                  <h3 className="font60">Our Courses</h3>
                  <p className="font20 my-4 ">
                    The purpose and focus of this institution's educational
                    program is to equip the Christian believer with the
                    knowledge God's word while introducing basic Christian
                    principles that will allow him or her to walk in
                    righteousness with God
                  </p>
                  <Link to="/register">
                    <button className="btn btn-green">Apply Now</button>
                  </Link>
                  <span className="ml-4">
                    Questions? Talk to our <a href="/faq/#contact">team</a>
                  </span>
                </div>
              </div>
              <div className="col-lg-1 col-sm-12"></div>
              <div className="col-lg-5 col-sm-12 my-auto sm-mt">
                <img src={coursebg} alt="" className="w-100" />
              </div>
            </div>
          </div>
        </header>
        <section className="courses-wrapper container py-5">
          <div className="each-course">
            <div className="row">
              <div className="col-lg-3 col-sm-12">
                <img src={course1} alt="" className="w-100" />
              </div>
              <div className="col-lg-8 col-sm-12">
                <p className="font24 font-bold text-blue">Bible Survey</p>
                <p>
                  General overview of the Bible, this class covers basic
                  information including the divisions of the Bible,purpose of
                  the Bible and history of the Bible.
                </p>
              </div>
            </div>
          </div>
          <div className="each-course">
            <div className="row">
              <div className="col-lg-3 col-sm-12">
                <img src={course6} alt="" className="w-100" />
              </div>
              <div className="col-lg-8 col-sm-12">
                <p className="font24 font-bold text-blue">Ministry of Help</p>
                <p>
                  Teaches the student how each of us fits into God's organized
                  plan for his church to minister to the needs of his people.
                </p>
              </div>
            </div>
          </div>
          <div className="each-course">
            <div className="row">
              <div className="col-lg-3 col-sm-12">
                <img src={course3} alt="" className="w-100" />
              </div>
              <div className="col-lg-8 col-sm-12">
                <p className="font24 font-bold text-blue">
                  Christian Development
                </p>
                <p>
                  This class teaches the fundamental principles or developing
                  and growing in those areas that will cause the believer to
                  become mature in their work in righteousness in order that
                  they will be right to be used by God for the kingdom.
                </p>
              </div>
            </div>
          </div>
          <div className="each-course">
            <div className="row">
              <div className="col-lg-3 col-sm-12">
                <img src={course2} alt="" className="w-100" />
              </div>
              <div className="col-lg-8 col-sm-12">
                <p className="font24 font-bold text-blue">Blood Covenant</p>
                <p>
                  This course is designed to teach the believer what his rights
                  and responsibilities are under the new testament covenant of
                  Jesus' blood.
                </p>
              </div>
            </div>
          </div>
          <div className="each-course">
            <div className="row">
              <div className="col-lg-3 col-sm-12">
                <img src={course7} alt="" className="w-100" />
              </div>
              <div className="col-lg-8 col-sm-12">
                <p className="font24 font-bold text-blue">Prayer</p>
                <p>
                  Teaches the fundamental aspect of prayer, also teaches how to
                  develop an effective prayer life and benefits of using the
                  word of God in prayer.
                </p>
              </div>
            </div>
          </div>
          <div className="each-course">
            <div className="row">
              <div className="col-lg-3 col-sm-12">
                <img src={course5} alt="" className="w-100" />
              </div>
              <div className="col-lg-8 col-sm-12">
                <p className="font24 font-bold text-blue">Leadership</p>
                <p>
                  This course is designed to develop the quality of leadership
                  to afford increase in personal and organizational
                  effectiveness.
                </p>
              </div>
            </div>
          </div>
          <div className="each-course">
            <div className="row">
              <div className="col-lg-3 col-sm-12">
                <img src={course4} alt="" className="w-100" />
              </div>
              <div className="col-lg-8 col-sm-12">
                <p className="font24 font-bold text-blue">Worship</p>
                <p>
                  This course is designed to teach the student from a biblical
                  perspertive the principle of praise and worship, with a
                  concentration to daily seek His presence.
                </p>
              </div>
            </div>
          </div>
          <div className="each-course">
            <div className="row">
              <div className="col-lg-3 col-sm-12">
                <img src={course8} alt="" className="w-100" />
              </div>
              <div className="col-lg-8 col-sm-12">
                <p className="font24 font-bold text-blue">Evangelism</p>
                <p>
                  This class explores biblical evangelism and its positive
                  effects on the early church and church today.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};
