import React, {useState, useEffect} from 'react';
import course from '../../assets/img/course-bg3.svg';
import {Link} from 'react-router-dom';
import StudentNavbar from '../component/Student-Navbar';
import {StudentFooter} from '../component/Student-Footer';
import Skeleton from 'react-loading-skeleton';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getUserCourseDetail} from '../../actions/course';
import {userStartVideo, userEndVideo} from '../../actions/student';
import Moment from 'react-moment';
import ReactPlayer from 'react-player/youtube';

const StudentCourseDetails = ({
  location,
  history,
  getUserCourseDetail,
  userStartVideo,
  userEndVideo,
  courses,
  loading,
}) => {
  const id = location.state.id;
  const session_name = location.state.session_name;
  console.log(session_name);

  console.log(courses);
  useEffect(() => {
    getUserCourseDetail(id);
  }, []);

  const [current_duration, setcurrent_duration] = useState(0);
  const [certLink, setcertLink] = useState('');
  const pauseVideo = () => {
    console.log('pause');
  };

  const endVideo = () => {
    console.log(courses?.user_modules?.status);
    if (courses?.user_modules?.status !== 'completed') {
      console.log('end');
      const payload = {
        user_module_id: courses.user_modules.id,
        current_duration: current_duration,
        status: 'completed',
      };
      async function awaitData() {
        let data = await userEndVideo(payload);
        const cert = data.data.data.userCourseModule.certificate_link;
        setcertLink(cert);
        console.log(certLink);
        document.querySelector('.certModal').click();
      }
      awaitData();
    }
  };
  const startVideo = () => {
    console.log('start');
    const payload = {
      session_id: courses.session.sessions_id,
      module_id: courses.session.id,
      current_duration: current_duration,
    };
    console.log(payload);
    if (courses.module_started == 'false') {
      console.log('object');
      userStartVideo(payload);
    }
  };
  const progressVideo = (e) => {
    setcurrent_duration(JSON.stringify(e));
    console.log(e, 'progress');
  };

  // const goBack = () => {
  //   history.back();
  // };

  return loading ? (
    <section className="container">
      <div className="mt-5">
        <div className="row">
          <div className="col-6 my-3">
            <Skeleton height={180} />
          </div>
          <div className="col-6 my-3">
            <Skeleton height={180} />
          </div>
          <div className="col-6 my-3">
            <Skeleton height={180} />
          </div>
          <div className="col-6 my-3">
            <Skeleton height={180} />
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="admin">
      <StudentNavbar />
      <div className="d-body">
        <section className="course-header">
          <div className="container">
            <div className="pt-5">
              <div className="my-4">
                <span className="font20 click my-5 text-white">
                  {/* <span onClick={goBack}>
                    <i className="fa fa-arrow-left mr-3"></i>{' '}
                  </span> */}
                  {session_name}
                </span>
              </div>
            </div>
            <div className="row pb-4">
              <div className="col-lg-8 col-sm-12 my-auto">
                <ReactPlayer
                  url={courses?.session?.video_link}
                  onProgress={progressVideo}
                  onStart={startVideo}
                  onPause={pauseVideo}
                  onEnded={endVideo}
                  controls={true}
                  className="w-100"
                />
              </div>
              <div className="col-lg-4 col-sm-12 sm-mt">
                <div className="lesson-plylist">
                  <div className="p-3 justify-content-between align-items-center d-flex playlist-header">
                    <span>{courses?.session?.name}</span>
                    <span className="font12">
                      {courses?.session?.video_duration} Minutes
                    </span>
                  </div>
                  <p className="font14 text-white mt-4">
                    <i className="fa fa-play mr-2"></i>
                    Lesson: {courses?.session?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="bg-white">
          <div className="container">
            <div className="row py-3">
              <div className="col-lg-8 col-sm-12">
                <ul className="nav">
                  <li className="text-blue">Overview</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-7 col-sm-12">
              <div>
                <p className="font14 font-light">
                  {courses?.session?.description}
                </p>
                <p className="font14">
                  Course completion:{' '}
                  <span className="font-bold">
                    {courses?.user_modules?.status == null
                      ? 'Not Started'
                      : courses?.user_modules?.status}
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-1 col-sm-12"></div>
            <div className="col-lg-4 col-sm-12">
              <div className="row">
                <div className="col-4 mt-4">
                  <p className="text-grey font12 mb-1">
                    <b>LAST UPDATE</b>
                  </p>
                  <p className="font14">
                    <b>
                      <Moment format="LL">
                        {courses?.session?.createdDate}
                      </Moment>
                    </b>
                  </p>
                </div>
                <div className="col-4 mt-4">
                  <p className="text-grey font12 mb-1">
                    <b>CERTIFICATION</b>
                  </p>
                  <p className="font14">
                    <b>One</b>
                  </p>
                </div>
                <div className="col-4 mt-4">
                  <p className="text-grey font12 mb-1">
                    <b>SESSION</b>
                  </p>
                  <p className="font14">
                    <b>{session_name}</b>
                  </p>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>

        <div className="mt-auto">
          <StudentFooter />
        </div>
      </div>
      <div
        className="d-none certModal"
        data-toggle="modal"
        data-target="#certModal"
      ></div>
      ;{/* <!-- download certificate Modal --> */}
      <div
        className="modal fade"
        id="certModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="certModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="certModalLabel">
                Download Certificate
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-4">
              <div className="text-center">
                <p className="font-light">
                  Did you watch the video?
                  <br />
                  Click{' '}
                  <a target="blanc" href={certLink} download>
                    here
                  </a>{' '}
                  to download your certificate
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      ;
    </div>
  );
};
StudentCourseDetails.propTypes = {
  getUserCourseDetail: PropTypes.func.isRequired,
  userStartVideo: PropTypes.func.isRequired,
  userEndVideo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  courses: state.courses.details,
  loading: state.courses.loading,
});
export default connect(mapStateToProps, {
  getUserCourseDetail,
  userStartVideo,
  userEndVideo,
})(StudentCourseDetails);
