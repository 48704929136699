import React from 'react';
import { Link } from 'react-router-dom';

export const AdminFooter = () => {
  return (
    <div>
      <div className="admin-footer container">
          <hr />
        <div className="d-flex flex-wrap justify-content-between">
          <div>
            <div className="copyright-wrapper">
              <p>&copy; 2020 IAM Academy</p>
            </div>
          </div>
          <div>
            <div className="footer-links float-md-right">
              <ul className="nav">
                <li className="nav-item px-4"><Link to='/'>Home</Link></li>
                <li className="nav-item px-4">God has great plans for you (Jer. 29:11)!</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
