import React, {useState} from 'react';
import logo from '../../assets/img/logo.png';
import sec1 from '../../assets/img/sec-1.png';
import key2 from '../../assets/img/key-2.png';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {Link, Redirect} from 'react-router-dom';
import CheckoutForm from './checkout';
import {tuitionPaymentService} from '../../actions/auth';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
const promise = loadStripe('pk_live_uorEnVQPZJrodoFHTkwcwyvS001QYcUjLh');

const TuitionPayment = ({tuitionPaymentService, location, history}) => {
  const [submitted, setsubmitted] = useState(false);
  if (!location.state) {
    return <Redirect to="/my/grouped-sessions" />;
  }
  const token = location.state.token;
  const client_secret = location.state.client_secret;
  const amount = location.state.amount;
  const dividedAmount = amount / 100;
  console.log(localStorage.getItem('non_member'));
  const onSubmitForm = (e) => {
    e.preventDefault();
    setsubmitted(true);
    history.push({
      pathname: `/stripe-tuition`,
      state: {token, amount, client_secret},
    });
  };

  return (
    <div>
      <section className="signIn-wrapper">
        <div className="curve1">
          <img src={sec1} alt="" />
        </div>

        <div className="curve2">
          <img src={key2} alt="" />
        </div>
        <div className="container">
          <div className="sign-container">
            <div>
              <div className="my-5 logo-wrapper">
                <Link to="/">
                  <img src={logo} alt="" className="login-logo" />
                </Link>
              </div>
              <div className="row my-5">
                <div className="col-lg-5 col-sm-12 mx-auto">
                  <div>
                    <form className="p-5 bg-white">
                      <div className="mb-4">
                        <h2 className="font24">
                          The Registration fee is ${dividedAmount}
                        </h2>
                        <p className="text-grey">
                          Thanks for your willingness to be part of this school,
                          please click pay to proceed
                        </p>
                      </div>
                      <div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Amount"
                            name="amount"
                            value={dividedAmount}
                            required
                            disabled
                          />
                        </div>
                        <div className="my-4">
                          <button
                            className="btn btn-green"
                            onClick={onSubmitForm}
                            disabled={submitted}
                          >
                            Click to Pay
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <footer className="mt-auto">
              <div className="d-flex justify-content-between">
                <p>(c) 2020. Discipleship Training Institute</p>
                <p>
                  <Link to="/" className="mr-2">
                    Home
                  </Link>
                  <span className="mr-2">|</span>
                  <span> God has great plans for you ! (Jer. 29:11)</span>
                </p>
              </div>
            </footer>
          </div>
        </div>
      </section>
    </div>
  );
};

TuitionPayment.prototypes = {
  tuitionPaymentService: PropTypes.func.isRequired,
};

export default connect(null, {tuitionPaymentService})(TuitionPayment);
