import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import AdminNavbar from './components/Admin-Navbar';
import {AdminFooter} from './components/Admin-Footer';
import Session from '../assets/img/session.jpg';
import {Link} from 'react-router-dom';
import {fetchGroupSession, deleteGroupSession} from '../actions/session';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import {base_url} from '../actions/auth';

const AdminGroupSession = ({
  history,
  fetchGroupSession,
  deleteGroupSession,
  session: {groupedSessions, loading},
}) => {
  const [randomColor, setrandomColor] = useState();
  const getRandomColor = () => {
    const cardColors = ['purple', 'red', 'blue', 'brown', 'green'];
    const color = cardColors[Math.floor(Math.random() * cardColors.length)];
    console.log(color);
    setrandomColor(color);
  };
  useEffect(() => {
    fetchGroupSession();
    getRandomColor();
  }, [fetchGroupSession]);

  console.log(groupedSessions, loading);
  const viewSessionDetail = (id, amount, year, name) => {
    history.push({
      pathname: `/admin/group-sessions/${id}`,
      state: {id, amount, year, name},
    });
    console.log(id);
  };

  async function deleteSession(id) {
    let data = await deleteGroupSession(id);
    window.location.reload();
  }

  return loading ? (
    <section className="container">
      <div className="mt-5">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="admin">
      <AdminNavbar />
      <div className="d-body bg-white">
        <div className="container mt-5">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <p className="font26 font-light">Sessions</p>
            <p className="font26 font-light">
              <Link to="/admin/group-session/create">
                <button className="btn btn-green">Create Session</button>
              </Link>
            </p>
          </div>
          <section>
            <div className="my-3">
              <div className="row">
                {groupedSessions.map((each) => (
                  <div
                    className="col-lg-3 col-md-4 col-sm-12 my-3 click group-sessions-wrapper"
                    key={each.id}
                  >
                    <div
                      className={`close ${randomColor}`}
                      onClick={() => deleteSession(each.id)}
                    >
                      <span
                        className="iconify"
                        data-icon="ant-design:close-circle-twotone"
                        data-inline="false"
                      ></span>
                    </div>
                    <div
                      className={`font12 group-sessions ${randomColor} p-2 border-radius my-2`}
                      onClick={() =>
                        viewSessionDetail(
                          each.id,
                          each.amount,
                          each.year,
                          each.name
                        )
                      }
                    >
                      <div className="font-bold">Session {each.name}</div>
                      <div className="font-light">
                        {each.year == 'one' ? 'First' : 'Second'} year
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="font-bold">${each.amount}</div>
                        <div className="text-black">
                          <span
                            className="iconify mr-2"
                            data-icon="bx:bxs-bookmark"
                            data-inline="false"
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {groupedSessions.length < 1 && (
                <div className="text-center font20 text-grey">
                  You have not created any session yet
                </div>
              )}
            </div>
          </section>
        </div>
        <div className="mt-auto">
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

AdminGroupSession.prototype = {
  fetchGroupSession: PropTypes.func.isRequired,
  deleteGroupSession: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps, {
  fetchGroupSession,
  deleteGroupSession,
})(AdminGroupSession);
