import React, {useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import CheckoutTuitionForm from './checkout-tuition';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
const promise = loadStripe('pk_live_uorEnVQPZJrodoFHTkwcwyvS001QYcUjLh');

const StripeTuition = ({location}) => {
  if (!location.state) {
    return <Redirect to="/login" />;
  }
  const client_secret = location.state.client_secret;
  const token = location.state.token;

  const paymentload = {token, client_secret};

  return (
    <div className="stripe-checkout-wrapper">
      <Elements stripe={promise}>
        <CheckoutTuitionForm props={paymentload} />
      </Elements>
    </div>
  );
};

export default connect(null, {})(StripeTuition);
