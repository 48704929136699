import React, {useState} from 'react';
import AdminNavbar from './components/Admin-Navbar';
import {AdminFooter} from './components/Admin-Footer';
import {
  updateSession,
  getSessionDetail,
  deleteSession,
  fetchGroupSession,
} from '../actions/session';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useEffect} from 'react';
import Skeleton from 'react-loading-skeleton';
import session from '../reducers/session';
import {Redirect} from 'react-router-dom';

const AdminSessionDetails = ({
  history,
  location,
  updateSession,
  getSessionDetail,
  deleteSession,
  groupedsession,
  fetchGroupSession,
  sessions,
  loading,
  creationLoader,
}) => {
  const id = location.state.id;

  const [preview_image, setpreview_image] = useState(sessions?.preview_image);
  const [name, setName] = useState(sessions?.name);
  const [description, setDescription] = useState(sessions?.description);
  const [groupSession, setgroupSession] = useState(sessions?.group_id);
  const [duration, setDuration] = useState(sessions?.duration);
  const [imageName, setImageName] = useState('');

  useEffect(() => {
    async function awaitData() {
      let data = await getSessionDetail(id);
      setName(data.name);
      setDescription(data.description);
      setDuration(data.duration);
      setgroupSession(data.group_id);
      console.log(data);
    }
    awaitData();
    fetchGroupSession();
  }, []);

  console.log(groupedsession);

  const onNameChange = (e) => {
    setName(e.target.value);
  };
  const onDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const ongroupSessionChange = (e) => {
    setgroupSession(e.target.value);
  };
  const onDurationChange = (e) => {
    setDuration(e.target.value);
  };
  const onImageChange = (e) => {
    let file = e.target.files[0];
    setpreview_image(file);
    setImageName(e.target.files[0].name);
  };
  const onSubmitForm = (e) => {
    e.preventDefault();
    const formResponse = new FormData();
    console.log(preview_image);
    if (preview_image) {
      formResponse.append('preview_image', preview_image);
    }
    formResponse.append('name', name);
    formResponse.append('description', description);
    formResponse.append('group_id', groupSession);
    formResponse.append('duration', duration);
    formResponse.append('session_id', id);
    updateSession(formResponse);
  };

  const viewCourses = (id) => {
    history.push({pathname: `/admin/session-courses/${id}`, state: {id}});
  };

  const _delete = (e) => {
    e.preventDefault();
    async function awaitDelete() {
      let dlt = await deleteSession(id);
      if (dlt) {
        history.push({pathname: '/admin/sessions'});
      }
    }
    awaitDelete();
  };

  return loading && !sessions ? (
    <section className="container">
      <div className="mt-5">
        <div className="row">
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="admin">
      <AdminNavbar />
      <div className="d-body admin-create">
        <div className="container mt-5">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <p className="font26 font-light">{name} </p>
            <div>
              <button className="btn btn-green" onClick={() => viewCourses(id)}>
                View Classes
              </button>
            </div>
          </div>
          <form>
            <div className="form-group mb-4">
              <p className="font18 text-grey pt-5">Course Title</p>
              <input
                type="text"
                placeholder="First"
                className="form-control"
                name="name"
                value={name}
                onChange={(e) => onNameChange(e)}
                required
              />
            </div>
            <div className="form-group mb-4">
              <p className="font18 text-grey pt-5">Course Description</p>
              <textarea
                rows="8"
                className="form-control"
                placeholder="Bible School"
                name="description"
                value={description}
                onChange={(e) => onDescriptionChange(e)}
                required
              ></textarea>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-12">
                <div className="form-group mb-4">
                  <p className="font18 text-grey pt-5">Course Session</p>

                  <select
                    className="form-control"
                    name="groupSession"
                    value={groupSession}
                    onChange={(e) => ongroupSessionChange(e)}
                  >
                    {groupedsession.map((each) => (
                      <option value={each.id}>
                        session {each.name}, year {each.year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="form-group mb-4">
                  <p className="font18 text-grey pt-5">No of Classes</p>
                  <input
                    type="text"
                    placeholder="number"
                    className="form-control"
                    name="duration"
                    value={duration}
                    onChange={(e) => onDurationChange(e)}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="form-group mb-4">
                  <p className="font18 text-grey pt-5">
                    Preview Image for the course
                  </p>
                  <div>
                    <label className="file-upload">
                      <div>{imageName ? imageName : 'Upload Image'}</div>
                      <div className="upload-btn">Browse</div>
                      <input
                        type="file"
                        id="file-picker"
                        name="preview_image"
                        onChange={(e) => onImageChange(e)}
                        required
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <form>
            <div className="d-flex flex-wrap justify-content-between mt-4">
              <button
                className="btn btn-outline-red submit-btn"
                onClick={_delete}
              >
                Delete Course
              </button>
              <button
                className="btn btn-green submit-btn"
                onClick={onSubmitForm}
                disabled={creationLoader}
              >
                Edit Course
              </button>
            </div>
          </form>
        </div>
        <div className="mt-auto">
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

AdminSessionDetails.prototypes = {
  updateSession: PropTypes.func.isRequired,
  getSessionDetail: PropTypes.func.isRequired,
  deleteSession: PropTypes.func.isRequired,
  sessions: PropTypes.object.isRequired,
  fetchGroupSession: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  session: state.session.details,
  loading: state.session.loading,
  creationLoader: state.session.creationLoader,
  groupedsession: state.session.groupedSessions,
});

export default connect(mapStateToProps, {
  updateSession,
  getSessionDetail,
  deleteSession,
  fetchGroupSession,
})(AdminSessionDetails);
