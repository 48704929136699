
import axios from 'axios';
import {
  GET_DASHBOARD,
  LOGOUT,
  AUTH_ERROR
} from './types';
import {base_url} from './auth';
import { setAlert } from './alert';

// const token = localStorage.getItem('token');
import Cookies from 'universal-cookie'
const cookies = new Cookies()
const token = cookies.get('token');

// Get dashboard
export const getDashboard = () => async (dispatch) => {
  const config = {
  };

  try {
    const res = await axios.get(
      `${base_url}api/admin/dashboard`
    );
    dispatch({
      type: GET_DASHBOARD,
      payload: res.data.data,
    });
  } catch (error) {
    console.log(error.response);
    
    if (error.response.status == 401) {
      dispatch({
        type: LOGOUT,
        payload: {
          msg: 'Session Expired',
        },
      });
      dispatch(setAlert('Session Expired'))
    }
  }
};
