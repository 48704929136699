import React, {useState, useEffect} from 'react';
import logo from '../../assets/img/logo.png';
import sec1 from '../../assets/img/sec-1.png';
import key2 from '../../assets/img/key-2.png';
import {Link, Redirect, withRouter} from 'react-router-dom';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {connect} from 'react-redux';
import {setAlert} from '../../actions/alert';
import {tuitionPaymentService} from '../../actions/auth';
import PropTypes from 'prop-types';

const CheckoutTuitionForm = ({
  props,
  setAlert,
  tuitionPaymentService,
  history,
}) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  // const key = location.state.key;

  const paymentload = props;
  const client_secret = paymentload.client_secret;
  const token = paymentload.token;
  const fullName =
    JSON.parse(localStorage.getItem('non_member')).first_name +
    ' ' +
    JSON.parse(localStorage.getItem('non_member')).last_name;

  useEffect(() => {
    setClientSecret(client_secret);
  }, []);

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };
  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: fullName,
        },
      },
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setAlert(`Payment failed ${payload.error.message}`, 'error');
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      console.log(paymentload);
      async function awaitData() {
        let data = await tuitionPaymentService(client_secret);
        console.log(data);
        console.log(data.data.message);
        if (data?.data?.message === 'Payment successful') {
          history.push({pathname: `/login`});
        }
      }
      awaitData();
    }
  };
  return (
    <section className="signIn-wrapper">
      <div className="curve1">
        <img src={sec1} alt="" />
      </div>

      <div className="curve2">
        <img src={key2} alt="" />
      </div>
      <div className="container">
        <div className="sign-container">
          <div>
            <div className="my-5 logo-wrapper">
              <Link to="/">
                <img src={logo} alt="" className="login-logo" />
              </Link>
            </div>
            <div className="row my-5">
              <div className="col-lg-7 col-sm-12 mx-auto">
                <div>
                  <form id="payment-form" onSubmit={handleSubmit}>
                    <CardElement
                      id="card-element"
                      options={cardStyle}
                      onChange={handleChange}
                    />
                    <button
                      disabled={processing || disabled || succeeded}
                      id="submit"
                    >
                      <span id="button-text">
                        {processing ? (
                          <div className="spinner" id="spinner"></div>
                        ) : (
                          'Pay'
                        )}
                      </span>
                    </button>
                    {/* Show any error that happens when processing the payment */}
                    {error && (
                      <div className="card-error" role="alert">
                        {error}
                      </div>
                    )}
                    {/* Show a success message upon completion */}
                    <p
                      className={
                        succeeded ? 'result-message' : 'result-message hidden'
                      }
                    >
                      Payment succeeded, see the result in your
                      <a href={`https://dashboard.stripe.com/test/payments`}>
                        {' '}
                        Stripe dashboard.
                      </a>{' '}
                      Refresh the page to pay again.
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <footer className="mt-auto">
            <div className="d-flex justify-content-between">
              <p>(c) 2020. Discipleship Training Institute</p>
              <p>
                <Link to="/" className="mr-2">
                  Home
                </Link>
                <span className="mr-2">|</span>
                <span> God has great plans for you ! (Jer. 29:11)</span>
              </p>
            </div>
          </footer>
        </div>
      </div>
    </section>
  );
};
CheckoutTuitionForm.prototype = {
  setAlert: PropTypes.func.isRequired,
  tuitionPaymentService: PropTypes.func.isRequired,
};

export default withRouter(
  connect(null, {setAlert, tuitionPaymentService})(CheckoutTuitionForm)
);
