import {
  GET_COURSE,
  CLEAR_COURSE,
  COURSE_ERROR,
  LOGOUT,
  GET_COURSE_DETAIL,
  LOADING,
  COURSE_CREATED,
  GET_GROUP_SESSION_COURSES,
} from '../actions/types';

const initialState = {
  courses: [],
  groupSessionCourses: [],
  details: [],
  loading: true,
  creationLoader: false,
  error: {},
};

export default function (state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_COURSE:
      return {
        ...state,
        courses: payload,
        loading: false,
        creationLoader: false,
      };
    case GET_GROUP_SESSION_COURSES:
      return {
        ...state,
        groupSessionCourses: payload,
        loading: false,
        creationLoader: false,
      };
    case COURSE_CREATED:
      return {
        ...state,
        loading: false,
        creationLoader: false,
      };
    case GET_COURSE_DETAIL:
      return {
        ...state,
        details: payload,
        loading: false,
        creationLoader: false,
      };

    case LOADING:
      return {
        ...state,
        loading: true,
        creationLoader: true,
      };
    case COURSE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        creationLoader: false,
      };
    case LOGOUT:
    case CLEAR_COURSE:
      return {
        ...state,
        courses: null,
        loading: false,
        creationLoader: false,
      };
    default:
      return state;
  }
}
