import React, {useState, useEffect} from 'react';
import course from '../assets/img/course.PNG';
import AdminNavbar from './components/Admin-Navbar';
import {AdminFooter} from './components/Admin-Footer';
import {Link} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import {fetchCourse} from '../actions/course';
import {connect} from 'react-redux';
import {base_url} from '../actions/auth';

const AdminCourses = ({history, fetchCourse, courses: {courses, loading}}) => {
  const body = {
    page: 1,
    status: '',
    start_date: '',
    end_date: '',
    terms: '',
    reference: '',
  };
  useEffect(() => {
    fetchCourse(body);
  }, []);

  console.log(courses, loading);

  const viewCourseDetail = (id) => {
    history.push({pathname: `/admin/courses/${id}`, state: {id}});
    console.log(id);
  };

  return loading && !courses ? (
    <section className="container">
      <div className="mt-5">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="admin">
      <AdminNavbar />
      <div className="d-body">
        <div className="container mt-5">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <p className="font26 font-light">Classes</p>
            <p className="font26 font-light">
              <Link to="/admin/courses/create">
                <button className="btn btn-green">Create Class</button>
              </Link>
            </p>
          </div>
          <section className="my-3">
            <div className="row">
              {courses?.map((each) => (
                <div
                  className="col-lg-3 col-md-4 col-sm-12 my-3 click"
                  key={each?._id}
                >
                  <div
                    className="each-course"
                    onClick={() => viewCourseDetail(each?._id)}
                  >
                    <div className="p-r">
                      <img
                        src={each?.preview_image ? each?.preview_image : course}
                        alt=""
                        className="w-100"
                      />
                      <span className="course-duration p-1">
                        {each?.video_duration} Mins
                      </span>
                    </div>
                    <div className="px-3 pt-3 pb-2">
                      <div className="mb-4">{each?.name}</div>
                      <p className="font12 mb-0 text-grey">Course</p>
                      <p className="font12 mb-0">
                        {each?.sessions_details[0]?.name}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
        <div className="mt-auto">
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

AdminCourses.prototype = {
  fetchCourse: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  courses: state.courses,
});

export default connect(mapStateToProps, {fetchCourse})(AdminCourses);
