import React, {useState} from 'react';
import AdminNavbar from './components/Admin-Navbar';
import {AdminFooter} from './components/Admin-Footer';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createCourse} from '../actions/course';
import {useEffect} from 'react';
import {fetchSession} from '../actions/session';

const AdminCreateCourse = ({
  createCourse,
  fetchSession,
  sessions,
  loading,
  history,
}) => {
  useEffect(() => {
    fetchSession();
  }, []);

  const [preview_image, setpreview_image] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [courseSession, setCourseSession] = useState('');
  const [duration, setDuration] = useState('');
  const [video_link, setVideo_link] = useState('');
  const [imageName, setImageName] = useState('');

  const onNameChange = (e) => {
    setName(e.target.value);
  };
  const onDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const oncourseSessionChange = (e) => {
    console.log(e.target.value);
    setCourseSession(e.target.value);
  };
  const onDurationChange = (e) => {
    setDuration(e.target.value);
  };
  const onLinkChange = (e) => {
    setVideo_link(e.target.value);
  };
  const onImageChange = (e) => {
    let file = e.target.files[0];
    setpreview_image(file);
    setImageName(e.target.files[0].name);
  };
  const onSubmitForm = (e) => {
    e.preventDefault();
    if (courseSession === '') {
      setCourseSession(sessions[0]._id);
    }
    const formResponse = new FormData();
    formResponse.append('preview_image', preview_image);
    formResponse.append('name', name);
    formResponse.append('description', description);
    formResponse.append('video_duration', duration);
    formResponse.append('video_link', video_link);
    if (courseSession == '') {
      formResponse.append('session_id', sessions[0]._id);
    } else {
      formResponse.append('session_id', courseSession);
    }
    console.log(formResponse.get('session_id'));
    async function awaitData() {
      let data = await createCourse(formResponse);
      history.push({pathname: '/admin/courses'});
    }
    awaitData();
  };

  return (
    <div className="admin">
      <AdminNavbar />
      <div className="d-body admin-create">
        <div className="container mt-5">
          <form>
            <div className="form-group mb-4">
              <p className="font18 text-grey pt-5"> Class Title</p>
              <input
                type="text"
                placeholder="Bible Study"
                className="form-control"
                name="name"
                value={name}
                onChange={(e) => onNameChange(e)}
                required
              />
            </div>
            <div className="form-group mb-4">
              <p className="font18 text-grey pt-5 sm-pt-none">
                Class Description
              </p>
              <textarea
                rows="8"
                className="form-control"
                placeholder="description"
                name="description"
                value={description}
                onChange={(e) => onDescriptionChange(e)}
              ></textarea>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-12 mt-4 sm-mt-none">
                <div className="form-group">
                  <p className="font18 text-grey">Choose Class Course</p>
                  <select
                    className="form-control"
                    name="courseSession"
                    onChange={(e) => oncourseSessionChange(e)}
                  >
                    {sessions.map((each) => (
                      <option value={each._id} key={each._id}>
                        {each.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 mt-4 sm-mt-none">
                <div className="form-group">
                  <p className="font18 text-grey">
                    Video Duration <span className="font14">(mins)</span>
                  </p>
                  <input
                    type="number"
                    placeholder="00:00"
                    className="form-control"
                    name="duration"
                    value={duration}
                    onChange={(e) => onDurationChange(e)}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 mt-4 sm-mt-none">
                <div className="form-group">
                  <p className="font18 text-grey">Video Link</p>
                  <input
                    type="text"
                    placeholder="Enter video link"
                    className="form-control"
                    name="video_link"
                    value={video_link}
                    onChange={(e) => onLinkChange(e)}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 mt-4 sm-mt-none">
                <div className="form-group">
                  <p className="font18 text-grey">Preview Image</p>
                  <div>
                    <label className="file-upload">
                      <div>{imageName ? imageName : 'Upload Image'}</div>
                      <div className="upload-btn">Browse</div>
                      <input
                        type="file"
                        id="file-picker"
                        name="preview_image"
                        onChange={(e) => onImageChange(e)}
                        required
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 my-auto text-right mt-4 sm-mt-none ml-auto">
                <button
                  className="btn btn-green submit-btn"
                  onClick={onSubmitForm}
                  disabled={loading}
                >
                  Add Class
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="mt-auto">
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

AdminCreateCourse.prototypes = {
  createCourse: PropTypes.func.isRequired,
  fetchSession: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sessions: state.session.sessions,
  loading: state.courses.creationLoader,
});

export default connect(mapStateToProps, {fetchSession, createCourse})(
  AdminCreateCourse
);
