import React from 'react';
import message from '../assets/img/message-white.png';
import phone from '../assets/img/call-white.png';
import {Navbar} from '../components/navbar';
import {Footer} from '../components/footer';

export const Calendar = () => {
  return (
    <div>
      <header>
        <div class="container">
          <div class="row">
            <div class="col-lg-10 col-sm-12">
              <div>
                <div class="text-right font14 text-light-green pt-4 mb-4">
                  <span class=" mr-3">
                    <i className="fa fa-phone mr-3"></i>
                    <span class="click">
                      <a href="tel:+16025290439" class="text-light-green">
                        +1 (602) 529-0439
                      </a>
                    </span>
                  </span>
                  |
                  <span>
                    <i className="fa fa-envelope-o mx-3"></i>
                    <span>
                      <a
                        href="mailto:care@iamchurchonline.org"
                        class="text-light-green"
                      >
                        care@iamchurchonline.org
                      </a>
                    </span>
                  </span>
                  <a
                    href="https://www.facebook.com/iamchurchonline/"
                    target="_blanc"
                    className="social-icon text-light-green"
                  >
                    <span
                      class="iconify"
                      data-icon="jam:facebook-circle"
                      data-inline="false"
                    ></span>
                  </a>
                  <a
                    href="https://www.instagram.com/iamchurchonline/"
                    target="_blanc"
                    className="social-icon text-light-green"
                  >
                    <span
                      class="iconify"
                      data-icon="la:instagram"
                      data-inline="false"
                    ></span>
                  </a>
                </div>
                <Navbar />
              </div>
            </div>
            <div class="col-lg-2 col-sm-12"></div>
          </div>
        </div>
        <div class="pl-8 hidden">
          <div class="row my-5 ">
            <div class="col-lg-7 col-sm-12">
              <div class="mt-5">
                <h3 class="font64 text-white">
                  Academic <span class="text-green">Calendar</span> Schedule
                </h3>
                <p class="font20 my-4 text-light-green">
                  We want you to know that we’re praying for you. God has GREAT
                  plans for you (Jer. 29:11)! <br /> Look through our FAQs or
                  reach us via mail,call, or instant messaging..
                </p>
              </div>
            </div>
            <div class="col-lg-5 col-sm-12 my-auto sm-mt"></div>
          </div>
        </div>
      </header>
      <section className="container calendar-wrapper">
        <div className="row">
          <div className="col-md-6">
            <div className="mb-4 text-center">
              <p className="font-bold mb-0 font20">***ORIENTATION***</p>
              <p className="font-light">September 8</p>
            </div>
            <div className="mb-4 text-center">
              <p className="font-bold mb-0 font20">***1st. DAY OF CLASS***</p>
              <p className="font-light">September15</p>
            </div>
            <div className="mb-4 text-center">
              <p className="font-bold mb-0 font20">***THANKSGIVING BREAK***</p>
              <p className="font-light">November 22nd. – 28th.</p>
            </div>
            <div className="mb-4 text-center">
              <p className="font-bold mb-0 font20">***CHRISTMAS BREAK***</p>
              <p className="font-light">December 16th – January 4th</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-4 text-center">
              <p className="font-bold mb-0 font20">***SPRING BREAK***</p>
              <p className="font-light">March 30 – April 5</p>
            </div>
            <div className="mb-4 text-center">
              <p className="font-bold mb-0 font20">***GRADUATION***</p>
              <p className="font-light">July TBD</p>
            </div>
            <div className="mb-4 text-center">
              <p className="font-bold mb-0 font20">****SUMMER BREAK****</p>
              <p className="font-light">June 29th. – July 27th</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
