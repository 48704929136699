import React from 'react';
import logoBlue from '../assets/img/logo.png';
import {Link} from 'react-router-dom';
import mark from '../assets/img/mark.png';

export const Footer = () => {
  return (
    <div className="mt-auto">
      <footer>
        <div className="footer-sign">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-12 text-white">
                <h3 className="font64">Apply Now</h3>
                <p className="mb-0 mt-4">
                  Do not miss this opportunity to discover and fulfill God’s
                  Plan and Purpose for your life.
                </p>
              </div>
              <div className="col-lg-4 col-sm-12 ml-auto my-auto sm-mt text-right sm-text-left">
                <Link to="/register">
                  <button
                    className="btn btn-green text-white wow tada"
                    data-wow-duration="1s"
                  >
                    Click to Apply
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="px-8 py-10 hidden">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <h3 className="font45">Visitors sheet</h3>
              <p className="mb-0 mt-2 ml-2">Do you have anymore questions?</p>
            </div>
            <div className="col-lg-4 col-sm-12 ml-auto my-auto sm-mt text-right sm-text-left">
              <a href="mailto:care@iamchurchonline.org?subject=IAM Academy Visitors Sheet&body= Name:%0D%0A E-mail:%0D%0A Telephone #%0D%0A %0D%0A">
                <button
                  className="btn btn-green text-white wow tada"
                  data-wow-duration="1s"
                >
                  Reach Us Now
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="footer-course">
          <div className="container">
            {/* <div className="row text-grey my-4">
            <div className="col-lg-6 col-sm-12">
              <span>DTI - All rights reserved</span>
            </div>
            <div className="col-lg-6 col-sm-12 sm-mt text-right sm-text-left click">
              God has great plans for you (Jer. 29:11)!
            </div>
          </div> */}
            <div className="row my-5">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="wow flipInX mb-5">
                  <img src={logoBlue} alt="" className="logo" />
                </div>
                <p>
                  1st. Year @IAM Academy <br />
                  Our 1st. Year is designed to give students a Strong Biblical
                  Foundation along with practical experience in Life.
                </p>
                <p>
                  2nd Year @IAM Academy <br />
                  Our 2nd Year, Students will gain deeper knowledge about the
                  Bible and the Scriptures.
                </p>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 lists">
                <p className="font26 font-bold">Core Classes</p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Foundations of Faith
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Ministry of Helps
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Time Management
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Christian Development
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Grace
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Salvation / Water Baptism
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Prayer
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Battle of Mind
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Authority of a Believer
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Praise & Worship
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Redemption Realities
                </p>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 lists">
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Money Mindset
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Becoming a Leader
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Money Management
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Holy Spirit
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Health & Healing
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Words you Speak
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Excellence
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Integrity/ Ethics
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Successful Family
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Purpose
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Potential
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Spiritual Authority
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Gifts of the Spirit
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-8 py-10 hidden">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-sm-6 col-md-4">
              <div className="row">
                <div className="col-6">
                  <p className="font-bold">Institute</p>
                  <p className=" click">
                    <Link to="/about">About Us</Link>
                  </p>
                  <p className=" click">
                    <Link to="/faq">FAQs</Link>
                  </p>
                </div>
                <div className="col-6">
                  <p className="font-bold">Resources</p>
                  <p className=" click">
                    <Link to="/register">Apply Now</Link>
                  </p>
                  <p className=" click">
                    <Link to="/faq">FAQs</Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 sm-mt">
              <div className="row">
                <div className="col-6">
                  <p className="font-bold">Features</p>
                  <p className=" click">
                    <Link to="/courses">Courses</Link>
                  </p>
                </div>
                <div className="col-6">
                  <p className="font-bold">Contact</p>
                  <p>
                    <a
                      href="mailto:care@iamchurchonline.org"
                      className="text-grey"
                    >
                      care@iamchurchonline.org
                    </a>
                  </p>
                  <p>
                    <a href="tel:+16025290439" className="text-grey">
                      +1 (602) 529-0439
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </footer>
    </div>
  );
};
