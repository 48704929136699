import React from 'react';
import logoWhite from '../assets/img/logo.png';
import {Link, NavLink} from 'react-router-dom';

export const Navbar = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg container">
        <Link to="/" className="navbar-brand mr-5">
          <img src={logoWhite} alt="" className="logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon text-light-green">
            <i className="fa fa-bars"></i>
          </span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>

            <li className="nav-item">
              <div class="dropdown show">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  About
                  <span
                    class="iconify ml-2"
                    data-icon="fa:angle-double-down"
                    data-inline="false"
                  ></span>
                </a>

                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <a href="/about/#welcome" className="nav-link dropdown-item">
                    Welcome
                  </a>
                  <a href="/about/#founders" className="nav-link dropdown-item">
                    Founders
                  </a>
                  <a href="/about/#purpose" className="nav-link dropdown-item">
                    Purpose, Vision, and Mission
                  </a>
                  <Link to="/believe" className="nav-link dropdown-item">
                    What We Believe
                  </Link>
                  <Link
                    to="/about/#location"
                    className="nav-link dropdown-item"
                  >
                     Our Location
                  </Link>
                </div>
              </div>
            </li>

            <li className="nav-item">
              <div class="dropdown show">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Academics
                  <span
                    class="iconify ml-2"
                    data-icon="fa:angle-double-down"
                    data-inline="false"
                  ></span>
                </a>

                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <Link to="/courses" className="nav-link dropdown-item">
                    Courses
                  </Link>
                  <Link to="/staffs" className="nav-link dropdown-item">
                    Faculty & Staff
                  </Link>
                  <Link to="/calendar" className="nav-link dropdown-item">
                    Academic Calendar
                  </Link>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <a href="/faq/#contact" className="nav-link">
                Contact
              </a>
            </li>
            <li className="nav-item">
              <Link to="/faq" className="nav-link">
                FAQ
              </Link>
            </li>
          </ul>
          <div>
            <Link to="/login" className="nav-link">
              Login
            </Link>
          </div>
          <div>
            <Link to="/register" className="nav-link">
              Apply Now
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};
