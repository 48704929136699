import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import AdminNavbar from './components/Admin-Navbar';
import {AdminFooter} from './components/Admin-Footer';
import Session from '../assets/img/session.jpg';
import {Link} from 'react-router-dom';
import {fetchSession} from '../actions/session';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import {base_url} from '../actions/auth';

const AdminSession = ({
  history,
  fetchSession,
  session: {sessions, loading},
}) => {
  useEffect(() => {
    fetchSession();
  }, [fetchSession]);

  console.log(sessions, loading);
  const viewSessionDetail = (id) => {
    history.push({pathname: `/admin/sessions/${id}`, state: {id}});
    console.log(id);
  };

  return loading && !sessions ? (
    <section className="container">
      <div className="mt-5">
        <div className="row">
          <div className="col-md-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-md-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-md-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-md-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-md-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-md-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-md-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-md-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
        </div>
      </div>
    </section>
  ) : !loading && sessions < 1 ? (
    <div>
      <AdminNavbar />
      <div className="d-body">
        <div className="container mt-5">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <p className="font26 font-light">Courses</p>
            <p className="font26 font-light">
              <Link to="/admin/sessions/create">
                <button className="btn btn-green">Create Course</button>
              </Link>
            </p>
          </div>
          <div className="text-center font20 text-grey mt-5">
            Sorry you have no active sessions yet
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="admin">
      <AdminNavbar />
      <div className="d-body">
        <div className="container mt-5">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <p className="font26 font-light">Courses</p>
            <p className="font26 font-light">
              <Link to="/admin/sessions/create">
                <button className="btn btn-green">Create Course</button>
              </Link>
            </p>
          </div>
          <section>
            <div className="my-3">
              <div className="row">
                {sessions.length < 1 ? (
                  <>
                    <div className="text-center font20 text-grey">
                      You have not created any course yet
                    </div>
                  </>
                ) : (
                  <>
                    {sessions.map((each) => (
                      <div
                        className="col-lg-3 col-md-4 col-sm-12 my-3 click"
                        key={each._id}
                        onClick={() => viewSessionDetail(each._id)}
                      >
                        <div className="each-course">
                          <div className="p-r">
                            <img
                              src={each.preview_image}
                              alt=""
                              className="w-100"
                            />
                            <span className="course-duration p-1">
                              session {each.groups_id[0]?.name}
                            </span>
                          </div>
                          <div className="px-3 pt-3 pb-2">
                            <div className="mb-4">{each.name}</div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <p className="font12 mb-0 text-grey">
                                  No of classes
                                </p>
                                <p className="font12 mb-0">{each.duration}</p>
                              </div>
                              <div>
                                <p className="font12 mb-0 text-grey">Year</p>
                                <p className="font12 mb-0">
                                  {each.groups_id[0]?.year}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </section>
        </div>
        <div className="mt-auto">
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

AdminSession.prototype = {
  fetchSession: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps, {fetchSession})(AdminSession);
