import React, {useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from './checkout';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
const promise = loadStripe('pk_live_uorEnVQPZJrodoFHTkwcwyvS001QYcUjLh');

const Stripe = ({location}) => {
  if (!location.state) {
    return <Redirect to="/my/grouped-sessions" />;
  }
  const client_secret = location.state.key;
  const group_id = location.state.group_id;
  const paymentload = {client_secret, group_id};
  return (
    <div className="stripe-checkout-wrapper">
      <Elements stripe={promise}>
        <CheckoutForm props={paymentload} />
      </Elements>
    </div>
  );
};

export default connect(null, {})(Stripe);
