import React, {useState} from 'react';
import logo from '../../assets/img/logo.png';
import sec1 from '../../assets/img/sec-1.png';
import key2 from '../../assets/img/key-2.png';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {Link, Redirect} from 'react-router-dom';
import CheckoutForm from './checkout';
import {coursePaymentService} from '../../actions/auth';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
const promise = loadStripe('pk_live_uorEnVQPZJrodoFHTkwcwyvS001QYcUjLh');

const CoursePayment = ({coursePaymentService, location, history}) => {
  const [submitted, setsubmitted] = useState(false);
  if (!location.state) {
    return <Redirect to="/my/grouped-sessions" />;
  }
  const group_id = location.state.group_id;
  const session_name = location.state.session_name;
  const amount = location.state.amount;
  const payload = {amount, group_id};

  const onSubmitForm = (e) => {
    e.preventDefault();
    setsubmitted(true);
    console.log(payload);
    async function awaitKey() {
      let key = await coursePaymentService(payload);
      history.push({pathname: `/stripe`, state: {key, group_id}});
    }
    awaitKey();
  };

  return (
    <div>
      <section className="signIn-wrapper">
        <div className="curve1">
          <img src={sec1} alt="" />
        </div>

        <div className="curve2">
          <img src={key2} alt="" />
        </div>
        <div className="container">
          <div className="sign-container">
            <div>
              <div className="my-5 logo-wrapper">
                <Link to="/">
                  <img src={logo} alt="" className="login-logo" />
                </Link>
              </div>
              <div className="row my-5">
                <div className="col-lg-5 col-sm-12 mx-auto">
                  <div>
                    <form className="p-5 bg-white">
                      <div className="mb-4">
                        <h2 className="font24">
                          {session_name === '0'
                            ? 'Our orientation '
                            : 'Registration for this Session '}
                          costs ${amount}
                        </h2>
                        <p className="text-grey">Please click pay to proceed</p>
                      </div>
                      <div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Amount"
                            name="amount"
                            value={amount}
                            required
                            disabled
                          />
                        </div>
                        <div className="my-4">
                          <button
                            className="btn btn-green"
                            onClick={onSubmitForm}
                            disabled={submitted}
                          >
                            Click to Pay
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <footer className="mt-auto">
              <div className="d-flex justify-content-between">
                <p>(c) 2020. Descipleship Training Institute</p>
                <p>
                  <Link to="/" className="mr-2">
                    Home
                  </Link>
                  <span className="mr-2">|</span>
                  <span> God has great plans for you ! (Jer. 29:11)</span>
                </p>
              </div>
            </footer>
          </div>
        </div>
      </section>
    </div>
  );
};

CoursePayment.prototypes = {
  coursePaymentService: PropTypes.func.isRequired,
};

export default connect(null, {coursePaymentService})(CoursePayment);
