import React from 'react';
import message from '../assets/img/message-white.png';
import phone from '../assets/img/call-white.png';
import {Navbar} from '../components/navbar';
import {Footer} from '../components/footer';
import executives from '../assets/img/executive-pastors.jpg';
import seniors from '../assets/img/senior-pastors.png';
import wells from '../assets/img/staffs/wells.jpg';
import belinda from '../assets/img/staffs/belinda.jpg';
import chavez from '../assets/img/staffs/chavez.jpeg';
import craigJnr from '../assets/img/staffs/craig jnr.jpg';
import craigSnr from '../assets/img/staffs/craig-snr.png';
import fields from '../assets/img/staffs/fields.jpg';
import gloria from '../assets/img/staffs/gloria.jpeg';
import melva from '../assets/img/staffs/melva.jpg';
import meridith from '../assets/img/staffs/meridith.jpg';
import miles from '../assets/img/staffs/miles.png';
import noel from '../assets/img/staffs/noel.png';
import ron from '../assets/img/staffs/ron.jpg';
import shannon from '../assets/img/staffs/shannon.jpg';
import theresa from '../assets/img/staffs/theresa.jpeg';

export const Staffs = () => {
  return (
    <div>
      <header>
        <div class="container">
          <div class="row">
            <div class="col-lg-10 col-sm-12">
              <div>
                <div class="text-right font14 text-light-green pt-4 mb-4">
                  <span class=" mr-3">
                    <i className="fa fa-phone mr-3"></i>
                    <span class="click">
                      <a href="tel:+16025290439" class="text-light-green">
                        +1 (602) 529-0439
                      </a>
                    </span>
                  </span>
                  |
                  <span>
                    <i className="fa fa-envelope-o mx-3"></i>
                    <span>
                      <a
                        href="mailto:care@iamchurchonline.org"
                        class="text-light-green"
                      >
                        care@iamchurchonline.org
                      </a>
                    </span>
                  </span>
                  <a
                    href="https://www.facebook.com/iamchurchonline/"
                    target="_blanc"
                    className="social-icon text-light-green"
                  >
                    <span
                      class="iconify"
                      data-icon="jam:facebook-circle"
                      data-inline="false"
                    ></span>
                  </a>
                  <a
                    href="https://www.instagram.com/iamchurchonline/"
                    target="_blanc"
                    className="social-icon text-light-green"
                  >
                    <span
                      class="iconify"
                      data-icon="la:instagram"
                      data-inline="false"
                    ></span>
                  </a>
                </div>
                <Navbar />
              </div>
            </div>
            <div class="col-lg-2 col-sm-12"></div>
          </div>
        </div>
        <div class="pl-8 hidden">
          <div class="row my-5 ">
            <div class="col-lg-7 col-sm-12">
              <div class="mt-5">
                <h3 class="font64 text-white">
                  Our highly <span class="text-green">Esteemed</span>{' '}
                  Instructors
                </h3>
                <p class="font20 my-4 text-light-green">
                  We want you to know that we’re praying for you. God has GREAT
                  plans for you (Jer. 29:11)! <br /> Look through our FAQs or
                  reach us via mail,call, or instant messaging..
                </p>
              </div>
            </div>
            <div class="col-lg-5 col-sm-12 my-auto sm-mt"></div>
          </div>
        </div>
      </header>
      <section className="container staffs-wrapper mb-5">
        <div className="row text-center">
          <div className="col-lg-3 col-md-4  col-sm-12 my-2">
            <div className="leaders border bg-white box-shadow">
              <img src={craigJnr} alt="" className="w-100" />
              <div className="my-4">
                <p>Pastor Alfred Craig Jr</p>
                <a
                  target="_blanc"
                  href="https://iamchurchonline.org/MINISTERS/alfred-noel-craig"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4  col-sm-12 my-2">
            <div className="leaders border bg-white box-shadow">
              <img src={noel} alt="" className="w-100" />
              <div className="my-4">
                <p> Noel Craig</p>
                <a
                  target="_blanc"
                  href="https://iamchurchonline.org/MINISTERS/pastor-alfred-craig-jr"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4  col-sm-12 my-2">
            <div className="leaders border bg-white box-shadow">
              <img src={craigSnr} alt="" className="w-100" />
              <div className="my-4">
                <p>Apostle Alfred Craig Sr</p>
                <a
                  target="_blanc"
                  href="https://iamchurchonline.org/MINISTERS/apostle-alfred-craig-sr"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4  col-sm-12 my-2">
            <div className="leaders border bg-white box-shadow">
              <img src={belinda} alt="" className="w-100" />
              <div className="my-4">
                <p>Minister Belinda Blackman</p>
                <a
                  target="_blanc"
                  href="https://iamchurchonline.org/MINISTERS/belinda-blackman"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4  col-sm-12 my-2">
            <div className="leaders border bg-white box-shadow">
              <img src={fields} alt="" className="w-100" />
              <div className="my-4">
                <p>Minister Beatrice Fields</p>
                <a
                  target="_blanc"
                  href="https://iamchurchonline.org/MINISTERS/beatrice-fields"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4  col-sm-12 my-2">
            <div className="leaders border bg-white box-shadow">
              <img src={shannon} alt="" className="w-100" />
              <div className="my-4">
                <p>Elder Shannon Dragon</p>
                <a
                  target="_blanc"
                  href="https://iamchurchonline.org/MINISTERS/elder-shannon-dragon"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4  col-sm-12 my-2">
            <div className="leaders border bg-white box-shadow">
              <img src={chavez} alt="" className="w-100" />
              <div className="my-4">
                <p>Elder Freddy & Cathy Chavez</p>
                <a
                  target="_blanc"
                  href="https://iamchurchonline.org/MINISTERS/freddy-and-cathy-chavez"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4  col-sm-12 my-2">
            <div className="leaders border bg-white box-shadow">
              <img src={miles} alt="" className="w-100" />
              <div className="my-4">
                <p>Elder Miles Cox</p>
                <a
                  target="_blanc"
                  href="https://iamchurchonline.org/MINISTERS/miles-cox"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4  col-sm-12 my-2">
            <div className="leaders border bg-white box-shadow">
              <img src={ron} alt="" className="w-100" />
              <div className="my-4">
                <p>Elder Ronnie Smith</p>
                <a
                  target="_blanc"
                  href="https://iamchurchonline.org/MINISTERS/elder-ron-smith"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4  col-sm-12 my-2">
            <div className="leaders border bg-white box-shadow">
              <img src={theresa} alt="" className="w-100" />
              <div className="my-4">
                <p>Elder Theresa Banks</p>
                <a
                  target="_blanc"
                  href="https://iamchurchonline.org/MINISTERS/theresa-banks"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4  col-sm-12 my-2">
            <div className="leaders border bg-white box-shadow">
              <img src={gloria} alt="" className="w-100" />
              <div className="my-4">
                <p>Pastor Gloria Johnson</p>
                <a
                  target="_blanc"
                  href="https://iamchurchonline.org/MINISTERS/pastor-gloria-johnson-smith"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4  col-sm-12 my-2">
            <div className="leaders border bg-white box-shadow">
              <img src={wells} alt="" className="w-100" />
              <div className="my-4">
                <p>Elder Beverly Wells</p>
                <a
                  target="_blanc"
                  href="https://iamchurchonline.org/MINISTERS/beverly-wells"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4  col-sm-12 my-2">
            <div className="leaders border bg-white box-shadow">
              <img src={meridith} alt="" className="w-100" />
              <div className="my-4">
                <p>Elder Stacey Meredith</p>
                <a
                  target="_blanc"
                  href="https://iamchurchonline.org/MINISTERS/elder-stacy-ann-meredith"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4  col-sm-12 my-2">
            <div className="leaders border bg-white box-shadow">
              <img src={melva} alt="" className="w-100" />
              <div className="my-4">
                <p>Elder Melva Townsley</p>
                <a
                  target="_blanc"
                  href="https://iamchurchonline.org/MINISTERS/elder-melva-townsley"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
