import {
  GET_STAFF,
  CLEAR_STAFF,
  STAFF_ERROR,
  RELOAD_PAGE,
  LOGOUT,
  UPDATE_USER,
} from '../actions/types';

const initialState = {
  staffs: {},
  loading: true,
  reloadPage: false,
  error: {},
};

export default function (state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_STAFF:
      return {
        ...state,
        staffs: payload,
        loading: false,
      };
    case UPDATE_USER:
      localStorage.setItem('user', JSON.stringify(payload));
      return {
        ...state,
      };
    case RELOAD_PAGE:
      return {
        ...state,
        reloadPage: true,
      };
    case STAFF_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case LOGOUT:
    case CLEAR_STAFF:
      return {
        ...state,
        staffs: null,
        loading: false,
      };
    default:
      return state;
  }
}
