import React, {useState, useEffect} from 'react';
import activeCourse from '../assets/img/active-course.svg';
import coursesImg from '../assets/img/courses.svg';
import enrolled from '../assets/img/enrolled.svg';
import course from '../assets/img/course.PNG';
import profile from '../assets/img/profile.svg';
import AdminNavbar from './components/Admin-Navbar';
import {AdminFooter} from './components/Admin-Footer';
import {Link} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import {getDashboard} from '../actions/dashboard';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Moment from 'react-moment';

const Dashboard = ({
  history,
  getDashboard,
  dashboard: {dashboard, loading},
}) => {
  const [courses, setcourses] = useState([1, 2, 3, 4]);
  const [Students, setStudents] = useState([1, 2, 3, 4]);

  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  console.log(dashboard);

  const viewStudentDetail = (id) => {
    history.push({pathname: `/admin/students/${id}`, state: {id}});
    console.log(id);
  };

  const viewCourseDetail = (id) => {
    history.push({pathname: `/admin/courses/${id}`, state: {id}});
    console.log(id);
  };

  return loading ? (
    <section className="container">
      <div className="mt-5">
        <div className="row">
          <div className="col-12 my-3">
            <Skeleton height={90} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={90} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={90} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={90} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={90} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={90} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={90} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={90} />
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="admin">
      <AdminNavbar />
      <div className="d-body">
        <div className="container mt-5">
          <p className="font26 font-light">Dashboard</p>
          <div className="dash-cards__wrapper my-3">
            <div className="row">
              <div className="col-12 col-sm-3">
                <Link to="/admin/students">
                  <div className="card py-2 px-3 justify-content-start">
                    <img src={enrolled} alt="" />
                    <div className="c-text px-4">
                      <h6 className="mt-3 mb-0">{dashboard?.total_user}</h6>
                      <p>Enrolled</p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-sm-3">
                <Link to="/admin/sessions">
                  <div className="card py-2 px-3 justify-content-start">
                    <img src={activeCourse} alt="" />
                    <div className="c-text px-4">
                      <h6 className="mt-3 mb-0">{dashboard?.total_sessions}</h6>
                      <p>
                        Active{' '}
                        {dashboard?.total_sessions > 1 ? 'Courses' : 'Course'}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-sm-3">
                <Link to="/admin/courses">
                  <div className="card py-2 px-3 justify-content-start">
                    <img src={coursesImg} alt="" />
                    <div className="c-text px-4">
                      <h6 className="mt-3 mb-0">{dashboard?.total_modules}</h6>
                      <p>
                        {dashboard?.total_modules > 1 ? 'Classes' : 'Classe'}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-sm-3">
                <Link to="/admin/staffs">
                  <div className="card special py-2 px-3 justify-content-start">
                    <div className="c-text px-4">
                      <h6 className="mt-3 mb-0">{dashboard?.total_admins}</h6>
                      <p>Total Admin</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <section className="my-5">
            <div className="d-flex">
              <p className="font18 font-light">Recent Classes</p>
              <Link to="/admin/courses">
                <button className="btn-sm btn btn-green ml-3">View All</button>
              </Link>
            </div>
            <div className="my-3">
              <div className="row">
                {dashboard?.last_four_course?.map((each) => (
                  <div
                    className="col-lg-3 col-md-4 col-sm-12 my-3"
                    key={each.id}
                  >
                    <div
                      className="each-course click"
                      onClick={() => viewCourseDetail(each?.id)}
                    >
                      <div className="p-r">
                        <img
                          src={
                            each?.preview_image ? each?.preview_image : course
                          }
                          alt=""
                          className="w-100"
                        />
                        <span className="course-duration p-1">
                          {each?.video_duration} Mins
                        </span>
                      </div>
                      <div className="px-3 pt-3 pb-2">
                        <div className="mb-4 ">{each?.name}</div>
                        <p className="font12 mb-0 text-grey">Date</p>
                        <p className="font12 mb-0">
                          <Moment format="LL">{each.createdDate}</Moment>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <section className="my-5">
            <div className="d-flex">
              <p className="font18 font-light">Recent Students</p>
              <Link to="/admin/students">
                <button className="btn-sm btn btn-green ml-3">View All</button>
              </Link>
            </div>
            <div className="my-3">
              <div className="card-table">
                <div className="table-responsive mb-5">
                  <table className="table text-nowrap w-100 table-borderless">
                    <tbody className="font14">
                      {dashboard?.last_four_student?.map((each) => (
                        <tr className="click" key={each.id}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div>
                                <img
                                  src={profile}
                                  alt=""
                                  className="profile mr-2"
                                />
                              </div>
                              <div>
                                <p className="mb-0">
                                  {each.first_name} {each.last_name}
                                </p>
                                <p className="mb-0 font14 text-grey">
                                  {each.membership_status === 'yes'
                                    ? 'Member'
                                    : 'Non-Member'}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0 text-grey font12">STATUS</p>
                              <p className="mb-0">{each.status}</p>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0 text-grey font12">
                                PHONE NUMBER
                              </p>
                              <p className="mb-0">{each.phone_number}</p>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0 text-grey font12">CITY</p>
                              <p className="mb-0">{each.city}</p>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0 text-grey font12">
                                DATE ADDED
                              </p>
                              <p className="mb-0">
                                <Moment format="LL">{each.createdDate}</Moment>
                              </p>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="mt-auto">
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

Dashboard.prototype = {
  getDashboard: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {getDashboard})(Dashboard);
