import React, {useEffect} from 'react';
import './App.scss';
import {BrowserRouter, Route, Switch, withRouter} from 'react-router-dom';
import {Home} from './pages/Home';
import {About} from './pages/About';
import {Contact} from './pages/Contact';
import {Faq} from './pages/Faq';
import {Courses} from './pages/Courses';
import Login from './pages/auth/login';
import ResetPassword from './pages/auth/ResetPassword';
import ForgotPassword from './pages/auth/ForgotPassword';
import AdminCourses from './admin/admin-courses';
import AdminCourseDetails from './admin/Admin-course-details';
import AdminCreateCourse from './admin/Admin-Create-Course';
import AdminCreateSession from './admin/Admin-Create-session';
import AdminSession from './admin/Admin-session';
import AdminSessionCourses from './admin/admin-session-courses';
import AdminProfile from './admin/Admin-Profile';
import AdminStudent from './admin/Admin-student';
import AdminStaffs from './admin/Admin-staffs';
import AdminStudentDetails from './admin/Admin-Student-Details';
import Dashboard from './admin/Dashboard';
import StudentProfile from './Students/pages/Student-Profile';
import StudentCourses from './Students/pages/Student-Courses';
import StudentCourseDetails from './Students/pages/Student-CourseDetails';
import StudentSessions from './Students/pages/Student-Sessions';
import Register from './pages/auth/Register';
import Alert from './general-component/Alert';
import AdminSessionDetails from './admin/Admin-session-details';
import StudentSessionCourses from './Students/pages/Student-Session-Courses';
import CoursePayment from './Students/pages/CoursePayment';
import Stripe from './Students/pages/Stripe';
import StripeTuition from './Students/pages/Stripe-tuition';
import TuitionPayment from './Students/pages/TuitionPayment';

//Redux
import {Provider} from 'react-redux';
import store from './store';
import {loadUserService} from './actions/auth';
import setAuthToken from './utils/setAuthToken';
import PrivateRoute from './general-component/PrivateRoute';
import {Calendar} from './pages/Calendar';
import {Staffs} from './pages/Staffs';
import {Believe} from './pages/Believe';
import StudentGroupedSession from './Students/pages/Student-groupedSession';
import StudentGroupsessionCourses from './Students/pages/Student-groupsession-courses';
import AdminGroupSession from './admin/Admin-group-session';
import AdminCreateGroupSession from './admin/Admin-Create-group-session';
import AdminGroupSessionDetails from './admin/Admin-group-session-details';

if (localStorage.token) {
  setAuthToken(localStorage.token);
} else if (localStorage.non_member_token) {
  setAuthToken(localStorage.non_member_token);
}

function App() {
  useEffect(() => {
    store.dispatch(loadUserService());
  }, []);

  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </Provider>
    </>
  );
}

const Main = withRouter(({location}) => {
  return (
    <>
      <Alert />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/courses" component={Courses} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/calendar" component={Calendar} />
        <Route exact path="/staffs" component={Staffs} />
        <Route exact path="/believe" component={Believe} />
        <Route exact path="/reset-password/:id" component={ResetPassword} />
        <Route exact path="/forgotPassword" component={ForgotPassword} />
        <PrivateRoute exact path="/admin" component={Dashboard} />
        <PrivateRoute exact path="/admin/courses" component={AdminCourses} />
        <PrivateRoute exact path="/admin/staffs" component={AdminStaffs} />
        <PrivateRoute
          exact
          path="/admin/courses/create"
          component={AdminCreateCourse}
        />
        <PrivateRoute
          exact
          path="/admin/courses/:id"
          component={AdminCourseDetails}
        />
        <PrivateRoute
          exact
          path="/admin/sessions/create"
          component={AdminCreateSession}
        />
        <PrivateRoute
          exact
          path="/admin/sessions/:id"
          component={AdminSessionDetails}
        />
        <PrivateRoute
          exact
          path="/admin/session-courses/:id"
          component={AdminSessionCourses}
        />
        <PrivateRoute exact path="/admin/sessions" component={AdminSession} />
        <PrivateRoute
          exact
          path="/admin/grouped-sessions"
          component={AdminGroupSession}
        />
        <PrivateRoute
          exact
          path="/admin/group-sessions/:id"
          component={AdminGroupSessionDetails}
        />
        <PrivateRoute
          exact
          path="/admin/group-session/create"
          component={AdminCreateGroupSession}
        />
        <PrivateRoute exact path="/admin/profile" component={AdminProfile} />
        <PrivateRoute exact path="/admin/students" component={AdminStudent} />
        <PrivateRoute
          exact
          path="/admin/students/:id"
          component={AdminStudentDetails}
        />
        <PrivateRoute
          exact
          path="/my/session/courses"
          component={StudentSessionCourses}
        />
        <PrivateRoute exact path="/my/profile" component={StudentProfile} />
        <PrivateRoute exact path="/my/courses" component={StudentCourses} />
        <PrivateRoute
          exact
          path="/my/courses/id"
          component={StudentCourseDetails}
        />
        <PrivateRoute
          exact
          path="/my/grouped-sessions"
          component={StudentGroupedSession}
        />
        <PrivateRoute
          exact
          path="/my/group-sessions/courses"
          component={StudentGroupsessionCourses}
        />
        <PrivateRoute exact path="/my/sessions" component={StudentSessions} />
        <Route exact path="/payment" component={CoursePayment} />
        <Route exact path="/tuition" component={TuitionPayment} />
        <Route exact path="/stripe-tuition" component={StripeTuition} />
        <Route exact path="/stripe" component={Stripe} />
      </Switch>
    </>
  );
});

export default App;
