import React, {useEffect} from 'react';
import {NavbarProgram} from '../components/navbar-program';
import believe from '../assets/img/course2.jpg';
import {Footer} from '../components/footer';
import {Link} from 'react-router-dom';

export const Believe = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="">
      <header className="program-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-sm-12">
              <div>
                <div className="text-right font14  pt-4 mb-4">
                  <span className=" mr-3">
                    <i className="fa fa-phone mr-3"></i>
                    <span className="click">
                      <a href="tel:+16025290439">+1 (602) 529-0439</a>
                    </span>
                  </span>
                  |
                  <span>
                    <i className="fa fa-envelope-o mx-3"></i>
                    <span>
                      <a href="mailto:care@iamchurchonline.org" className="">
                        care@iamchurchonline.org
                      </a>
                    </span>
                  </span>
                  <a
                    href="https://www.facebook.com/iamchurchonline/"
                    target="_blanc"
                    className="social-icon"
                  >
                    <span
                      class="iconify"
                      data-icon="jam:facebook-circle"
                      data-inline="false"
                    ></span>
                  </a>
                  <a
                    href="https://www.instagram.com/iamchurchonline/"
                    target="_blanc"
                    className="social-icon"
                  >
                    <span
                      class="iconify"
                      data-icon="la:instagram"
                      data-inline="false"
                    ></span>
                  </a>
                </div>
                <NavbarProgram />
              </div>
            </div>
            <div className="col-lg-2 col-sm-12"></div>
          </div>
        </div>
        <div className="pl-8 hidden">
          <div className="row my-5 ">
            <div className="col-lg-6 col-sm-12 my-auto">
              <div>
                <h3 class="font64">
                  What <span class="text-green">We</span> Believe
                </h3>
                <p className="font20 my-4 ">
                  A SCHOOL WITH INTEGRITY, SERVICE, EXCELLENCE, COST AND
                  EFFECTIVENESS
                </p>
              </div>
            </div>
            <div className="col-lg-1 col-sm-12"></div>
            <div className="col-lg-5 col-sm-12 my-auto sm-mt">
              <img src={believe} alt="" className="w-100" />
            </div>
          </div>
        </div>
      </header>
      <section className="bg-green">
        <div className=" py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div>
                  <p className="font-bold font20">THE SCRIPTURES</p>
                  <p>
                    <b>The Bible is the inspired Word of God,</b>
                    the product of holy men of old who spoke and wrote as they
                    were moved by the Holy Spirit. The New Covenant, as recorded
                    in the New Testament, we accept as our infallible guide in
                    matters pertaining to conduct and doctrine (2 Tim. 3:16; 1
                    Thess. 2:13; 2 Peter 1:21).
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <p className="font-bold font20">THE GODHEAD</p>
                  <p>
                    <b>
                      Our God is One, but manifested in three Persons—the
                      Father,
                    </b>
                    the Son, and the Holy Spirit, being coequal(Deut. 6:4; Phil.
                    2:6). God the Father is greater than all; the Sender of the
                    Word (Logos) and the Begetter (John 14:28; John 16:28; John
                    1:14). The Son is the Word flesh-covered, the One Begotten,
                    and has existed with the Father from the beginning (John
                    1:1; John 1:18; John 1:14). The Holy Spirit proceeds forth
                    from both the Father and the Son and is eternal (John 14:16;
                    John 15:26).
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <p className="font-bold font20">
                    MAN, HIS FALL AND REDEMPTION
                  </p>
                  <p>
                    <b>
                      Man is a created being, made in the likeness and image of
                      God,
                    </b>
                    but through Adam’s transgression and fall, sin came into the
                    world. The Bible says “…all have sinned, and come short of
                    the glory of God,” and “…There is none righteous, no, not
                    one” (Rom. 3:10; 3:23). Jesus Christ, the Son of God, was
                    manifested to undo the works of the devil and gave His life
                    and shed His blood to redeem and restore man back to God
                    (Rom. 5:14; 1 John 3:8).
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <p className="font-bold font20">
                    ETERNAL LIFE AND THE NEW BIRTH
                  </p>
                  <p>
                    <b>
                      Salvation is the gift of God to man through faith in Jesus
                      Christ.
                    </b>
                    This is separate from works and the Law, and is made
                    operative by grace through faith in Jesus Christ, producing
                    works acceptable to God (Eph. 2:8–10). <br />
                    <b>
                      The New Birth is necessary to all men, and when
                      experienced,{' '}
                    </b>
                    produces eternal life. Man’s first step toward salvation is
                    godly sorrow that worketh repentance (2 Cor. 7:10; John
                    3:3–5; 1 John 5:12).
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <p className="font-bold font20">WATER BAPTISM</p>
                  <p>
                    <b>Baptism in water is by immersion,</b>
                    is a direct commandment of our Lord, and is for believers
                    only. The ordinance is a symbol of the Christian’s
                    identification with Christ in His death, burial, and
                    resurrection (Matt. 28:19; Rom. 6:4; Col. 2:12; Acts
                    8:36–39). <br />
                    The following recommendation regarding the water baptismal
                    formula is adopted; to wit: “On the confession of your faith
                    in the Lord Jesus Christ, the Son of God, and by His
                    authority, I baptize you in the Name of the Father, and the
                    Son, and the Holy Ghost. Amen.”
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <p className="font-bold font20">BAPTISM IN THE HOLY SPIRIT</p>
                  <p>
                    <b>
                      The Baptism in the Holy Ghost is a gift and is accompanied
                      by the initial evidence of speaking in other tongues.{' '}
                    </b>
                    The Baptism in the Holy Spirit and fire is a gift from God
                    as promised by the Lord Jesus Christ to all believers in
                    this dispensation and is received subsequent to the new
                    birth. This experience is accompanied by the initial
                    evidence of speaking in other tongues as the Holy Spirit
                    Himself gives utterance (Matt. 3:11; John 14:16,17; Acts
                    1:8; Acts 2:38,39; Acts 19:1–7; Acts 2:1–4).
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <p className="font-bold font20">SANCTIFICATION</p>
                  <p>
                    <b>
                      We believe in sanctification, which is living a life of
                      holiness.
                    </b>
                    The Bible teaches that without holiness no man can see the
                    Lord. We believe in the Doctrine of Sanctification as a
                    definite, yet progressive work of grace, commencing at the
                    time of regeneration and continuing until the consummation
                    of salvation at Christ’s return (Heb. 12:14; 1 Thess. 5:23;
                    2 Peter 3:18; 2 Cor. 3:18; Phil. 3:12–14; 1 Cor. 1:30).
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <p className="font-bold font20">DIVINE HEALING</p>
                  <p>
                    <b>
                      Healing is the privilege of every member of the Church
                      today provided through Jesus’ death on the Cross.
                    </b>{' '}
                    Healing is for the physical ills of the human body and is
                    wrought by the power of God through the prayer of faith, and
                    by the laying on of hands. It is provided for in the
                    atonement of Christ, and is the privilege of every member of
                    the Church today (James 5:14,15; Mark 16:18; Isa. 53:4,5;
                    Matt. 8:17; 1 Peter 2:24).
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <p className="font-bold font20">
                    HELL AND ETERNAL RETRIBUTION
                  </p>
                  <p>
                    <b>
                      The one who physically dies in his sins without accepting
                      Christ is hopelessly and eternally lost in the lake of
                      fire and, therefore, has no further opportunity of hearing
                      the Gospel or repenting.
                    </b>
                    The lake of fire is literal. The terms “eternal” and
                    “everlasting,” used in describing the duration of the
                    punishment of the damned in the lake of fire, carry the same
                    thought and meaning of endless existence as used in denoting
                    the duration of joy and ecstasy of saints in the Presence of
                    God (Heb. 9:27; Rev. 19-20).
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <p className="font-bold font20">
                    RESURRECTION OF THE JUST AND THE RETURN OF OUR LORD
                  </p>
                  <p>
                    <b>
                      Jesus will return and “. . . The dead in Christ shall rise
                      first: Then we which are alive and remain shall be caught
                      up together with them in the clouds to meet the Lord in
                      the air . . .” (1 Thess. 4:16–17).
                    </b>
                    The angels said to Jesus’ disciples, “…This same Jesus,
                    which is taken up from you into heaven, shall so come in
                    like manner as ye have seen him go into heaven.” His coming
                    is imminent. When He comes, “…The dead in Christ shin shall
                    be caught up together with them in the clouds to meet the
                    Lord in the air…” (Acts 1:11; 1 Thess. 4:16,1 Following the
                    Tribulation, He shall return to earth as King of kings, and
                    Lord of lords, and together with His saints, who shall be
                    kings and priests, He shall reign a thousand years (Rev.
                    5:10; 20:6).
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <Footer />
      </div>
    </div>
  );
};
