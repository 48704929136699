import React, { useEffect } from "react";
import { Navbar } from "../components/navbar";
import homeBg from "../assets/img/placeholder.png";
import { VideoModal } from "../components/VideoModal";
import { Footer } from "../components/footer";
import feature1 from "../assets/img/guarantee-certificate.png";
import feature2 from "../assets/img/clocks.png";
import feature3 from "../assets/img/technical-support.png";
import feature4 from "../assets/img/certificate.png";
import image1 from "../assets/img/image1.jpg";
import image2 from "../assets/img/image2.jpg";
import mark from "../assets/img/mark.png";
import { Link } from "react-router-dom";

export const Home = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="d-body">
      <section>
        <header>
          <div className="container">
            <div className="row">
              <div className="col-lg-10 col-sm-12">
                <div>
                  <div className="text-right font14 text-light-green pt-4 mb-4">
                    <span className=" mr-3">
                      <i className="fa fa-phone mr-3"></i>
                      <span className="click">
                        <a href="tel:+16025290439" className="text-light-green">
                          +1 (602) 529-0439
                        </a>
                      </span>
                    </span>
                    |
                    <span>
                      <i className="fa fa-envelope-o mx-3"></i>
                      <span>
                        <a
                          href="mailto:care@iamchurchonline.org"
                          className="text-light-green"
                        >
                          care@iamchurchonline.org
                        </a>
                      </span>
                    </span>
                    <a
                      href="https://www.facebook.com/iamchurchonline/"
                      target="_blanc"
                      className="social-icon text-light-green"
                    >
                      <span
                        class="iconify"
                        data-icon="jam:facebook-circle"
                        data-inline="false"
                      ></span>
                    </a>
                    <a
                      href="https://www.instagram.com/iamchurchonline/"
                      target="_blanc"
                      className="social-icon text-light-green"
                    >
                      <span
                        class="iconify"
                        data-icon="la:instagram"
                        data-inline="false"
                      ></span>
                    </a>
                  </div>
                  <Navbar />
                </div>
              </div>
              <div className="col-lg-2 col-sm-12"></div>
            </div>
          </div>
          <div className="pl-8 hidden pt10vh">
            <div className="row my-5 ">
              <div
                className="col-lg-6 col-sm-12 wow fadeInLeft"
                data-wow-duration="1s"
              >
                <div>
                  <h3 className="font60 text-white">
                    Discipleship Training
                    <span className="text-green ml-2">Institute</span>
                  </h3>
                  <p className="font20 my-4 text-light-green">
                    A whole new world of learning, an Extension of International
                    Association of Ministry Training Institute. One under
                    descipline for Instruction to produce maturity
                  </p>
                  <Link to="/register">
                    <button
                      className="btn btn-green wow tada"
                      data-wow-duration="2s"
                    >
                      Apply Now
                    </button>
                  </Link>
                  <span className="text-white ml-4">
                    Questions? Talk to our <a href="/faq/#contact">team</a>
                  </span>
                </div>
              </div>
              <div className="col-lg-1 col-sm-12"></div>
              <div
                className="col-lg-5 col-sm-12 my-auto sm-mt wow fadeInRight"
                data-wow-duration="1s"
              >
                <img
                  src={homeBg}
                  alt=""
                  className="w-100 click"
                  data-toggle="modal"
                  data-target="#videoModal"
                />
              </div>
            </div>
          </div>
        </header>
        <VideoModal />
        <section className="container py-space mt-5">
          <div className="row">
            <div
              className="col-lg-3 col-sm-12 feature-box wow bounceIn"
              data-wow-duration="1s"
            >
              <div className="p-4">
                <img src={feature4} alt="" />
                <p className="font-museo-bd">Certificate</p>
                <div className="text-grey font14">
                  DTI gives a solid accredited results on completion of each
                  course
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-12 feature-box wow bounceIn"
              data-wow-duration="2s"
            >
              <div className="p-4">
                <img src={feature2} alt="" />
                <p className="font-museo-bd">Flexibility</p>
                <div className="text-grey font14">
                  Not only can you go to class in your pajamas, you might not
                  need to get out of bed.
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-12 feature-box wow bounceIn"
              data-wow-duration="4s"
            >
              <div className="p-4">
                <img src={feature1} alt="" />
                <p className="font-museo-bd">Quality</p>
                <div className="text-grey font14">
                  This bible college can help cultivate a deeper understanding
                  of the Bible
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-12 feature-box wow bounceIn"
              data-wow-duration="3s"
            >
              <div className="p-4">
                <img src={feature3} alt="" />
                <p className="font-museo-bd"> Support</p>
                <div className="text-grey font14">
                  We have a readily standby team, who are available to support
                  you every step of the way.
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="py-5 bg-bible">
          <div className="container">
            <div className="text-center row">
              <div className="mx-auto col-lg-8 col-sm-12">
                <h3 className="font-museo-bd font40"></h3>
                <p className="font18">
                  Make every effort to keep the oneness of the Spirit in the
                  bond of peace [each individual working together to make the
                  whole successful]. There is one body [of believers] and one
                  Spirit—just as you were called to one hope when called [to
                  salvation] (Amplified) <br />
                  And [His gifts to the church were varied and] He Himself
                  appointed some as apostles [special messengers,
                  representatives], some as prophets [who speak a new message
                  from God to the people], some as evangelists [who spread the
                  good news of salvation], and some as pastors and teachers [to
                  shepherd and guide and instruct], [and He did this] to fully
                  equip and perfect the saints (God’s people) for works of
                  service, to build up the body of Christ [the church];
                  (Amplified)
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div class="bg-green my-3 core-values text-white">
                <h3 class="font28 font-bold">Core Values</h3>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Integrity & Excellence
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Compassion & Passion for Results
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  We put the Word and Scripture of God first in our life.
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  We live by faith.
                </p>
                <div class="line-under"></div>
              </div>
            </div>

            <div className="col-md-6">
              <div class="bg-green my-3 core-values text-white">
                <h3 class="font28 font-bold">Core Values</h3>
                <div class="line-under"></div>

                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  We walk in love.
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  We pray about everything.
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  We honor God and are led by the Holy Spirit
                </p>
                <div class="line-under"></div>
                <p class="font-light text-white mb-0 mt-3">
                  <img src={mark} alt="" class="pr-1" />
                  Clarity of Purpose
                </p>
                <div class="line-under"></div>
              </div>
            </div>
          </div>
        </div>
        <section className="container my-5">
          <div className="row">
            <div className="col-md-1 col-sm-12"></div>
            <div className="col-md-5 col-sm-12 wow fadeInLeft">
              <img src={image1} alt="" className="learn-img" />
            </div>
            <div className="col-md-5 col-sm-12 my-auto">
              <p className="font30 font-museo-bd">What will you learn?</p>
              <p className="text-grey">
                You will learn how to discover and walk in the will of God for
                your life while becoming an effective member of the body of
                Christ
              </p>
              <Link to="/about">
                <button className="btn btn-green">More Details</button>
              </Link>
            </div>
            <div className="col-lg-1 col-sm-12"></div>
          </div>

          <div className="row mt-4">
            <div className="col-md-1 col-sm-12"></div>
            <div className="col-md-5 col-sm-12">
              <p className="font30 font-museo-bd">Our Goals</p>
              <p className="text-grey mb-1">Learn how to develop your faith</p>
              <p className="text-grey mb-1">Learn how to become a leader</p>
              <p className="text-grey mb-1">
                Learn how to understand your purpose and vision
              </p>
              <p className="text-grey mb-1">
                Learn how to correctly survey the bible
              </p>
              <p className="text-grey mb-1">
                Learn your covenant right as a believer{" "}
              </p>
              <p className="text-grey mb-1">Learn how to Evangelize</p>
              <p className="text-grey mb-1">
                Learn how to belive God for your healing
              </p>
              <p className="text-grey">Learn how to serve in God's way</p>
              <Link to="/about">
                <button className="btn btn-green">About Us</button>
              </Link>
            </div>
            <div className="col-md-6 col-sm-12 wow fadeInRight sm-d-none">
              <img src={image2} alt="" className="learn-img" />
            </div>
          </div>
        </section>
      </section>
      <div className="mt-auto">
        <Footer />
      </div>
    </div>
  );
};
