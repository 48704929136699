import React, {useState} from 'react';
import AdminNavbar from './components/Admin-Navbar';
import {AdminFooter} from './components/Admin-Footer';
import {
  updateGroupSession,
  getGroupSessionDetail,
  deleteSession,
} from '../actions/session';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useEffect} from 'react';
import Skeleton from 'react-loading-skeleton';
import session from '../reducers/session';
import {Redirect} from 'react-router-dom';

const AdminGroupSessionDetails = ({
  history,
  location,
  updateGroupSession,
  getGroupSessionDetail,
  sessions,
  loading,
  creationLoader,
}) => {
  const id = location.state.id;
  const incomingAmount = location.state.amount;
  const incomingName = location.state.name;
  const incomingYear = location.state.year;

  const [formdata, setformdata] = useState({
    name: incomingName,
    amount: incomingAmount,
    year: incomingYear,
    id: id,
  });
  const {name, amount, year} = formdata;

  const onFormChange = (e) => {
    setformdata({...formdata, [e.target.name]: e.target.value});
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    console.log(formdata);
    updateGroupSession(formdata);
  };

  // return loading && !sessions ? (
  return (
    <div className="admin">
      <AdminNavbar />
      <div className="d-body admin-create">
        <div className="container mt-5">
          <p className="font26 font-light">Session {incomingName}</p>
          <div className="row">
            <div className="col-md-7 mx-auto">
              <form className="bg-white py-4 px-3 border-radius">
                <div className="form-group mb-3">
                  <p className="font18 text-grey pt-5">Session Number</p>
                  <input
                    type="number"
                    placeholder="Number"
                    className="form-control"
                    name="name"
                    value={name}
                    onChange={(e) => onFormChange(e)}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <p className="font18 text-grey">Year</p>

                  <select
                    name="year"
                    className="form-control"
                    name="year"
                    value={year}
                    onChange={(e) => onFormChange(e)}
                  >
                    <option value="">choose a year</option>
                    <option value="one">One</option>
                    <option value="two">Two</option>
                  </select>
                </div>
                <div className="form-group mb-5">
                  <p className="font18 text-grey">Amount</p>
                  <input
                    type="number"
                    placeholder="Number"
                    className="form-control"
                    name="amount"
                    value={amount}
                    onChange={(e) => onFormChange(e)}
                    required
                  />
                </div>
                <div className="text-right my-4">
                  <button
                    className="btn btn-green"
                    onClick={onSubmitForm}
                    disabled={creationLoader}
                  >
                    Edit Session
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="mt-auto">
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

AdminGroupSessionDetails.prototypes = {
  updateGroupSession: PropTypes.func.isRequired,
  getGroupSessionDetail: PropTypes.func.isRequired,
  deleteSession: PropTypes.func.isRequired,
  sessions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  session: state.session.groupSessionDetails,
  loading: state.session.loading,
  creationLoader: state.session.creationLoader,
});

export default connect(mapStateToProps, {
  updateGroupSession,
  getGroupSessionDetail,
  deleteSession,
})(AdminGroupSessionDetails);
