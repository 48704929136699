import React, {useState, useEffect} from 'react';
import course from '../../assets/img/course.PNG';
import {Link, Redirect} from 'react-router-dom';
import StudentNavbar from '../component/Student-Navbar';
import {StudentFooter} from '../component/Student-Footer';
import {userCourses} from '../../actions/course';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

const StudentCourses = ({
  userCourses,
  loading,
  courses,
  isAuthenticated,
  history,
}) => {
  useEffect(() => {
    userCourses();
  }, [userCourses]);

  console.log(loading, courses);

  const viewCourseDetail = (id, session_name) => {
    console.log(session_name);
    history.push({pathname: '/my/courses/id', state: {id, session_name}});
    console.log(id);
  };
  return loading ? (
    <section className="container">
      <div className="mt-5">
        <div className="row">
          <div className="col-6 my-3">
            <Skeleton height={180} />
          </div>
          <div className="col-6 my-3">
            <Skeleton height={180} />
          </div>
          <div className="col-6 my-3">
            <Skeleton height={180} />
          </div>
          <div className="col-6 my-3">
            <Skeleton height={180} />
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="admin">
      <StudentNavbar />
      <div className="d-body">
        <div className="container mt-5">
          <p className="font26 font-light">Courses</p>
          <section className="my-3">
            <div className="row">
              {courses?.map((each) =>
                each.modules.map((c) => (
                  <div
                    className="col-lg-3 col-sm-12 my-3"
                    key={c._id}
                    onClick={() =>
                      viewCourseDetail(c._id, each.session_details[0].name)
                    }
                  >
                    <div className="each-course">
                      <div className="p-r">
                        <img
                          src={
                            each?.preview_image ? each?.preview_image : course
                          }
                          alt=""
                          className="w-100"
                        />
                        <span className="course-duration p-1">
                          {c.video_duration} Mins
                        </span>
                      </div>
                      <div className="px-3 pt-3 pb-2">
                        <div className="mb-4">{c.name}</div>
                        <p className="font12 mb-0 text-grey">SESSION</p>
                        <p className="font12 mb-0">
                          {each.session_details[0].name}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              )}
              {courses?.length < 1 && (
                <div className="m-auto font20 text-grey text-center">
                  Sorry, no course found. <br /> It is either you have not
                  registered for a session or Courses for the session is yet to
                  be uploaded
                </div>
              )}
            </div>
          </section>
        </div>
        <div className="mt-auto">
          <StudentFooter />
        </div>
      </div>
    </div>
  );
};
StudentCourses.prototype = {
  userCourses: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  courses: state.courses.courses,
  loading: state.courses.loading,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {userCourses})(StudentCourses);
