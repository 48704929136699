import React from 'react';
import video from '../assets/img/video.mp4';

export const VideoModal = () => {
  return (
    <div>
      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="videoModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="videoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content bg-green">
          <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Pastor Craig preaching</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
            <div class="iframe-responsive">
                <iframe width="100%" height="315" src='https://www.youtube.com/embed/kLAU_nua3Ao' frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};
