import { combineReducers } from 'redux';
import alert from './alert'
import auth from './auth'
import session from './session'
import courses from './course'
import staffs from './staff'
import students from './student'
import dashboard from './dashboard'

const rootReducers = combineReducers({
    alert,
    auth,
    session,
    courses,
    staffs,
    students,
    dashboard
})
export default rootReducers