import React, {useState, useEffect} from 'react';
import course from '../..//assets/img/course.PNG';
import {Link, Redirect} from 'react-router-dom';
import StudentNavbar from '../component/Student-Navbar';
import {StudentFooter} from '../component/Student-Footer';
import {UserSessionCourses} from '../../actions/course';
import Skeleton from 'react-loading-skeleton';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

const StudentSessionCourses = ({
  location,
  UserSessionCourses,
  isAuthenticated,
  courses,
  loading,
  history,
}) => {
  const id = location.state.session_id;

  useEffect(() => {
    UserSessionCourses(id);
  }, [UserSessionCourses]);

  console.log(loading, courses);

  const viewCourseDetail = (id, session_name) => {
    console.log(id);
    history.push({pathname: '/my/courses/id', state: {id, session_name}});
  };

  return loading ? (
    <section className="container">
      <div className="mt-5">
        <div className="row">
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="admin">
      <StudentNavbar />
      <div className="d-body">
        <div className="container mt-5">
          <p className="font26 font-light">Classes</p>
          <section className="my-3">
            <div className="row">
              {courses?.map((each) => (
                <div
                  className="col-lg-3 col-md-4 col-sm-12 my-3"
                  key={each._id}
                  onClick={() => viewCourseDetail(each.sessions_id, each.name)}
                >
                  <div className="each-course">
                    <div className="p-r">
                      <img src={course} alt="" className="w-100" />
                      <span className="course-duration p-1">
                        {each.video_duration} Mins
                      </span>
                    </div>
                    <div className="px-3 pt-3 pb-2">
                      <div className="mb-4">{each.name}</div>
                      <p className="font12 mb-0 text-grey">COURSE</p>
                      <p className="font12 mb-0 text-capitalize">
                        {each.sessions_details[0].name}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
        <div className="mt-auto">
          <StudentFooter />
        </div>
      </div>
    </div>
  );
};

StudentSessionCourses.prototype = {
  UserSessionCourses: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  courses: state.session.courses,
  loading: state.session.loading,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {UserSessionCourses})(
  StudentSessionCourses
);
