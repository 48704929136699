import React, {useState, useEffect} from 'react';
import course from '../..//assets/img/course.PNG';
import {Link, Redirect} from 'react-router-dom';
import StudentNavbar from '../component/Student-Navbar';
import {StudentFooter} from '../component/Student-Footer';
import {UserGroupSessionCourses} from '../../actions/course';
import Skeleton from 'react-loading-skeleton';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

const StudentGroupsessionCourses = ({
  location,
  UserGroupSessionCourses,
  isAuthenticated,
  groupSessionCourses,
  loading,
  history,
}) => {
  const id = location.state.id;
  console.log(id);
  const session_name = location.state.session_name;

  useEffect(() => {
    UserGroupSessionCourses(id);
  }, [UserGroupSessionCourses]);

  console.log(loading, groupSessionCourses);
  const viewCourseDetail = (id, session_name) => {
    history.push({pathname: '/my/courses/id', state: {id, session_name}});
    console.log(id);
  };

  return loading ? (
    <section className="container">
      <div className="mt-5">
        <div className="row">
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="admin">
      <StudentNavbar />
      <div className="d-body">
        <div className="container mt-5">
          <p className="font26 font-light">Courses</p>
          <section className="my-3">
            <div className="row">
              {groupSessionCourses?.length < 1 ? (
                <>
                  <div className="m-auto font20 text-grey text-center">
                    Courses for this sessions are yet to be uploaded
                  </div>
                </>
              ) : (
                <>
                  {groupSessionCourses?.map((each) => (
                    <div
                      className="col-lg-3 col-md-4 col-sm-12 my-3"
                      key={each.id}
                      onClick={() => viewCourseDetail(each.id, each.name)}
                    >
                      <div className="each-course">
                        <div className="p-r">
                          <img
                            src={
                              each.preview_image ? each.preview_image : course
                            }
                            alt=""
                            className="w-100"
                          />
                          <span className="course-duration p-1">
                            {each.duration} Classes
                          </span>
                        </div>
                        <div className="px-3 pt-3 pb-2">
                          <div className="mb-4">{each.name}</div>
                          <p className="font12 mb-0 text-grey">SESSION</p>
                          <p className="font12 mb-0 text-capitalize">
                            {session_name}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </section>
        </div>
        <div className="mt-auto">
          <StudentFooter />
        </div>
      </div>
    </div>
  );
};

StudentGroupsessionCourses.prototype = {
  UserGroupSessionCourses: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  groupSessionCourses: state.courses.groupSessionCourses,
  loading: state.courses.loading,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {UserGroupSessionCourses})(
  StudentGroupsessionCourses
);
