import React, {useState} from 'react';
import AdminNavbar from './components/Admin-Navbar';
import {AdminFooter} from './components/Admin-Footer';
import {createGroupSession} from '../actions/session';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const AdminCreateGroupSession = ({createGroupSession, loading, reloadPage}) => {
  const [formdata, setformdata] = useState({
    name: '',
    amount: '',
    year: '',
  });
  const {name, amount, year} = formdata;

  const onFormChange = (e) => {
    setformdata({...formdata, [e.target.name]: e.target.value});
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    console.log(formdata);

    async function awaitData() {
      let data = await createGroupSession(formdata);
      console.log(reloadPage);
      if (reloadPage) {
        window.location.reload();
      }
    }
    awaitData();
  };
  return (
    <div className="admin">
      <AdminNavbar />
      <div className="d-body admin-create">
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-7 mx-auto">
              <form className="bg-white py-4 px-3 border-radius">
                <div className="form-group mb-4">
                  <p className="font18 text-grey pt-5">Session Number</p>
                  <input
                    type="number"
                    placeholder="Number"
                    className="form-control"
                    name="name"
                    value={name}
                    onChange={(e) => onFormChange(e)}
                    required
                  />
                </div>
                <div className="form-group mb-4">
                  <p className="font18 text-grey">Year</p>

                  <select
                    name="year"
                    className="form-control"
                    name="year"
                    value={year}
                    onChange={(e) => onFormChange(e)}
                  >
                    <option value="">choose a year</option>
                    <option value="one">One</option>
                    <option value="two">Two</option>
                  </select>
                </div>
                <div className="form-group mb-5">
                  <p className="font18 text-grey">Amount</p>
                  <input
                    type="number"
                    placeholder="Number"
                    className="form-control"
                    name="amount"
                    value={amount}
                    onChange={(e) => onFormChange(e)}
                    required
                  />
                </div>
                <div className="text-right my-4">
                  <button
                    className="btn btn-green"
                    onClick={onSubmitForm}
                    disabled={loading}
                  >
                    Create Session
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="mt-auto">
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

AdminCreateGroupSession.prototypes = {
  createGroupSession: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.session.creationLoader,
  reloadPage: state.staffs.reloadPage,
});

export default connect(mapStateToProps, {createGroupSession})(
  AdminCreateGroupSession
);
