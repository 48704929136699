import axios from 'axios';
import {setAlert} from './alert';
import {
  STUDENT_CREATED,
  GET_STUDENT,
  STUDENT_ERROR,
  UPDATE_STUDENT,
  CLEAR_STUDENT,
  LOGOUT,
  RELOAD_PAGE,
  GET_STUDENT_DETAIL,
  UPDATE_USER,
} from './types';
import {base_url} from './auth';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const token = cookies.get('token');

// create student
export const createStudent = (formData) => async (dispatch) => {
  const config = {
    headers: {
      // 'Content-Type': 'multipart/form-data',
    },
  };

  const body = formData;
  try {
    const res = await axios.post(
      `${base_url}api/admin/create-user`,
      body,
      config
    );
    dispatch(setAlert(res.data.message, 'success'));
    console.log(res);
    dispatch({
      type: GET_STUDENT,
      payload: res.data,
    });
    dispatch({
      type: RELOAD_PAGE,
    });
  } catch (error) {
    const errors = error.response;
    console.log(error);
    console.log(errors);

    if (errors) {
      dispatch(setAlert(errors.data.message, 'error'));
    }
    if (errors.status === 401) {
      console.log(error);
      dispatch({
        type: LOGOUT,
      });
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// update student
export const updateStudent = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const body = formData;
  try {
    const res = await axios.post(
      `${base_url}api/admin/update-staff`,
      body,
      config
    );
    dispatch(setAlert(res.data.message, 'success'));
    dispatch({
      type: GET_STUDENT,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response;
    console.log(errors);

    if (errors) {
      dispatch(setAlert(errors.data.message, 'error'));
    }
    if (errors.status === 401) {
      console.log(error);
      dispatch({
        type: LOGOUT,
      });
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// Get students
export const fetchStudent = () => async (dispatch) => {
  const config = {
    headers: {},
  };
  const payload = {
    page: 1,
    status: '',
    start_date: '',
    end_date: '',
    terms: '',
    reference: '',
  };
  try {
    const res = await axios.post(`${base_url}api/admin/users`, payload, config);
    console.log(res.data.data.users);
    dispatch({
      type: GET_STUDENT,
      payload: res.data.data.users,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: STUDENT_ERROR,
      payload: {
        msg: error.statusText,
        status: error.status,
      },
    });
  }
};

// Get students paginated
export const fetchStudentPaginated = (pageNumber) => async (dispatch) => {
  const config = {
    headers: {},
  };
  const payload = {
    page: pageNumber,
    status: '',
    start_date: '',
    end_date: '',
    terms: '',
    reference: '',
  };
  try {
    const res = await axios.post(`${base_url}api/admin/users`, payload, config);
    console.log(res.data.data.users);
    dispatch({
      type: GET_STUDENT,
      payload: res.data.data.users,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: STUDENT_ERROR,
      payload: {
        msg: error.statusText,
        status: error.status,
      },
    });
  }
};

// Get student details
export const getStudentDetail = (id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.post(
      `${base_url}api/admin/user-details`,
      {user_id: id},
      config
    );
    dispatch({
      type: GET_STUDENT_DETAIL,
      payload: res.data.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: STUDENT_ERROR,
      payload: {
        msg: error,
        status: error,
      },
    });

    return {loading: false};
  }
};

// Delete student
export const updateStudentStatus = (payload) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.post(
      `${base_url}api/admin/update-status`,
      payload,
      config
    );

    dispatch(setAlert('Done', 'success'));
    dispatch({type: RELOAD_PAGE});
  } catch (err) {
    console.log(err);
    dispatch({
      type: STUDENT_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//get student profile
export const userProfile = () => async (dispatch) => {
  const config = {
    headers: {},
  };
  try {
    const res = await axios.get(`${base_url}api/user/profile`, config);
    dispatch({
      type: GET_STUDENT,
      payload: res.data.data,
    });
    dispatch({
      type: UPDATE_USER,
      payload: res.data.data.user,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    dispatch({
      type: STUDENT_ERROR,
      payload: {
        msg: error.statusText,
        status: error.status,
      },
    });
  }
};

// update my profile
export const updateMyProfile = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const body = formData;
  try {
    const res = await axios.post(
      `${base_url}api/user/edit-profile`,
      body,
      config
    );
    dispatch(setAlert('good', 'success'));
    dispatch({
      type: GET_STUDENT,
      payload: res.data,
    });
    dispatch(userProfile());
    dispatch({
      type: RELOAD_PAGE,
      payload: res.data,
    });
    console.log(res.data);
  } catch (error) {
    console.log(error);
    const errors = error.response;
    console.log(errors);

    if (errors) {
      dispatch(setAlert(errors.data.message, 'error'));
    }
    if (errors?.status === 401) {
      console.log(error);
      dispatch({
        type: LOGOUT,
      });
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: {
        msg: error.response?.statusText,
        status: error.response?.status,
      },
    });
  }
};

// start video
export const userStartVideo = (body) => async (dispatch) => {
  const config = {
    headers: {},
  };

  try {
    const res = await axios.post(
      `${base_url}api/user/start-modules`,
      body,
      config
    );
    dispatch(setAlert(res.data, 'success'));
    dispatch({
      type: GET_STUDENT,
      payload: res.data,
    });
    console.log(res);
  } catch (error) {
    const errors = error.response;
    console.log(errors);

    if (errors) {
      dispatch(setAlert(errors.data.message, 'error'));
    }
    if (errors.status === 401) {
      console.log(error);
      dispatch({
        type: LOGOUT,
      });
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// End video
export const userEndVideo = (body) => async (dispatch) => {
  const config = {
    headers: {},
  };

  try {
    const res = await axios.post(
      `${base_url}api/user/completed-user-modules`,
      body,
      config
    );
    dispatch(setAlert(res.data, 'success'));
    dispatch({
      type: GET_STUDENT,
      payload: res.data,
    });
    console.log(res.data);
    return res;
  } catch (error) {
    const errors = error.response;
    console.log(errors);

    if (errors) {
      dispatch(setAlert(errors.data.message, 'error'));
    }
    if (errors.status === 401) {
      console.log(error);
      dispatch({
        type: LOGOUT,
      });
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
