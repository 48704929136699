import axios from 'axios';
import {setAlert} from './alert';
import {
  STAFF_CREATED,
  GET_STAFF,
  STAFF_ERROR,
  CLEAR_STAFF,
  LOGOUT,
  RELOAD_PAGE,
  GET_STUDENT,
  UPDATE_USER,
} from './types';
import {base_url} from './auth';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const token = cookies.get('token');

// create staff
export const createStaff = (formData) => async (dispatch) => {
  const config = {};

  const body = formData;
  try {
    const res = await axios.post(
      `${base_url}api/admin/create-staff-account`,
      body,
      config
    );
    dispatch(setAlert(res.data.message, 'success'));
    dispatch({
      type: GET_STAFF,
      payload: res.data,
    });
    dispatch({
      type: RELOAD_PAGE,
    });
  } catch (error) {
    const errors = error.response;
    console.log(error);
    console.log(errors);

    if (errors) {
      dispatch(setAlert(errors.data.message, 'error'));
    }
    if (errors.status === 401) {
      console.log(error);
      dispatch({
        type: LOGOUT,
      });
    }
    dispatch({
      type: STAFF_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// update staff
export const updateStaff = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const body = formData;
  try {
    const res = await axios.post(
      `${base_url}api/admin/update-user`,
      body,
      config
    );
    console.log(res.data.data);
    dispatch(setAlert(res.data.message, 'success'));
    dispatch({
      type: GET_STAFF,
      payload: res.data,
    });
    dispatch({
      type: UPDATE_USER,
      payload: res.data.data.user,
    });
    dispatch({
      type: RELOAD_PAGE,
    });
  } catch (error) {
    console.log(error);
    const errors = error?.response;
    console.log(errors);
    if (errors) {
      dispatch(setAlert(errors.data.message, 'error'));
    }
    if (errors?.status === 401) {
      console.log(error);
      dispatch({
        type: LOGOUT,
      });
    }
    dispatch({
      type: STAFF_ERROR,
      payload: {
        msg: error?.response?.statusText,
        status: error?.response?.status,
      },
    });
  }
};

// Get staffs
export const fetchStaff = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    const config = {
      headers: {},
    };
    try {
      const res = await axios.post(`${base_url}api/admin/staffs`, {}, config);
      dispatch({
        type: GET_STAFF,
        payload: res.data.staff,
      });
      resolve(res.data.staff);
      console.log(res.data.staff);
    } catch (error) {
      console.log(error);
      dispatch({
        type: STAFF_ERROR,
        payload: {
          msg: error.statusText,
          status: error.status,
        },
      });
      reject(error);
    }
  });
};

// Get staff details
export const getUserDetail = (id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.post(
      `${base_url}api/admin/staff-details`,
      {user_id: id},
      config
    );
    console.log(res);
    dispatch({
      type: GET_STAFF,
      payload: res.data.staff,
    });
    return res.data.staff;
  } catch (error) {
    dispatch({
      type: STAFF_ERROR,
      payload: {
        msg: error,
        status: error,
      },
    });

    return {loading: false};
  }
};

// Delete staff
export const deleteStaff = (id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    console.log(id);
    const res = await axios.post(
      `${base_url}api/admin/delete-staff`,
      {user_id: id},
      config
    );

    dispatch({type: CLEAR_STAFF});
    dispatch({type: STAFF_ERROR});

    dispatch(setAlert('Staff deleted permanently', 'success'));

    dispatch({
      type: RELOAD_PAGE,
    });
  } catch (err) {
    dispatch({
      type: STAFF_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
