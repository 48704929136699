import React, {useState, useEffect} from 'react';
import profile from '../assets/img/profile.svg';
import AdminNavbar from './components/Admin-Navbar';
import {AdminFooter} from './components/Admin-Footer';
import {Link} from 'react-router-dom';
import AddAdmin from './components/Add-Admin';
import {fetchStaff, deleteStaff} from '../actions/staff';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import {base_url} from '../actions/auth';
import {connect} from 'react-redux';
import Moment from 'react-moment';
import Pagination from 'react-js-pagination';

const AdminStaffs = (props, reloadPage) => {
  const {
    history,
    fetchStaff,
    deleteStaff,
    staffs: {staffs, loading},
  } = props;

  const [staffLists, setStaffLists] = useState([]);
  const [activeDelete, setActiveDelete] = useState();

  useEffect(() => {
    fetchStaff()
      .then((res) => {
        const adminRole = [];
        res.forEach((el) => {
          if ((el.role = 'admin')) {
            console.log(el);
            adminRole.push(el);
          }
        });
        console.log(adminRole);
        setStaffLists([...adminRole]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [fetchStaff]);

  const _delete = (id) => {
    setActiveDelete(id);
  };
  const deleteFinally = () => {
    deleteStaff(activeDelete);
  };
  if (reloadPage == true) {
    window.location.reload();
  }
  return loading && !staffs ? (
    <section className="container">
      <div className="mt-5">
        <div className="row">
          <div className="col-12 my-3">
            <Skeleton height={50} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={50} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={50} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={50} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={50} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={50} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={50} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={50} />
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="admin">
      <AdminNavbar />
      <div className="d-body">
        <div className="container mt-5">
          <div className="mb-4 d-flex justify-content-between flex-wrap">
            <p className="font26 font-light">All Staffs</p>
            <div>
              <button
                className="btn btn-green"
                data-toggle="modal"
                data-target="#addModal"
              >
                Create Admin
              </button>
            </div>
          </div>
          <section className="my-5">
            <div className="my-3">
              <div className="card-table">
                <div className="table-responsive mb-5">
                  <table className="table text-nowrap w-100 table-borderless">
                    <tbody className="font14">
                      {staffLists.map((each) => (
                        <tr className="click" key={each.id}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div>
                                <img
                                  src={each.avater ? each.avater : profile}
                                  alt=""
                                  className="profile mr-2"
                                />
                              </div>
                              <div>
                                <p className="mb-0">
                                  {each.first_name} {each.last_name}
                                </p>
                                <p className="mb-0 font14 text-grey text-capitalize">
                                  {each.status}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0 text-grey font12">
                                Phone Number
                              </p>
                              <p className="mb-0">{each.phone_number}</p>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0 text-grey font12">
                                Email Address
                              </p>
                              <p className="mb-0">{each.email_address}</p>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0 text-grey font12">CITY</p>
                              <p className="mb-0">{each.city}</p>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0 text-grey font12">
                                DATE ADDED
                              </p>
                              <p className="mb-0">
                                <Moment format="LL">{each.createdDate}</Moment>
                              </p>
                            </div>
                          </td>
                          <td>
                            <button
                              className="btn btn-outline-red btn-sm"
                              onClick={() => _delete(each.id)}
                              data-toggle="modal"
                              data-target="#deleteModal"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="mt-auto">
          <AdminFooter />
        </div>
      </div>

      {/* <!-- delete Modal --> */}
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">
                Delete Staff
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-4">
              <div className="text-center">
                <p className="font-light">
                  Are you sure you want to delete this staff?
                </p>
              </div>
            </div>
            <div className="px-5 mt-3 py-3 d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-outline-secondary mr-3"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={deleteFinally}
              >
                delete
              </button>
            </div>
          </div>
        </div>
      </div>

      <AddAdmin />
    </div>
  );
};

AdminStaffs.prototype = {
  fetchStaff: PropTypes.func.isRequired,
  deleteStaff: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  staffs: state.staffs,
  reloadPage: state.staffs.reloadPage,
});

export default connect(mapStateToProps, {fetchStaff, deleteStaff})(AdminStaffs);
