import React, {useState, useEffect} from 'react';
import profile from '../../assets/img/profile.svg';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {updateStaff, getUserDetail} from '../../actions/staff';

const EditAdmin = ({updateStaff, getUserDetail, reloadPage, location}) => {
  const id = JSON.parse(localStorage.getItem('user')).id;
  const [avater, setAvater] = useState('');
  const [formData, setFormData] = useState({
    email_address: '',
    phone_number: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    country: 'country',
    city: '',
    user_id: '',
  });
  const {
    email_address,
    phone_number,
    first_name,
    last_name,
    middle_name,
    city,
    country,
    user_id,
  } = formData;
  useEffect(() => {
    async function awaitData() {
      let data = await getUserDetail(id);
      console.log(data);
      setFormData({
        email_address: data.email_address,
        phone_number: data.phone_number,
        first_name: data.first_name,
        last_name: data.last_name,
        middle_name: data.middle_name,
        city: data.city,
        user_id: data.id,
        country: 'country',
      });
      setAvater(data?.avater);
      if (data?.dob) {
        setdob(new Date(data?.dob));
      }
    }
    awaitData();
  }, []);
  const onFormChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onImageChange = (e) => {
    let file = e.target.files[0];
    setAvater(file);
    console.log(file);
  };

  const [dob, setdob] = useState();
  const dobChange = (e) => {
    console.log(e);
    setdob(e);
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    console.log(formData);
    const formResponse = new FormData();
    if (avater) {
      console.log(avater);
      formResponse.append('avater', avater);
    }
    if (dob) {
      formResponse.append('dob', dob);
    }
    Object.entries(formData).forEach((o) => formResponse.append(o[0], o[1]));
    updateStaff(formResponse);
  };
  if (reloadPage === true) {
    window.location.reload();
  }

  return (
    <div>
      <div
        className="modal fade"
        id="editModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="editModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addModalLabel">
                Edit Profile
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ">
              <div className="row pt-5">
                <div className="col-lg-3 col-sm-12 sm-text-center">
                  <div className="profile m-auto click">
                    <img src={avater ? avater : profile} alt="" />
                    <div className="file-picker bg-blue">
                      <label htmlFor="file" className="click text-white mb-0">
                        <i className="fa fa-plus"></i>
                      </label>
                      <input
                        type="file"
                        name="file"
                        id="file"
                        className="inputfile"
                        name="avater"
                        onChange={(e) => onImageChange(e)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-sm-12">
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <input
                        type="text"
                        className="common-input my-3 bg-white w-100"
                        placeholder="First Name"
                        name="first_name"
                        value={first_name}
                        onChange={(e) => onFormChange(e)}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <input
                        type="text"
                        className="common-input my-3 bg-white w-100"
                        placeholder="Last Name"
                        name="last_name"
                        value={last_name}
                        onChange={(e) => onFormChange(e)}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <input
                        type="text"
                        className="common-input my-3 bg-white w-100"
                        placeholder="Middle Name"
                        name="middle_name"
                        value={middle_name}
                        onChange={(e) => onFormChange(e)}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <input
                        type="tel"
                        className="common-input my-3 bg-white w-100"
                        placeholder="Phone Number"
                        name="phone_number"
                        value={phone_number}
                        onChange={(e) => onFormChange(e)}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <input
                        type="text"
                        className="common-input my-3 bg-white w-100"
                        placeholder="City"
                        name="city"
                        value={city}
                        onChange={(e) => onFormChange(e)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <DatePicker
                        className="common-input my-3 bg-white w-100"
                        selected={dob}
                        name="dob"
                        placeholderText="Date of Birth"
                        onChange={(date) => dobChange(date)}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="common-input my-3 bg-white w-100"
                        placeholder="Email"
                        name="email_address"
                        value={email_address}
                        onChange={(e) => onFormChange(e)}
                      />
                    </div>
                  </div>

                  <div className="my-5 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-secondary mr-3"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-green"
                      onClick={onSubmitForm}
                    >
                      Edit Admin
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EditAdmin.propTypes = {
  updateStaff: PropTypes.func.isRequired,
  getUserDetail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  reloadPage: state.staffs.reloadPage,
});
export default connect(mapStateToProps, {updateStaff, getUserDetail})(
  EditAdmin
);
