import {
  GET_STUDENT,
  GET_STUDENT_DETAIL,
  CLEAR_STUDENT,
  STUDENT_ERROR,
  RELOAD_PAGE,
  LOGOUT,
  UPDATE_STUDENT,
} from '../actions/types';

const initialState = {
  students: [],
  details: [],
  loading: true,
  reloadPage: false,
  error: {},
};

export default function (state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_STUDENT:
      return {
        ...state,
        students: payload,
        loading: false,
      };
      case UPDATE_STUDENT:
      localStorage.setItem('user', JSON.stringify(payload.data.user));
        return{
          ...state
        }
      case GET_STUDENT_DETAIL:
        return {
          ...state,
          details: payload,
          loading: false,
        };
    case RELOAD_PAGE:
      return {
        ...state,
        reloadPage: true,
      };
    case STUDENT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case LOGOUT:
    case CLEAR_STUDENT:
      return {
        ...state,
        students: null,
        loading: false,
      };
    default:
      return state;
  }
}
