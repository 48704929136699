import React, {useState, useEffect} from 'react';
import AdminNavbar from './components/Admin-Navbar';
import {AdminFooter} from './components/Admin-Footer';
import courseImg from '../assets/img/course-bg3.svg';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Moment from 'react-moment';
import Skeleton from 'react-loading-skeleton';
import {getCourseDetail, deleteCourse} from '../actions/course';
import {Redirect} from 'react-router-dom';
import {useRef} from 'react';

const AdminCourseDetails = ({
  getCourseDetail,
  deleteCourse,
  courses,
  loading,
  location,
  history,
}) => {
  const id = location.state.id;

  useEffect(() => {
    getCourseDetail(id);
  }, []);

  console.log(courses);

  const dismissModal = useRef(null);
  const _delete = (id) => {
    console.log(id);
    async function awaitData() {
      let data = await deleteCourse(id);
      dismissModal.current.click();
      history.push({pathname: '/admin/courses'});
    }
    awaitData();
  };

  return loading ? (
    <section className="container">
      <div className="mt-5">
        <div className="row">
          <div className="col-6 my-3">
            <Skeleton height={180} />
          </div>
          <div className="col-6 my-3">
            <Skeleton height={180} />
          </div>
          <div className="col-6 my-3">
            <Skeleton height={180} />
          </div>
          <div className="col-6 my-3">
            <Skeleton height={180} />
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="admin">
      <AdminNavbar />
      <div className="d-body">
        <section className="course-header">
          <div className="container">
            <div className="row pt-5">
              <div className="col-lg-6 col-sm-12 my-auto">
                <span className="font20 click my-5 text-white">
                  <Link to="/admin/courses">
                    <i className="fa fa-arrow-left mr-3"></i>
                  </Link>
                  {courses.module?.name}
                </span>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-secondary btn-sm my-5 mr-3"
                    data-toggle="modal"
                    data-target="#deleteModal"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div className="row pb-4">
              <div className="col-lg-8 col-sm-12 my-auto">
                <div class="iframe-responsive">
                  <iframe
                    width="100%"
                    height="315"
                    src={courses?.module?.video_link}
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 sm-mt">
                <div className="lesson-plylist">
                  <div className="p-3 justify-content-between align-items-center d-flex playlist-header">
                    <span>{courses.sessions?.name}</span>
                    <span className="font12">
                      {courses.module?.video_duration} Minutes
                    </span>
                  </div>
                  <p className="font14 text-white mt-4">
                    <i className="fa fa-play mr-2"></i>
                    Lesson : {courses.module?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="bg-white">
          <div className="container">
            <div className="row py-3">
              <div className="col-lg-8 col-sm-12">
                <ul className="nav">
                  <li className="text-blue">Description</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-7 col-sm-12">
              <div>
                <p className="font14 font-light">
                  {courses.module?.description}
                </p>
              </div>
            </div>
            <div className="col-lg-1 col-sm-12"></div>
            <div className="col-lg-4 col-sm-12">
              <div className="row">
                <div className="col-4 mt-4">
                  <p className="text-grey font12 mb-1">
                    <b>LAST UPDATE</b>
                  </p>
                  <p className="font14">
                    <b>
                      <Moment format="LL">
                        {courses.sessions?.createdDate}
                      </Moment>
                    </b>
                  </p>
                </div>
                <div className="col-4 mt-4">
                  <p className="text-grey font12 mb-1">
                    <b>CERTIFICATION</b>
                  </p>
                  <p className="font14">
                    <b>One</b>
                  </p>
                </div>
                <div className="col-4 mt-4">
                  <p className="text-grey font12 mb-1">
                    <b>SESSION</b>
                  </p>
                  <p className="font14">
                    <b>{courses.sessions?.name}</b>
                  </p>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
        <div className="mt-auto">
          <AdminFooter />
        </div>
      </div>
      {/* <!-- delete Modal --> */}
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">
                Delete Course
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-4">
              <div className="text-center">
                <p className="font-light">
                  Are you sure you want to delete this Course?
                </p>
              </div>
            </div>
            <div className="px-5 mt-3 py-3 d-flex justify-content-center">
              <button
                type="button"
                ref={dismissModal}
                className="btn btn-outline-secondary mr-3"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => _delete(id)}
              >
                delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AdminCourseDetails.propTypes = {
  getCourseDetail: PropTypes.func.isRequired,
  deleteCourse: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  courses: state.courses.details,
  loading: state.courses.loading,
});
export default connect(mapStateToProps, {getCourseDetail, deleteCourse})(
  AdminCourseDetails
);
