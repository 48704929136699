import {GET_DASHBOARD} from '../actions/types';

const initialState = {
  dashboard: [],
  loading: true,
};

export default function (state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_DASHBOARD:
      return {
        ...state,
        dashboard: payload,
        loading: false,
      };
    default:
      return state;
  }
}
