import { Redirect } from "react-router-dom";
import React from "react";

import axios from "axios";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOADING,
  NON_MEMBER,
  REMOVE_NON_MEMBER,
} from "./types";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";
// import Cookies from 'universal-cookie'
// const cookies = new Cookies()

const token = localStorage.getItem("token");
export const base_url = "https://iam-academy.herokuapp.com/";

// load user
export const loadUserService = (props) => async (dispatch) => {
  if (localStorage.token && localStorage.user) {
    setAuthToken(localStorage.token);
    dispatch({
      type: USER_LOADED,
    });
  } else {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//register user
export const registerService = (newUser) => async (dispatch) => {
  dispatch({ type: LOADING });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(newUser);
  try {
    const res = await axios.post(
      `${base_url}api/auth/create-user-account`,
      body,
      config
    );
    dispatch({
      type: NON_MEMBER,
      payload: res.data,
    });
    console.log(res.data);
    dispatch(setAlert("Welcome", "success"));
    dispatch(loadUserService());
    return res.data;
  } catch (err) {
    console.log(err);
    console.log(err?.response);
    if (err) {
      dispatch(setAlert(err.response?.data, "error"));
      if (err?.response?.status === "400") {
        setAlert("Error detected in your form", "error");
      }
    }
    const errors = err.data;
    if (errors) {
      console.log(errors);
      console.log(errors.response);
      errors.forEach((error) => {
        setAlert(error, "error");
      });
    }
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

//login user
export const loginService = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password });
  dispatch({ type: LOADING });
  try {
    console.log(body);
    const res = await axios.post(
      `${base_url}api/auth/login-account`,
      body,
      config
    );
    dispatch(setAlert("Welcome", "success"));
    console.log(res.data);
    if (
      res.data.data.user.role === "admin" &&
      res.data.data.user.membership_status === "no"
    ) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch(loadUserService());
    } else if (res.data.data.user.tuition_status === "yes") {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch(loadUserService());
    } else {
      dispatch({
        type: NON_MEMBER,
        payload: res.data,
      });
    }
    return;
  } catch (err) {
    const errors = err.response?.data;
    if (errors) {
      console.log(errors);
      dispatch(setAlert(errors, "error"));
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

//forgot password
export const forgotPasswordService = (body) => async (dispatch) => {
  dispatch({ type: LOADING });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    console.log(body);
    const res = await axios.post(
      `${base_url}api/auth/forget-password`,
      body,
      config
    );
    console.log(res);
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const errors = err?.response?.data;
    if (errors) {
      console.log(errors);
      dispatch(setAlert(errors, "error"));
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

//reset password
export const resetPasswordService = (body) => async (dispatch) => {
  dispatch({ type: LOADING });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    console.log(body);
    const res = await axios.post(
      `${base_url}api/auth/reset-password`,
      body,
      config
    );
    console.log(res);
    dispatch(setAlert("Password changed", "success"));

    return res.data;
  } catch (err) {
    console.log(err);
    const errors = err.response?.data;
    if (errors) {
      console.log(errors);
      setAlert(errors?.message, "error");
      dispatch(setAlert(errors, "error"));
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

//logout
export const logoutService = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};

//course payment
export const coursePaymentService = (body) => async (dispatch) => {
  dispatch({ type: LOADING });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    console.log(body);
    const res = await axios.post(
      `${base_url}api/user/initiate-group-payment`,
      body,
      config
    );
    console.log(res);
    dispatch(setAlert("Enter your card details", "success"));
    return res.data.data?.payment_intent_secret_key?.client_secret;
  } catch (err) {
    console.log(err);
    const errors = err.response.data;
    if (errors) {
      console.log(errors);
      dispatch(setAlert(errors, "error"));
    }
  }
};

//tution payment
export const tuitionPaymentService = (body) => async (dispatch) => {
  dispatch({ type: LOADING });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const payload = { client_secret: body };
  try {
    const res = await axios.post(
      `${base_url}api/user/membership-payment-complete`,
      payload,
      config
    );
    console.log(res);
    dispatch(setAlert("Tuition paid, proceed to login", "success"));
    dispatch({
      type: REMOVE_NON_MEMBER,
      payload: null,
    });
    return res;
  } catch (err) {
    console.log(err);
    const errors = err.response?.data;
    if (errors) {
      console.log(errors);
      dispatch(setAlert(errors, "error"));
    }
  }
};
