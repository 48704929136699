import React, {useEffect} from 'react';
import {Navbar} from '../components/navbar';
import aboutBg from '../assets/img/aboutbg.jpg';
import executives from '../assets/img/executive-pastors.jpg';
import seniors from '../assets/img/senior-pastors.png';
import logo from '../assets/img/logo.png';
import {Footer} from '../components/footer';
import mark from '../assets/img/mark.png';
import {Link} from 'react-router-dom';

export const About = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-sm-12">
              <div>
                <div className="text-right font14 text-light-green pt-4 mb-4">
                  <span className=" mr-3">
                    <i className="fa fa-phone mr-3"></i>
                    <span className="click">
                      <a href="tel:+16025290439" className="text-light-green">
                        +1 (602) 529-0439
                      </a>
                    </span>
                  </span>
                  |
                  <span>
                    <i className="fa fa-envelope-o mx-3"></i>
                    <span>
                      <a
                        href="mailto:care@iamchurchonline.org"
                        className="text-light-green"
                      >
                        care@iamchurchonline.org
                      </a>
                    </span>
                  </span>
                  <a
                    href="https://www.facebook.com/iamchurchonline/"
                    target="_blanc"
                    className="social-icon text-light-green"
                  >
                    <span
                      class="iconify"
                      data-icon="jam:facebook-circle"
                      data-inline="false"
                    ></span>
                  </a>
                  <a
                    href="https://www.instagram.com/iamchurchonline/"
                    target="_blanc"
                    className="social-icon text-light-green"
                  >
                    <span
                      class="iconify"
                      data-icon="la:instagram"
                      data-inline="false"
                    ></span>
                  </a>
                </div>
                <Navbar />
              </div>
            </div>
            <div className="col-lg-2 col-sm-12"></div>
          </div>
        </div>
        <div className="pl-8 hidden pt10vh">
          <div className="row my-5 ">
            <div className="col-lg-6 col-sm-12">
              <div>
                <h3 className="font60 text-white">About DTI</h3>
                <p className="font20 my-4 text-light-green">
                  A SCHOOL WITH INTEGRITY, SERVICE, EXCELLENCE, COST AND
                  EFFECTIVENESS <br />
                  Empowering Belivers to fulfill the great commission Jesus
                  Christ within the context of emphasizing a christ-centered
                  message.
                </p>
                <Link to="/register">
                  <button className="btn btn-green">Apply Now</button>
                </Link>
                <span className="text-white ml-4">
                  Questions? Talk to our <a href="/faq/#contact">team</a>
                </span>
              </div>
            </div>
            <div className="col-lg-1 col-sm-12"></div>
            <div className="col-lg-5 col-sm-12 my-auto sm-mt">
              <img src={aboutBg} alt="" className="w-100" />
            </div>
          </div>
        </div>
      </header>
      <section className="about-wrapper" id="welcome">
        <div className="container py-5">
          <div className="text-center wow zoomIn" data-wow-duration="1s">
            <img src={logo} alt="" className="welcome-logo" />
            <div className="my-5 font-bold font48">Welcome</div>
          </div>
          <div className="pb-5 font20 text-center">
            <p>
              On behalf of our IAM Academy team, we would like to welcome you to
              IAM ACADEMY and wish you every success. Whether you are taking our
              course on campus, online, or through our affiliates program
              throughout the country, we are glad you have joined us. You are an
              important student of the IAM ACADEMY team and we want to help you
              learn about being a productive student so that you will feel
              comfortable as quickly as possible. Each student contributes
              directly to IAM ACADEMY’s growth and I hope you take pride in
              being a student of our Team.
            </p>
            <p>
              This Academy provides information about DTI & MTI and answers most
              of the questions new students have concerning their enrollment. It
              outlines the major policies and benefit programs that help make
              this a great place to attend school. We encourage you to go
              through the website in its entirety carefully and discuss any
              questions with your IAM Academy team.
            </p>
            <p>
              It is important to understand that no written statement can
              anticipate all situations. This Website is designed for general
              information purposes only; it is not intended to create any
              express or implied contractual rights
            </p>
            <p>
              Again, we are pleased to welcome you to IAM Academy. I know your
              experience here will be challenging, enjoyable and rewarding.
            </p>
          </div>
          <div className="text-right">
            <span className="font-bold font20">Sincerely,</span> <br />
            Pastor Alfred Craig Jr. <br />
            Pastor Noel Craig
          </div>
        </div>
        <div className="p-r purpose-wrapper py-4" id="purpose">
          <div className="container">
            <div className="text-center mb-3 font36">
              <b>DTI Purpose </b>
            </div>
            <p className="font20">
              <span className="big-letter wow fadeInLeft">T</span>
              he IAM ACADEMY exists to offer a strictly Bible-based curriculum,
              that perpetuates the knowledge and understanding of the walk of
              Faith by application of God’s word and His spirit. We have
              teachers that are called, anointed and knowledgeable Christian
              instructors. <br /> We have a structured environment conducive to
              learning and applying God’s principles for students to go forth in
              their God-called and God-anointed Kingdom work.
            </p>
          </div>
        </div>
        <div className="bg-grey">
          <div className="container">
            <div className="row py-space">
              <div className="col-lg-10 col-sm-12 mx-auto ">
                <div className="text-center">
                  <p className="font36 text-blue">
                    <b>Vision</b>
                  </p>
                </div>
                <p className="font20">
                  IAM ACADEMY is designed to build a well-trained and skillful
                  Student to operate in the fullness of God’s Word. The purpose
                  is to equip each student to teach others to do the same with
                  faith, integrity and excellence to build up the body of
                  Christ.
                </p>
                <ul>
                  <li className="mb-2">
                    Point #1: ESTABLISH DISCIPLES TRAINING designed take the
                    Gospel to every person in Arizona, the United States, and
                    the world, so that they will have the opportunity to hear,
                    accept and believe the gospel of Jesus Christ.
                  </li>
                  <li>
                    Point #2: ESTABLISH MINISTRY TRAINING designed to equip men
                    and women as Apostles, Prophets, Evangelists, Pastors,
                    Teachers and the Ministry of Helps as declared in
                    scriptures—(Ephesians 4:11 and I Corinthians 12:27-28.)
                  </li>
                </ul>
                <div className="text-center">
                  <p className="font36 text-blue">
                    <b>Mission</b>
                  </p>
                </div>
                <p className="font20 text-center">
                  To serve each student to graduate from our world-class
                  institution of biblical higher education, to fulfill Gods
                  calling on their Life and be fully prepared and equipped for
                  his or her next step in life. Whatever the choice, we want our
                  students to thrive as individuals as well as members of their
                  families and communities. Providing skillful Word of
                  Faith-based instruction with Integrity and Excellence.
                </p>
                <div className="row">
                  <div className="col-lg-9 col-sm-12 mx-auto">
                    <div className="row">
                      <div
                        className="col-lg-6 col-sm-12 my-4 wow zoomIn"
                        data-wow-duration="1s"
                      >
                        <div class="bg-blue my-3 goals text-white">
                          <h3 class="font28 font-bold">Goal is to be</h3>
                          <div class="line-under"></div>
                          <p class="font-light text-white mb-0 mt-3">
                            <img src={mark} alt="" class="pr-1" />
                            SPIRITUAL
                          </p>
                          <div class="line-under"></div>
                          <p class="font-light text-white mb-0 mt-3">
                            <img src={mark} alt="" class="pr-1" />
                            PRACTICAL
                          </p>
                          <div class="line-under"></div>
                          <p class="font-light text-white mb-0 mt-3">
                            <img src={mark} alt="" class="pr-1" />
                            AFFORDABLE
                          </p>
                          <div class="line-under"></div>
                          <p class="font-light text-white mb-0 mt-3">
                            <img src={mark} alt="" class="pr-1" />
                            SIMPLE
                          </p>
                          <div class="line-under"></div>
                          <p class="font-light text-white mb-0 mt-3">
                            <img src={mark} alt="" class="pr-1" />
                            ACCESSIBLE
                          </p>
                          <div class="line-under"></div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6 col-sm-12 my-4 wow zoomIn"
                        data-wow-duration="2s"
                      >
                        <div class="bg-blue my-3 goals text-white">
                          <h3 class="font28 font-bold">5 Tiers</h3>
                          <div class="line-under"></div>
                          <p class="font-light text-white mb-0 mt-3">
                            <img src={mark} alt="" class="pr-1" />
                            Discipleship- Tier ONE
                          </p>
                          <div class="line-under"></div>
                          <p class="font-light text-white mb-0 mt-3">
                            <img src={mark} alt="" class="pr-1" />
                            Leadership - Tier TWO
                          </p>
                          <div class="line-under"></div>
                          <p class="font-light text-white mb-0 mt-3">
                            <img src={mark} alt="" class="pr-1" />
                            Financial - Tier THREE
                          </p>
                          <div class="line-under"></div>
                          <p class="font-light text-white mb-0 mt-3">
                            <img src={mark} alt="" class="pr-1" />
                            Business - Tier FOUR
                          </p>
                          <div class="line-under"></div>
                          <p class="font-light text-white mb-0 mt-3">
                            <img src={mark} alt="" class="pr-1" />
                            Ministry Training - Tier FIVE
                          </p>
                          <div class="line-under"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-5 text-center">
          <div className="container">
            <p className="font36 text-blue" id="founders">
              <b>DTI Leadership.</b>
            </p>
            <div className="row">
              <div className="col-lg-8 col-sm-12 mx-auto">
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <div className="leaders border bg-white box-shadow">
                      <img
                        src={executives}
                        alt=""
                        className="w-100 executive-image"
                      />
                      <div className="my-4">
                        <p className="font20 mb-0">
                          <b>Executive Pastors</b>
                        </p>
                        <p>Pastor Alfred Craig Jr & Noel Craig</p>
                        <a
                          target="_blanc"
                          href="https://iamchurchonline.org/MINISTERS/alfred-noel-craig"
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="leaders border bg-white box-shadow">
                      <img src={seniors} alt="" className="w-100" />
                      <div className="my-4">
                        <p className="font20 mb-0">
                          <b>Senior Pastors</b>
                        </p>
                        <p>Apostle Alfred Craig Sr & Amb. Beverly Craig</p>
                        <a
                          target="_blanc"
                          href="https://iamchurchonline.org/MINISTERS/apostle-alfred-sr-dr-beverly-craig"
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <Footer />
      </div>
    </div>
  );
};
