import React, {useState} from 'react';
import logo from '../../assets/img/logo.png';
import sec1 from '../../assets/img/sec-1.png';
import key2 from '../../assets/img/key-2.png';
import arrow from '../../assets/img/arrow-white.png';
import message from '../../assets/img/message.png';
import lock from '../../assets/img/lock.png';
import user from '../../assets/img/user.png';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/login.scss';
import {Link, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {setAlert} from '../../actions/alert';
import {registerService} from '../../actions/auth';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const Register = ({
  setAlert,
  registerService,
  isAuthenticated,
  loading,
  history,
}) => {
  const [formData, setFormData] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    phone_number: '',
    email_address: '',
    country: '',
    city: '',
    password: '',
    password2: '',
  });
  const {
    first_name,
    middle_name,
    last_name,
    phone_number,
    email_address,
    country,
    city,
    password,
    password2,
  } = formData;

  const [dob, setdob] = useState();
  const dobChange = (e) => {
    console.log(e);
    setdob(e);
  };

  const [membership_status, setmember] = useState();
  const memberOptions = ['yes', 'no'];
  const onMemberChange = (e) => {
    setmember(e.value);
  };

  const onFormChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const signUp = (e) => {
    e.preventDefault();
    if (password != password2) {
      setAlert('Passwords do not match!', 'error');
    } else {
      const newUser = {
        first_name,
        middle_name,
        last_name,
        phone_number,
        email_address,
        country,
        city,
        dob,
        password,
        membership_status,
      };
      console.log(newUser);
      async function awaitData() {
        let data = await registerService(newUser);
        console.log(data);
        const amount = data?.data.payment_data.amount;
        const token = data?.data.accessToken;
        const client_secret = data?.data.payment_data.client_secret;
        history.push({
          pathname: `/tuition`,
          state: {token, amount, client_secret},
        });
      }
      awaitData();
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/my/grouped-sessions" />;
  }

  return (
    <div>
      <ToastContainer />
      <section className="signIn-wrapper">
        <div className="curve1">
          <img src={sec1} alt="" />
        </div>

        <div className="curve2">
          <img src={key2} alt="" />
        </div>
        <div className="container">
          <div className="sign-container">
            <div>
              <div className="my-5 logo-wrapper">
                <Link to="/">
                  <img src={logo} alt="" className="login-logo" />
                </Link>
              </div>
              <div className="row my-5">
                <div className="col-lg-4 col-sm-12 sm-d-none my-auto">
                  <div className="text-white">
                    <h2 className="font88 font-bold">
                      <span>Intergrety. Service. Excellence</span>
                    </h2>
                    <p className="font18">
                      <span>
                        Do not miss the opportunity to discover and fulfill
                        God's plan and purpose for your life. Discipleship is
                        God's gift to you
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-12 sm-d-none my-auto">
                  <img src={arrow} alt="" className="w-100" />
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div>
                    <form className="signupForm bg-white">
                      <div className="d-flex align-items-center mb-4">
                        <Link to="/login">
                          <h2 className="login-nav login mr-3">Login</h2>
                        </Link>
                        <Link to="/register">
                          <h2 className="login-nav signUp active">Sign Up</h2>
                        </Link>
                      </div>
                      <div>
                        <div className="row">
                          <div className="col-lg-6 col-sm-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                name="first_name"
                                value={first_name}
                                onChange={(e) => onFormChange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Middle Name"
                                name="middle_name"
                                value={middle_name}
                                onChange={(e) => onFormChange(e)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 col-sm-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                                name="last_name"
                                value={last_name}
                                onChange={(e) => onFormChange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-12">
                            <div className="form-group">
                              <input
                                type="number"
                                placeholder="Phone number"
                                className="form-control"
                                name="phone_number"
                                value={phone_number}
                                onChange={(e) => onFormChange(e)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Email address"
                                name="email_address"
                                value={email_address}
                                onChange={(e) => onFormChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-sm-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="City"
                                name="city"
                                value={city}
                                onChange={(e) => onFormChange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Country"
                                name="country"
                                value={country}
                                onChange={(e) => onFormChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-sm-12">
                            <div className="form-group">
                              <DatePicker
                                className="form-control"
                                selected={dob}
                                name="dob"
                                placeholderText="Date of Birth"
                                onChange={(date) => dobChange(date)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-12">
                            <div className="form-group">
                              <Dropdown
                                className=""
                                options={memberOptions}
                                name="member"
                                onChange={(e) => onMemberChange(e)}
                                value={membership_status}
                                placeholder="Are you a Member?"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-12">
                            <div className="form-group">
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Password"
                                name="password"
                                value={password}
                                onChange={(e) => onFormChange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-12">
                            <div className="form-group">
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Confirm password"
                                name="password2"
                                value={password2}
                                onChange={(e) => onFormChange(e)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="my-4">
                          <button
                            className="btn btn-green"
                            onClick={signUp}
                            disabled={loading}
                          >
                            Sign Up
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <footer className="mt-auto">
              <div className="d-flex justify-content-between">
                <p>(c) 2020. Discipleship Training Institute</p>
                <p>
                  <Link to="/" className="mr-2">
                    Home
                  </Link>
                  <span className="mr-2">|</span>
                  <span> God has great plans for you ! (Jer. 29:11)</span>
                </p>
              </div>
            </footer>
          </div>
        </div>
      </section>
    </div>
  );
};
Register.prototype = {
  setAlert: PropTypes.func.isRequired,
  registerService: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {setAlert, registerService})(Register);
