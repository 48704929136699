import React, {useState, useEffect} from 'react';
import course from '../assets/img/course.PNG';
import AdminNavbar from './components/Admin-Navbar';
import {AdminFooter} from './components/Admin-Footer';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {getSessionCourses} from '../actions/session';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

const AdminSessionCourses = ({
  location,
  getSessionCourses,
  courses,
  loading,
  history,
}) => {
  const id = location.state.id;
  console.log(id);
  useEffect(() => {
    getSessionCourses(id);
  }, []);
  console.log(loading, courses);

  const viewCourseDetail = (id) => {
    history.push({pathname: `/admin/courses/${id}`, state: {id}});
    console.log(id);
  };

  return loading && !courses ? (
    <section className="container">
      <div className="mt-5">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="admin">
      <AdminNavbar />
      <div className="d-body">
        <div className="container mt-5">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <p className="font26 font-light">Classes</p>
            <p className="font26 font-light">
              <Link to="/admin/courses/create">
                <button className="btn btn-green">Create Class</button>
              </Link>
            </p>
          </div>
          <section className="my-3">
            <div className="row">
              {courses < 1 ? (
                <>
                  <div className="m-auto font20 text-grey text-center">
                    Classes for this Course are yet to be created
                  </div>
                </>
              ) : (
                <>
                  {courses?.map((e) => (
                    <div
                      className="col-lg-3 col-md-4 col-sm-12 my-3"
                      key={e.id}
                    >
                      <div
                        className="each-course"
                        onClick={() => viewCourseDetail(e.id)}
                      >
                        <div className="p-r">
                          <img
                            src={e.preview_image ? e.preview_image : course}
                            alt=""
                            className="w-100"
                          />
                          <span className="course-duration p-1">
                            {e.video_duration} Mins
                          </span>
                        </div>
                        <div className="px-3 pt-3 pb-2">
                          <div className="mb-4">{e.name}</div>
                          <p className="font12 mb-0 text-grey">COURSE</p>
                          <p className="font12 mb-0">{e.name}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </section>
        </div>
        <div className="mt-auto">
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

AdminSessionCourses.prototype = {
  getSessionCourses: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  courses: state.session.courses,
  loading: state.session.loading,
});

export default connect(mapStateToProps, {getSessionCourses})(
  AdminSessionCourses
);
