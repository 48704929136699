import React, {useState, useEffect} from 'react';
import profile from '../assets/img/profile.svg';
import AdminNavbar from './components/Admin-Navbar';
import {AdminFooter} from './components/Admin-Footer';
import {Link} from 'react-router-dom';
import {
  fetchStudent,
  updateStudentStatus,
  fetchStudentPaginated,
} from '../actions/student';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import AddStudent from './components/Add-Student';
import Moment from 'react-moment';
import Pagination from 'react-js-pagination';

const AdminStudent = ({
  history,
  fetchStudent,
  fetchStudentPaginated,
  updateStudentStatus,
  students: {students, loading},
  reloadPage,
}) => {
  useEffect(() => {
    fetchStudent();
  }, [fetchStudent]);

  console.log(students);

  const [studentLists, setStudentLists] = useState([]);
  const [activeDelete, setActiveDelete] = useState();
  const [activePage, setactivePage] = useState(1);

  const handlePageChange = (pageNumber) => {
    console.log(pageNumber);
    fetchStudentPaginated(pageNumber);
    console.log(pageNumber);
    setactivePage(pageNumber);
  };

  const viewStudentDetail = (id) => {
    history.push({pathname: `/admin/students/${id}`, state: {id}});
    console.log(id);
  };

  const _delete = (id) => {
    setActiveDelete(id);
    console.log(id);
  };
  const deleteFinally = () => {
    console.log(activeDelete);
    const payload = {
      status: 'in-active',
      user_id: activeDelete,
    };
    updateStudentStatus(payload);
  };

  const activateFinally = () => {
    console.log(activeDelete);
    const payload = {
      status: 'active',
      user_id: activeDelete,
    };
    updateStudentStatus(payload);
  };
  if (reloadPage === true) {
    window.location.reload();
  }

  return loading && !students ? (
    <section className="container">
      <div className="mt-5">
        <div className="row">
          <div className="col-12 my-3">
            <Skeleton height={90} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={90} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={90} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={90} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={90} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={90} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={90} />
          </div>
          <div className="col-12 my-3">
            <Skeleton height={90} />
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="admin">
      <AdminNavbar />
      <div className="d-body">
        <div className="container mt-5">
          <div className="mb-4 d-flex justify-content-between flex-wrap">
            <p className="font26 font-light">All Students</p>
            <div>
              <button
                className="btn btn-green"
                data-toggle="modal"
                data-target="#addModal"
              >
                Create Student
              </button>
            </div>
          </div>
          <section className="my-5">
            <div className="my-3">
              <div className="card-table">
                <div className="table-responsive mb-5">
                  <table className="table text-nowrap w-100 table-borderless">
                    <tbody className="font14">
                      {students?.map((each) => (
                        <tr className="click" key={each._id}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div>
                                <img
                                  src={each.avater ? each.avater : profile}
                                  alt=""
                                  className="profile mr-2"
                                />
                              </div>
                              <div>
                                <p className="mb-0">
                                  {each.first_name} {each.last_name}
                                </p>
                                <p className="mb-0 font14 text-grey">
                                  {each.membership_status === 'yes'
                                    ? 'Member'
                                    : 'Non-Member'}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0 text-grey font12">STATUS</p>
                              <p className="mb-0">{each.status}</p>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0 text-grey font12">
                                PHONE NUMBER
                              </p>
                              <p className="mb-0">{each.phone_number}</p>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0 text-grey font12">CITY</p>
                              <p className="mb-0">{each.city}</p>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0 text-grey font12">
                                DATE ADDED
                              </p>
                              <p className="mb-0">
                                <Moment format="LL">{each.createdDate}</Moment>
                              </p>
                            </div>
                          </td>
                          <td>
                            <button
                              className="btn btn-outline-green btn-sm"
                              onClick={() => viewStudentDetail(each._id)}
                            >
                              View
                            </button>
                          </td>
                          <td>
                            {each.status === 'active' ? (
                              <button
                                className="btn btn-outline-red btn-sm"
                                data-toggle="modal"
                                data-target="#deleteModal"
                                onClick={() => _delete(each._id)}
                              >
                                Deactivate
                              </button>
                            ) : (
                              <button
                                className="btn btn-outline-green btn-sm"
                                data-toggle="modal"
                                data-target="#activateModal"
                                onClick={() => _delete(each._id)}
                              >
                                Activate
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center flex-wrap my-5 list-nav">
              <Pagination
                firstPageText={<i className=" fa fa-angle-double-left" />}
                lastPageText={<i className="fa fa-angle-double-right" />}
                prevPageText={<i className=" fa fa-chevron-left" />}
                nextPageText={<i className=" fa fa-chevron-right" />}
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={students.length}
                pageRangeDisplayed={5}
                onChange={(e) => handlePageChange(e)}
              />
            </div>
          </section>
        </div>
        <div className="mt-auto">
          <AdminFooter />
        </div>
      </div>

      {/* <!-- delete Modal --> */}
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">
                Deactivate Student
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-4">
              <div className="text-center">
                <p className="font-light">Are you sure about this?</p>
              </div>
            </div>
            <div className="px-5 mt-3 py-3 d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-outline-secondary mr-3"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={deleteFinally}
              >
                Deactivate
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- activate Modal --> */}
      <div
        className="modal fade"
        id="activateModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="activateModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="activateModalLabel">
                Activate Student
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-4">
              <div className="text-center">
                <p className="font-light">Are you sure about this?</p>
              </div>
            </div>
            <div className="px-5 mt-3 py-3 d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-outline-secondary mr-3"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-green"
                onClick={activateFinally}
              >
                Activate
              </button>
            </div>
          </div>
        </div>
      </div>

      <AddStudent />
    </div>
  );
};

AdminStudent.prototype = {
  fetchStudent: PropTypes.func.isRequired,
  updateStudentStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  students: state.students,
  reloadPage: state.students.reloadPage,
});

export default connect(mapStateToProps, {
  fetchStudent,
  fetchStudentPaginated,
  updateStudentStatus,
})(AdminStudent);
