import React, {useState, useEffect} from 'react';
import logo from '../../assets/img/logo.png';
import sec1 from '../../assets/img/sec-1.png';
import key2 from '../../assets/img/key-2.png';
import arrow from '../../assets/img/arrow-white.png';
import message from '../../assets/img/message.png';
import lock from '../../assets/img/lock.png';
import user from '../../assets/img/user.png';
import '../../styles/login.scss';
import {Link, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {loginService} from '../../actions/auth';
import PropTypes from 'prop-types';

const Login = ({
  loginService,
  isAuthenticated,
  loading,
  location,
  non_member,
  history,
}) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const {email, password} = formData;

  const onFormChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    console.log(formData);
    loginService(email, password);
  };

  // Redirect if tuition_status is not paid
  if (!isAuthenticated && non_member?.user?.tuition_status === 'no') {
    console.log(non_member?.user?.tuition_status);
    const amount = non_member.payment_data.amount;
    const token = non_member.accessToken;
    const client_secret = non_member.payment_data.client_secret;
    history.push({pathname: `/tuition`, state: {token, amount, client_secret}});
  }

  // Redirect if logged in
  if (isAuthenticated) {
    const userDetails = JSON.parse(localStorage.getItem('user'));
    if (userDetails.role === 'admin') {
      console.log('admin');
      return <Redirect to="/admin" />;
    } else {
      console.log('student');
      return <Redirect to="/my/grouped-sessions" />;
    }
  }
  return (
    <div>
      <section className="signIn-wrapper">
        <div className="curve1">
          <img src={sec1} alt="" />
        </div>

        <div className="curve2">
          <img src={key2} alt="" />
        </div>
        <div className="container">
          <div className="sign-container">
            <div>
              <div className="my-5 logo-wrapper">
                <Link to="/">
                  <img src={logo} alt="" className="login-logo" />
                </Link>
              </div>
              <div className="row my-5">
                <div className="col-lg-5 col-sm-12 sm-d-none my-auto">
                  <div className="text-white">
                    <h2 className="font88 font-bold">
                      <span>Welcome Back</span>
                    </h2>
                    <p className="font18">
                      <span>
                        Begin your journey for success in life. Discipleship is
                        God's gift to you
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-12 sm-d-none my-auto">
                  <img src={arrow} alt="" className="w-100" />
                </div>
                <div className="col-lg-5 col-sm-12">
                  <div>
                    <form className="p-5 bg-white">
                      <div className="d-flex align-items-center mb-4">
                        <Link to="/login">
                          <h2 className="login-nav login active mr-3">Login</h2>
                        </Link>
                        <Link to="/register">
                          <h2 className="login-nav signUp ">Sign Up</h2>
                        </Link>
                      </div>
                      <div>
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email address"
                            name="email"
                            value={email}
                            onChange={(e) => onFormChange(e)}
                            required
                          />
                          <span className="input-icon">
                            <img src={message} alt="" />
                          </span>
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={(e) => onFormChange(e)}
                          />
                          <span className="input-icon">
                            <img src={lock} alt="" />
                          </span>
                        </div>
                        <div className="my-4">
                          <button
                            className="btn btn-green"
                            onClick={onSubmitForm}
                            disabled={loading}
                          >
                            Login
                          </button>
                        </div>
                        <div className="text-right font14">
                          <Link to="/forgotPassword" className="text-grey">
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <footer className="mt-auto">
              <div className="d-flex justify-content-between">
                <p>(c) 2020. Discipleship Training Institute</p>
                <p>
                  <Link to="/" className="mr-2">
                    Home
                  </Link>
                  <span className="mr-2">|</span>
                  <span> God has great plans for you ! (Jer. 29:11)</span>
                </p>
              </div>
            </footer>
          </div>
        </div>
      </section>
    </div>
  );
};

Login.prototypes = {
  loginService: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  non_member: state.auth.non_member?.data,
});

export default connect(mapStateToProps, {loginService})(Login);
