import {
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOADING,
  NON_MEMBER,
  UPDATE_USER,
  REMOVE_NON_MEMBER,
} from '../actions/types';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const initialState = {
  token: localStorage.getItem('token'),
  user: JSON.parse(localStorage.getItem('user')),
  non_member: {},
  isAuthenticated: null,
  loading: true,
};

export default function (state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.data.accessToken);
      cookies.set('token', payload.data.accessToken, {path: '/'});
      localStorage.setItem('user', JSON.stringify(payload.data.user));
      return {
        ...state,
        ...payload,
        user: JSON.parse(localStorage.getItem('user')),
        isAuthenticated: true,
        loading: false,
      };
    case UPDATE_USER:
      localStorage.setItem('user', JSON.stringify(payload));
      return {
        ...state,
        user: JSON.parse(localStorage.getItem('user')),
      };
    case NON_MEMBER:
      localStorage.setItem('non_member_token', payload.data.accessToken);
      localStorage.setItem('non_member', JSON.stringify(payload.data.user));
      return {
        ...state,
        non_member: payload,
        isAuthenticated: false,
        loading: false,
      };
    case REMOVE_NON_MEMBER:
      return {
        ...state,
        non_member: null,
        isAuthenticated: false,
        loading: false,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case REGISTER_FAIL:
    case LOGOUT:
      cookies.remove('token');
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      };
    default:
      return state;
  }
}
