import React, {useEffect} from 'react';
import {Link, Redirect} from 'react-router-dom';
import Session from '../../assets/img/session.jpg';
import StudentNavbar from '../component/Student-Navbar';
import {StudentFooter} from '../component/Student-Footer';
import {UserSession} from '../../actions/session';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import {base_url} from '../../actions/auth';
import {connect} from 'react-redux';

const StudentSessions = ({
  history,
  UserSession,
  isAuthenticated,
  session: {sessions, loading},
}) => {
  useEffect(() => {
    UserSession();
  }, []);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  console.log(sessions);
  const viewSessionDetail = (id) => {
    history.push({pathname: `/my/session/courses`, state: {session_id: id}});
    console.log(id);
  };

  return loading ? (
    <section className="container">
      <div className="mt-5">
        <div className="row">
          <div className="col-md-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-md-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-md-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-md-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-md-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-md-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-md-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-md-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="admin">
      <StudentNavbar />
      <div className="d-body">
        <div className="container mt-5">
          <p className="font26 font-light">Courses</p>
          <section>
            <div className="my-3">
              <div className="row">
                {sessions?.length < 1 ? (
                  <>
                    <div className="m-auto font20 text-grey text-center">
                      Sorry, no course found. <br /> It is either you have not
                      registered for a session or Courses for the session is yet
                      to be uploaded
                    </div>
                  </>
                ) : (
                  <>
                    {sessions?.map((each) => (
                      <div
                        className="col-lg-3 col-md-4 col-sm-12 my-3"
                        key={each.id}
                        onClick={() =>
                          viewSessionDetail(
                            each.id,
                            each.amount,
                            each.payment_status
                          )
                        }
                      >
                        <div className="each-course">
                          <div className="p-r">
                            <img
                              src={
                                each.preview_image
                                  ? each.preview_image
                                  : Session
                              }
                              alt=""
                              className="w-100"
                            />
                            <span className="course-duration p-1">
                              {each.duration} classes
                            </span>
                          </div>
                          <div className="px-3 pt-3 pb-2">
                            <div className="mb-4">{each.description}</div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <p className="font12 mb-0 text-grey">COURSE</p>
                                <p className="font12 mb-0">{each.name}</p>
                              </div>
                              <div>
                                <p className="font12 mb-0 text-grey">Year</p>
                                <p className="font12 mb-0">{each.year}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </section>
        </div>
        <div className="mt-auto">
          <StudentFooter />
        </div>
      </div>
    </div>
  );
};
StudentSessions.prototype = {
  UserSession: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  session: state.session,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {UserSession})(StudentSessions);
