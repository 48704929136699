import React, {useEffect} from 'react';
import AdminNavbar from './components/Admin-Navbar';
import {AdminFooter} from './components/Admin-Footer';
import profile from '../assets/img/profile.svg';
import AddAdmin from './components/Add-Admin';
import {logoutService} from '../actions/auth';
import EditAdmin from './components/Edit-Admin';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getUserDetail} from '../actions/staff';
import Moment from 'react-moment';
import Skeleton from 'react-loading-skeleton';

const AdminProfile = ({staff, loading}) => {
  const id = JSON.parse(localStorage.getItem('user')).id;
  useEffect(() => {
    getUserDetail(id);
  }, []);

  // loading && Object.entries(staff).length === 0 ? (
  //   <>
  //     <AdminNavbar />
  //     <section className="container">
  //       <div className="mt-5">
  //         <div className="row">
  //           <div className="col-lg-4 col-sm-12 my-3">
  //             <Skeleton height={400} />
  //           </div>
  //           <div className="col-lg-8 col-sm-12 my-3">
  //             <Skeleton height={400} />
  //           </div>
  //         </div>
  //       </div>
  //     </section>
  //   </>
  // )
  return (
    <div className="admin">
      <AdminNavbar />
      <div className="d-body">
        <div className="container mt-5">
          <div className="mb-4 text-right">
            <button
              className="btn btn-green"
              data-toggle="modal"
              data-target="#addModal"
            >
              Create Admin
            </button>
          </div>
          <div className="row setting_wrapper">
            <div className="col-lg-3 col-sm-12">
              <div className="bg-blue border-radius">
                <div className="pb-3 pt-4 text-center ">
                  <div className="mx-auto profile-img">
                    <img src={staff.avater ? staff.avater : profile} alt="" />
                  </div>
                  <p className="mt-2 font24 text-white ">
                    <b>
                      {staff.first_name} <br />
                      {staff.last_name}
                    </b>
                  </p>
                  <p className="text-white">{staff.email_address}</p>
                </div>
                <div className="px-5 py-3 font12 text-white click d-flex justify-content-between edit-click">
                  <div data-toggle="modal" data-target="#editModal">
                    <b>EDIT</b>
                  </div>
                  <div data-toggle="modal" onClick={logoutService}>
                    <b>Logout</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1 col-sm-12"></div>
            <div className="col-lg-8 col-sm-12 sm-mt bg-white">
              <div className="nav-card bg-white px-4 py-3">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active font-bold"
                      id="pills-Overview-tab"
                      data-toggle="pill"
                      href="#pills-Overview"
                      role="tab"
                      aria-controls="pills-Overview"
                      aria-selected="true"
                    >
                      Overview
                    </a>
                  </li>
                </ul>
              </div>
              <hr className="my-0" />

              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-Overview"
                  role="tabpanel"
                  aria-labelledby="pills-Overview-tab"
                >
                  <div className="bg-white py-5 px-4">
                    <div className="row">
                      <div className="col-lg-8 col-sm-12">
                        <div className="row">
                          <div className="col-lg-6 col-sm-12 sm-mt">
                            <div className="my-3">
                              <p className="mb-0 text-grey font12">EMAIL</p>
                              <p className="mb-0">{staff.email_address}</p>
                            </div>
                            <div className="my-3">
                              <p className="mb-0 text-grey font12">City</p>
                              <p className="mb-0">{staff.city}</p>
                            </div>
                            <div className="my-3">
                              <p className="mb-0 text-grey font12">
                                PHONE NUMBER
                              </p>
                              <p className="mb-0">{staff.phone_number}</p>
                            </div>
                            <div className="my-3">
                              <p className="mb-0 text-grey font12">
                                Date Created
                              </p>
                              <p className="mb-0">
                                <Moment format="LL">{staff.createdDate}</Moment>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-auto">
          <AdminFooter />
        </div>
      </div>

      {/* <!--edit Modal --> */}
      <EditAdmin />

      {/* <!--add Modal --> */}
      <AddAdmin />
    </div>
  );
};
AdminProfile.propTypes = {
  getUserDetail: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  staff: state.staffs.staffs,
  loading: state.staffs.loading,
});
export default connect(mapStateToProps, {getUserDetail})(AdminProfile);
