import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Session from "../../assets/img/session.jpg";
import StudentNavbar from "../component/Student-Navbar";
import { StudentFooter } from "../component/Student-Footer";
import { UserGroupedSession } from "../../actions/session";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import { base_url } from "../../actions/auth";
import { connect } from "react-redux";

const StudentGroupedSessions = ({
  history,
  UserGroupedSession,
  isAuthenticated,
  session: { groupedSessions, loading },
}) => {
  const [randomColor, setrandomColor] = useState();
  const [randomColor2, setrandomColor2] = useState();
  const getRandomColor1 = () => {
    // const cardColors = ['purple', 'red', 'blue', 'brown', 'green'];
    const cardColors = ["blue"];
    const color = cardColors[Math.floor(Math.random() * cardColors.length)];
    setrandomColor(color);
  };
  const getRandomColor2 = () => {
    const cardColors = ["purple", "red", "blue", "brown", "green"];
    const color = cardColors[Math.floor(Math.random() * cardColors.length)];
    setrandomColor2(color);
  };
  useEffect(() => {
    UserGroupedSession();
    getRandomColor1();
    getRandomColor2();
  }, []);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  console.log(groupedSessions);

  groupedSessions.sort((a, b) => {
    return a.name - b.name;
  });

  const viewSessionDetail = (id, amount, paymentStatus, session_name) => {
    // console.log(session_name);
    // return;
    if (paymentStatus == "unpaid" && session_name != "0") {
      history.push({
        pathname: `/payment`,
        state: { group_id: id, amount, session_name },
      });
    } else {
      console.log(id, amount, paymentStatus, session_name);
      history.push({
        pathname: `/my/group-sessions/courses`,
        state: { id, session_name },
      });
      console.log(id);
    }
  };

  return loading ? (
    <section className="container">
      <div className="mt-5">
        <div className="row">
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
          <div className="col-lg-3 col-sm-12 my-3">
            <Skeleton height={150} />
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="admin">
      <StudentNavbar />
      <div className="d-body bg-white">
        <div className="container mt-5">
          <p className="font26 font-light">Sessions</p>
          <section>
            <div className="my-3">
              {groupedSessions?.length < 1 ? (
                <>
                  <div className="m-auto font20 text-grey text-center">
                    Courses for this sessions are yet to be uploaded
                  </div>
                </>
              ) : (
                <>
                  <p className="font-bold font20">Year 1</p>
                  <div className="row">
                    {groupedSessions?.map((each) => (
                      <div
                        className="col-lg-3 col-sm-12 my-3 click group-sessions-wrapper"
                        key={each._id}
                      >
                        <div
                          className={`font12 group-sessions ${randomColor} p-2 border-radius my-2`}
                          onClick={() =>
                            viewSessionDetail(
                              each._id,
                              each.amount,
                              each.payment_status,
                              each.name
                            )
                          }
                        >
                          <div className="font-bold">
                            {each.name === "0"
                              ? "Orientation"
                              : `Session ${each.name}`}
                          </div>
                          <div className="font-light">
                            {each.year == "one" ? "First" : "Second"} year
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="font-bold">${each.amount}</div>
                            <div className="text-black">
                              <span
                                className="iconify mr-2"
                                data-icon="bx:bxs-bookmark"
                                data-inline="false"
                              ></span>
                              {each.name === "0"
                                ? "Free"
                                : `${each.payment_status}`}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
            {/* <div className="my-3">
              <p className="font-bold font20">Year 2</p>
              <div className="row">
                {fakeGroupSession?.map((each) => (
                  <div
                    className="col-lg-3 col-md-4 col-sm-12 my-3"
                    key={each}
                    onClick={() =>
                      viewSessionDetail(
                        each.id,
                        each.amount,
                        each.payment_status
                      )
                    }
                  >
                    <div
                      className={`font12 group-sessions ${randomColor2} p-2 border-radius my-2`}
                    >
                      <div className="font-bold">Session 1</div>
                      <div className="font-light">3 courses</div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="font-bold">$30</div>
                        <div className="text-black">
                          <span
                            className="iconify mr-2"
                            data-icon="bx:bxs-bookmark"
                            data-inline="false"
                          ></span>
                          Pay Now
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
           */}
          </section>
        </div>
        <div className="mt-auto">
          <StudentFooter />
        </div>
      </div>
    </div>
  );
};
StudentGroupedSessions.prototype = {
  UserGroupedSession: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  session: state.session,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { UserGroupedSession })(
  StudentGroupedSessions
);
